import {
    Alignment,
    Button,
    ButtonGroup,
    Card,
    Classes,
    FormGroup,
    H2,
    Icon,
    InputGroup,
    Intent,
    Popover,
    Switch
} from "@blueprintjs/core";
import { AppearanceType, SizeType } from '@atlaskit/avatar';
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";
import React, { useCallback, useEffect, useState } from "react";
import {  createProject, fetchAllProject } from "../state/ducks/project/operations";
import { useMutationCreateInvite,  } from "../state/mutations";

import AvatarGroup from '@atlaskit/avatar-group';
import BoardImg from '../assets/board.svg'
import Body from "../components/Body";
import FlexView from "react-flexview";
import Fuse from 'fuse.js'
import Logo from "../components/UI/Logo";
import NavBar from "../components/NavBar";
import { connect } from "react-redux";
import { logoutUser } from "../state/ducks/auth/operations";
import { setTheme } from "../state/ducks/interface/operations";
import { useDebounce } from 'use-debounce';

const NewProject = connect(state => {
    return {
      
    };
  })(props => {
    const [loading, setLoading] = useState(false)
    const defEmails = {"1":"", "2":"","3":"","4":"","5":""}
    const [emails, setEmails] = useState(defEmails)
    
    const [errors, setErrors] = useState({})
    const [projectName, setProjectName] = useState("")
    const [createInvite] = useMutationCreateInvite();
    // const createProjectCb = useCallback(() => {
    //     createProject(name)(props.dispatch)
        
    //   }, [name])
    return (
      <div>
        <NavBar></NavBar>
        <Body boxed>
        <div style={{textAlign: 'center'}}>
      <img style={{width: 200 }} src={BoardImg}></img>
            <H2>Let's create a workspace!</H2>
            <p>A small step for man, one giant leap for mankind.</p>
            <div style={{height: 30}}></div>
            </div>
          <Card elevation={2} style={{maxWidth: '500px', margin: 'auto'}}>
          <FormGroup
                label="Workspace Name"
                labelFor="text-input"
                labelInfo="(required)"
                helperText={errors['projectName'] ? errors['projectName'] : undefined} intent={errors['projectName'] ? 'danger' : 'none'}
            >
                <InputGroup autoFocus={true} large={true} id="text-input" placeholder="Apple Inc." value={projectName} onChange={(e) => setProjectName(e.target.value)} />
            </FormGroup>

            <FormGroup
                label="Workspace Collaborators"
                
            >
                {["1","2","3","4"].map((k) => (<div key={k}>
                    <FormGroup helperText={errors[k] ? errors[k] : undefined} intent={errors[k] ? 'danger' : 'none'}>
                    <InputGroup large={true} placeholder="Teammate's email" value={emails[k]} onChange={(e) => setEmails({...emails, [k]: e.target.value})} />
                    </FormGroup>
                    
                </div>))}
                <div style={{height: '1em'}}></div>
                <Button large={true} intent={'primary'} fill={true} loading={loading} onClick={() => {
                    setErrors({})
                    const allEmails = Object.keys(emails).map((k) => emails[k])
                    // Check validità lunghezza nome project
                    let raiseError = false
                    if(projectName === ""){
                        setErrors((s)=>({...s, projectName: 'Please choose a name'}))
                        raiseError = true
                    }
                    const validateEmail = (email) => {
                        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return re.test(String(email).toLowerCase());
                    }
                    // Check validità emails
                    for(let emailK of Object.keys(emails)){
                        let email = emails[emailK]
                        if(email !== ''){
                            if(!validateEmail(email)){
                                setErrors((s)=>({ ...s, [emailK]: 'Please insert a valid email'}))
                                raiseError=true
                            }
                        }
                    }
                    if(raiseError){
                        return
                    }
                    setLoading(true)
                    createProject(projectName, async (response)=>{
                        // Invito membri
                        console.log("IVN1")
                        try{
                            for(let email of allEmails){
                                if(email !== "")
                                    await createInvite({variables: {input: {
                                        projectId: response.data.createProject.id,
                                        recipientEmail: email,
                                        role: 'admin' }}})
                            }
                        }catch(e){
                            console.error(e)
                            setLoading(false)
                        }
                        // Entro nel progetto
                        props.history.replace(`/projects/${response.data.createProject.id}`)
                    }, () => {
                        setLoading(false)
                    })(props.dispatch)
                }}>Continue</Button>
            </FormGroup>
          </Card>
        </Body>
      </div>
    );
  });
  
  export default NewProject;
  