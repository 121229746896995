import {
    Button,
    EditableText,
    NavbarDivider,
} from "@blueprintjs/core";
import React, { useState } from "react";

import Body from "../../components/Body";
import { Link } from "react-router-dom";
import { PROJECT_TIME } from "../../state/graphql";
import ProjectMenu from "../../components/ProjectMenu";
import ProjectNavBar from "../../components/ProjectNavBar";
import { Query } from "@apollo/react-components";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";
import moment from "moment";
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { usePlaceholderData } from "../../utils";

const ProjectWorkload = connect((state, ownProps) => {
    return {
      loggedUser: loggedUser(state)
    };
  })(props => {
    const [showSidebar, setShowSidebar] = useState(true);
    const [startDate, setStartDate] = useState(moment().startOf('week'))
    let { projectId, userId: paramUserId } = useParams();
    const dates: Array<any> = []
    
    var monthDate = moment().startOf('day').startOf('isoWeek')//moment().startOf('month')
    const startMonth = monthDate.clone().startOf('month')
    
    const months: Array<any> = [] 
    let lastMonth = startMonth
    let span = 0
    for(let x = 0; x< 28; x++){
        if(lastMonth.isSame(monthDate.clone().startOf('month'),'month')){
            span += 1
        }else{
            months.push({
                date: lastMonth,
                span: span 
            })
            span = 0
            lastMonth = monthDate.clone().startOf('month')
        }
        dates.push({
            date: monthDate.clone(),
            greyedOut: monthDate.isoWeekday() === 6 || monthDate.isoWeekday() === 7,
            highlighted: monthDate.isSame(moment().startOf('day'))
        })
        monthDate.add(1, 'day');
    }
    console.log(months)
    const columns = dates.length

    const dd = [
        {
            "displayName": "Adileo Barone",
            "defaultWeekAvailabilities": [0,8,8,8,8,4,0],
            "availabilities": {
                '2018-02-01': 2,
                '2018-02-02': 3,
            },
            "activities": [

            ]
        }
    ]
    
    const userId = paramUserId ? paramUserId : props.loggedUser.id;
    return (
      <Query
        fetchPolicy={"cache-and-network"}
        query={PROJECT_TIME}
        variables={{ id: projectId, view: {
          start: startDate.clone().subtract(1,'day').toISOString(),
          end: startDate.clone().add(1,'weeks').add(1,'day').toISOString(),
          userId: userId,
        } }}
      >
        {(result) => {
          const { loading, error, data: realData } = result as any
          const { data, skeltonClass } = usePlaceholderData({
            data: {
              me: {
                project: {
                  name: "Lorem ipsum dolor",
                  id: "123",
                  projectViews: [],
                  itemAttributes: [],
                  timeLogsView: [],
                  members: []
                }
              }
            },
            realData,
            loading
          });

          const project = data.me?.project;
          
          return (
            <div>
              
              <div
                style={{
                  display: "flex",
                  width: "100vw",
                  height: "100vh",
                  overflow: "hidden"
                }}
              >
                {showSidebar ? (
                  <ProjectMenu
                    project={project}
                    activeMenu={"workload"}
                  ></ProjectMenu>
                ) : null}
                <div style={{ flex: "1 1", backgroundColor: "#FBFDFF" }}>
                  <ProjectNavBar
                    notFixed={true}
                    pre={
                      <>
                        <Button
                          minimal={true}
                          icon={showSidebar ? "chevron-left" : "menu"}
                          text={showSidebar ? "Menu" : "Menu"}
                          onClick={() => setShowSidebar(!showSidebar)}
                        />
                        <NavbarDivider></NavbarDivider>
                        <div
                          style={{
                            color: "white",
                            display: "flex",
                            fontSize: "0.8em"
                          }}
                        >
                          <Link to={"/"} style={{ color: "white" }}>
                            All Workspaces
                          </Link>
                          <div style={{ padding: "0em 0.4em" }}>/</div>
                          <Link
                            className={skeltonClass}
                            to={`/projects/${project ? project.id : ""}`}
                            style={{ color: "white" }}
                          >
                            {project ? project.name : ""}
                          </Link>
                          <div style={{ padding: "0em 0.4em" }}>/</div>
                          <Link
                            className={skeltonClass}
                            to={`/projects/${
                              project ? project.id : ""
                            }/workload`}
                            style={{ color: "white" }}
                          >
                            Workload
                          </Link>
                        </div>
                      </>
                    }
                    post={
                      <>
                      
                      </>
                    }
                  ></ProjectNavBar>
                  <Body >
                   <div style={{padding: '1em'}}>
                   <Button style={{marginRight: '1em'}} icon={'chevron-left'}></Button> <Button rightIcon={'chevron-right'}></Button>
                  <div style={{width: '100%', display: 'grid', gridTemplateColumns: `4fr repeat(${columns}, 1fr)`, gridTemplateRows: '0.5fr 1fr'}}>
                    {/* Row 1 */}
                    <div></div>
                    <div style={{background:'transparent', gridRow: '1', gridColumn: `2 / ${columns+2}`}}></div>
                    {/* Row 2 */}
                    <div></div>
                    {dates.map(({date, greyedOut, highlighted}) => <HeaderDateCell greyedOut={greyedOut} highlighted={highlighted}><div>{date.format('DD')}</div><div>{date.format('ddd')}</div></HeaderDateCell>)}
                  </div>
                  <div style={{width: '100%', display: 'grid', gridTemplateColumns: `4fr repeat(${columns}, 1fr)`, gridTemplateRows: '0.5fr 1fr'}}>
                    {/* Row 1 */}
                    <PersonCellStart>
                        <div>Adileo Barone</div>
                        <div><Button small={true} icon={'caret-down'}></Button></div>
                    </PersonCellStart>
                    {dates.map(({date, greyedOut, highlighted}) => <PersonCell greyedOut={greyedOut} highlighted={highlighted}>2/8</PersonCell>)}
                    
                    <ActivityCellStart>
                
                        <div>Activity 1</div>
                        <div><Button small={true} icon={'cross'}></Button></div>
                    </ActivityCellStart>
                    {dates.map(({date, greyedOut, highlighted}) => <PersonCell greyedOut={greyedOut} highlighted={highlighted}><EditableText defaultValue={'1'}></EditableText></PersonCell>)}

                    <ActivityCellStart>
                
                        <div>Activity 2</div>
                        <div><Button small={true} icon={'cross'}></Button></div>
                    </ActivityCellStart>
                    {dates.map(({date, greyedOut, highlighted}) => <PersonCell greyedOut={greyedOut} highlighted={highlighted}><EditableText defaultValue={'1'}></EditableText></PersonCell>)}
                    <ActivityCellStart><a href="#">Add activity</a></ActivityCellStart>
                  </div>

                  </div>   
                  </Body>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  });
  const ActivityCellStart = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1em;
  justify-content: space-between;
  padding-right: 1em;
  height: 40px;
  .bp3-button:not([class*="bp3-intent-"]):not([class*="bp3-minimal"]){
    background-color: rgba(0, 0, 0, 0.03);
  }
  `
  const PersonCellStart = styled.div`
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  `
  const PersonCell = styled.div`
  display: flex;
  height: 40px;
  .bp3-editable-text{
      width: 40px;
      text-align:center;
      .bp3-editable-text-content{
          min-width: 40px !important;
          width: 40px !important;
      }
      .bp3-editable-text-input{
        text-align:center;
      }
  }
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${(props) => {
      if(props.highlighted){
          return "#0d81f11f";
      }
      if(props.greyedOut){
          return "#f4f4f4"
      }
      return "transparent"
    }};
  `
  const HeaderDateCell = styled.div`
  text-align:center;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 3px;
  border-top: ${(props)=> props.highlighted ? '4px solid #0d81f1':'4px solid transparent'};
  background-color: ${(props) => {
      if(props.highlighted){
          return "#0d81f11f";
      }
      if(props.greyedOut){
          return "#f4f4f4"
      }
      return "transparent"
    }};
  `
  export default ProjectWorkload;
  