import React from 'react'

const ptIconWrapper = (path, viewboxDefault = 24, size=16, style) => {
    
        return (<span className="bp3-icon" style={style} >
      <svg
        className="pt-icon"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={size}
        height={size}
        viewBox={`0 0 ${viewboxDefault} ${viewboxDefault}`}
      >
        {path}
      </svg></span>)
    
  };




export const SIcon = ({icon, size=16, style={}}) =>{
    const icons = {
        'filter':<path d="m1 0c-.601562 0-1 .398438-1 1v3c0 .300781.113281.488281.3125.6875l16 17c.199219.199219.488281.3125.6875.3125h10c.300781 0 .488281-.113281.6875-.3125l16-17c.199219-.199219.3125-.386719.3125-.6875v-3c0-.601562-.398437-1-1-1zm15 20.0001389v17.7271608c0 .4708783.199219.8355768.5 1.071016l10 7.0908643c.101563.1200282.300781.1109589.5.1109589s.300781.0090693.5-.1109589c.300781-.2354392.5-.6001377.5-1.071016v-24.8180251z"/>,
        'kanban':<path d="m32 0v46h12v-46zm-32 13v33h12v-33zm16 11v22h12v-22z" transform="matrix(-1 0 0 -1 47 48)"/>
    }
    const IC = ptIconWrapper(icons[icon], 50, size, style)
return (<>{IC}</>)
}
