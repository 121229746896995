import {
  Button,
  Classes,
  Drawer,
  FormGroup,
  InputGroup,
  NavbarDivider,
  Popover,
  Tag
} from "@blueprintjs/core";
import RGL, { WidthProvider } from "react-grid-layout";
import React, { useEffect, useState } from "react";
import {
  setActual,
  startProjectView,
  stopProjectView,
} from "../../state/ducks/project/operations";

import Body from "../../components/Body";
import { Droppable } from "react-beautiful-dnd";
import ItemEditor from "../../components/ItemEditor";
import ItemList from '../../components/ItemList'
import { Link } from "react-router-dom";
import { PROJECT_VIEW } from "../../state/graphql";
import ProjectMenu from "../../components/ProjectMenu";
import ProjectNavBar from "../../components/ProjectNavBar";
import { Query } from '@apollo/react-components';
import { SIcon } from "../../utils/Icons";
import Select from "react-select";
import classNames from "classnames";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";
import { useHistory } from "react-router-dom";
import { useMutationUpdateView } from "../../state/mutations";

export const viewAttrHelper = (view, itemAttributes) => {
  console.log({view, itemAttributes})
  let groupedByAttribute: any = null
  let actualFilters: any = {}
  let actualCardLayout: any = {}
  let filterNames: any = null
  let filterNames2: any = null
  let filtersTextual: any = []
  if(view && itemAttributes && view.groupBy){
    groupedByAttribute = itemAttributes.find(a => a.id === view.groupBy)
  }
  if(view && view.filters){
    actualFilters = JSON.parse(view.filters) || {}
  }
  if(view && view.cardLayout){
    actualCardLayout = JSON.parse(view.cardLayout) || {}
  }
  if(itemAttributes){
    let arr: any= []
    
    itemAttributes.filter(i => i.type === 'select' || i.type === 'memberSelect').forEach((attribute) => {
      if(actualFilters[attribute.id]){
        if(actualFilters[attribute.id] === 'NA'){
          arr.push(attribute.name + ' N/A')
          filtersTextual.push(`${attribute.name} is N/A`)
        }else{
          console.log('scalzo', attribute.id,actualFilters[attribute.id])
          const val = attribute.values.find((v) => v.id === actualFilters[attribute.id])
          if(val){
            arr.push(val.value)
            filtersTextual.push(`${attribute.name} is ${val.value}`)
          }
          
        }
      }
    })
    filterNames = arr.join(', ')
    
  }

  return {groupedByAttribute, actualFilters, actualCardLayout, filterNames, filtersTextual}
}
const GridLayout = WidthProvider(RGL);

const ProjectView = connect((state, ownProps) => {
  return {
    theme: state.interface.theme,
    showBin: state.project.showBin,
    loggedUser: loggedUser(state)
  };
})(props => {
  const [editOpen, setEditOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showSidebar, setShowSidebar] = useState(true);
  const [updateView] = useMutationUpdateView()
  useEffect(() => {
   
    startProjectView(
      props.match.params.projectId,
      props.match.params.viewId
    )(props.dispatch);
    return () => {
      stopProjectView()(props.dispatch);
    };
  }, [props.match.params.projectId, props.match.params.viewId]);
  return (
    <div>
    <Query fetchPolicy={'cache-and-network'} query={PROJECT_VIEW} variables={{id: props.match.params.projectId, viewId: props.match.params.viewId }}>
      {({ loading, error, data }) => {
          const project = data?.me?.project
          setActual(project)(props.dispatch)
        console.log({data})
        const {groupedByAttribute, actualFilters, actualCardLayout, filterNames} = viewAttrHelper(project?.view, project?.itemAttributes)

       

        return ( 
    <div>
      <Drawer
        size={1200}
        isOpen={editOpen}
        onClose={() => setEditOpen(false)}
        style={{ padding: "1em 3em", overflowY: 'auto' }}
      >
         <ItemEditor
              project={project}
              projectId={project?.id}
              itemId={editOpen}
            ></ItemEditor>
      </Drawer>
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          overflow: "hidden"
        }}
      >
        {showSidebar ? (
          <ProjectMenu project={project} activeView={project?.view} activeMenu={'views'}></ProjectMenu>
        ) : null}
        <div style={{ flex: "1 1", backgroundColor: "#FBFDFF" }}>
          <ProjectNavBar
            notFixed={true}
            pre={
              <>
                
                <Button
                  minimal={true}
                  icon={showSidebar ? "chevron-left" : "menu"}
                  text={showSidebar ? "Menu" : "Menu"}
                  onClick={() => setShowSidebar(!showSidebar)}
                />
                <NavbarDivider></NavbarDivider>
                <div style={{color: 'white', display: 'flex', fontSize: '0.8em'}}>
                  <Link to={'/'} style={{color:'white'}}>
                    All Workspaces
                  </Link> 
                  <div style={{padding: '0em 0.4em'}}>/</div>
                  <Link to={`/projects/${project ? project.id:''}`} style={{color:'white'}}>
                    {project ? project.name:''}
                  </Link>
                  <div style={{padding: '0em 0.4em'}}>/</div>
                  <Link to={`/projects/${project ? project.id:''}/views`} style={{color:'white'}}>
                    Kanban Boards
                  </Link>
                  
                  <div style={{padding: '0em 0.4em'}}>/</div>
                  <Link to={`/projects/${project ? project.id:''}/v/${project ? project.view.id:''}`} style={{color:'white'}}>
                    {project ? project.view.name:''}
                  </Link>
                </div>
                
                
                
              </>
            }
            post={
              <>
              
                {!props.showBin ? <>
                <Popover
                  position={"bottom"}
                  content={
                    <div style={{ padding: "1em" }}>
                      {project
                        ? project.itemAttributes.filter(i => i.type === 'select' || i.type === 'memberSelect').map(attribute => (
                            <div
                              key={attribute.id}
                              style={{ width: 200, marginBottom: "1em" }}
                            >
                             <FormGroup label={attribute.name}>
                              <Select
                                className={'SESelect'}
                                classNamePrefix={'SESelect'}
                                isClearable={true}
                                value={
                                  actualFilters[attribute.id]
                                    ? [
                                        [...attribute.values, {id: 'NA', value: 'Not Assigned'}].find(
                                          v =>
                                            v.id === actualFilters[attribute.id]
                                        )
                                      ].map(v => ({
                                        label: v.value,
                                        value: v.id
                                      }))[0]
                                    : undefined
                                }
                                onChange={(newV: any) => {
                                  if (newV) {
                                    updateView({variables: {
                                      input: {
                                        projectId: props.match.params.projectId,
                                        projectViewId: project.view.id,
                                        filters: JSON.stringify({
                                          ...actualFilters,
                                          [attribute.id]: newV.value
                                        })
                                      }
                                    }})
                                    
                                  } else {
                                    const nF = { ...actualFilters };
                                    delete nF[attribute.id];
                                    updateView({variables: {
                                      input: {
                                        projectId: props.match.params.projectId,
                                        projectViewId: project.view.id,
                                        filters: JSON.stringify(nF)
                                      }
                                    }})
                                  }
                                }}
                                options={[{value: 'Not Assigned', id: 'NA'},...attribute.values].map(v => ({
                                  label: v.value,
                                  value: v.id
                                }))}
                              ></Select></FormGroup>
                            </div>
                          ))
                        : null}
                    </div>
                  }
                >
                  <Button
                    minimal={true}
                    icon={<SIcon icon={'filter'}></SIcon>}
                    text={filterNames ? "Filters: " + filterNames : "Filters"}
                    // rightIcon="caret-down"
                  />
                </Popover>

                <Popover
                  position={"bottom"}
                  content={
                    <div style={{ padding: "1em" }}>
                      {project
                        ? project.itemAttributes
                            .filter(i => i.type === 'select' || i.type === 'memberSelect')
                            .filter(i => i.id != project.view.groupBy)
                            .map(attribute => (
                              <Tag
                                key={attribute.id}
                                style={{
                                  marginRight: "0.5em",
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  updateView({variables: {
                                    input: {
                                      projectId: props.match.params.projectId,
                                      projectViewId: project.view.id,
                                      groupBy: attribute.id
                                    }
                                  }})
                                  
                                }}
                              >
                                {attribute.name}
                              </Tag>
                            ))
                        : null}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "1em"
                        }}
                      >
                        {groupedByAttribute ? (
                          <Tag
                            key={groupedByAttribute.id}
                            style={{ marginRight: "0.5em", cursor: "pointer" }}
                            onRemove={() => {
                              updateView({variables: {
                                input: {
                                  projectId: props.match.params.projectId,
                                  projectViewId: project.view.id,
                                  groupBy: ""
                                }
                              }})
                              
                            }}
                          >
                            {groupedByAttribute.name}
                          </Tag>
                        ) : null}
                      </div>
                    </div>
                  }
                >
                  <Button
                    minimal={true}
                    icon={<SIcon icon={'kanban'}></SIcon>}
                    text={
                      groupedByAttribute
                        ? "Columns: " + groupedByAttribute.name
                        : "Columns"
                    }
                   
                  />
                </Popover>
                

                <Popover
                  position={"bottom"}
                  content={
                    <div style={{ padding: "1em" }}>
                      {project
                        ? project.itemAttributes
                            .filter(i => i.type === 'select')
                            .filter(i => !(actualCardLayout.visibleCA||[]).find((k) => k === i.id))
                            .map(attribute => (
                              <Tag
                                key={attribute.id}
                                style={{
                                  marginRight: "0.5em",
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  updateView({variables: {
                                    input: {
                                      projectId: props.match.params.projectId,
                                      projectViewId: project.view.id,
                                      cardLayout: JSON.stringify({visibleCA: [...(actualCardLayout.visibleCA || []), attribute.id]})
                                    }
                                  }})
                                  
                                }}
                              >
                                {attribute.name}
                              </Tag>
                            ))
                        : null}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "1em"
                        }}
                      >
                        {actualCardLayout.visibleCA ? actualCardLayout.visibleCA.map((k) => (
                          <Tag
                          key={k}
                          style={{ marginRight: "0.5em", cursor: "pointer" }}
                          onRemove={() => {
                            const newVisibleCa = (actualCardLayout.visibleCA || []).filter((kk) => kk !== k)
                            updateView({variables: {
                              input: {
                                projectId: props.match.params.projectId,
                                projectViewId: project.view.id,
                                cardLayout: JSON.stringify({visibleCA: newVisibleCa})
                              }
                            }})
                            
                          }}
                        >
                          {project && project.itemAttributes ? project.itemAttributes.find((a) => a.id === k).name : null}
                        </Tag>
                        )): null}
                      </div>
                    </div>
                  }
                >
                  <Button
                    minimal={true}
                    icon="tag"
                    text={'Visible Labels'}
                    
                  />
                </Popover>
                </> : null}
                <Droppable
                  droppableId={"archive"}
                  ignoreContainerClipping={true}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        
                        borderRadius: 5,
                        background: snapshot.isDraggingOver
                          ? "rgba(0,0,0,0.03)"
                          : "#DB3E5D"
                      }}
                    >
                      {props.showBin ? (
                        <Button
                          minimal={true}
                          icon="archive"
                          
                          text={"Delete (drop here)"}
                        />
                      ) : null}
                    </div>
                  )}
                </Droppable>
                <NavbarDivider></NavbarDivider>
                <InputGroup id="search-input" className={'search'} type={'search'} leftIcon={'search'} placeholder="Search item" value={searchText} onChange={(e)=>setSearchText(e.target.value)} />
               
              </>
            }
          ></ProjectNavBar>
          <Body>
           
              <ItemList
                searchText={searchText}
                jsonFilters={actualFilters}
                jsonCardLayout={actualCardLayout}
                projectId={props.match.params.projectId}
                showEdit={id => {
                  setEditOpen(id);
                }}
                editItem={itemId => setEditOpen(itemId)}
                project={project}
                projectMembers={project ? project.members : []}
                projectAttributes={project ? project.itemAttributes : []}
                filters={project ? project.view.filters : null}
                groupBy={project ? project.view.groupBy : null}
              ></ItemList>
              <div style={{height: '50px'}}></div>
          </Body>
        </div>
      </div>
    </div>
    )}}</Query>
    </div>
  );
});

export default ProjectView;
