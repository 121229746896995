import * as types from "./types";

import { combineReducers } from "redux";

const initialState = {
    data: {},
    me: undefined
}
const reducer = (state = initialState, action: any) => {
    switch( action.type ) {
        case types.USER_ME_FETCH_SUCCESS:
            if(action.payload.me){
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [action.payload.me.id]: action.payload.me
                    },
                    me:  action.payload.me.id,
                }
            }else{
                return {
                    ...state,
                    me: null
                }
            }
            
        case types.USER_LOGOUT_CLEAR_REQUEST:
            return {
                ...initialState
            }
        default: return state;
    }
}
export default reducer;