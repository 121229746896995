import {
    Alignment,
    Button,
    ButtonGroup,
    Card,
    Classes,
    H2,
    Icon,
    InputGroup,
    Intent,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
    Switch
} from "@blueprintjs/core";
import { AppearanceType, SizeType } from '@atlaskit/avatar';
import { Link, Redirect } from "react-router-dom";
import React, { useCallback } from "react";

import AvatarGroup from '@atlaskit/avatar-group';
import FlexView from "react-flexview";
import Logo from "../UI/Logo";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";
import { logoutUser } from "../../state/ducks/auth/operations";
import { setTheme } from "../../state/ducks/interface/operations";
import {
  withRouter
} from 'react-router-dom'

const NavBar = connect(state => {
    return {
      theme: state.interface.theme,
      loggedUser: loggedUser(state)
    };
  })(props => {
    const { theme, dispatch, loggedUser } = props;
    const switchTheme = useCallback(
      e => {
        const themen = theme === "light" ? "dark" : "light";
        setTheme(themen)(dispatch);
      },
      [theme]
    );
    return (
        <>
        <Navbar className={'navBarBlue'} fixedToTop={props.notFixed ? false : true} style={{}} >
          <NavbarGroup align={Alignment.LEFT}>
            <NavbarHeading>
              <Link to={'/'}><Logo format="h" color={'white'} width={120}></Logo></Link>
            </NavbarHeading>
    {props.pre ? <> <NavbarDivider />{props.pre}</> : null }
          </NavbarGroup>
          <NavbarGroup align={Alignment.RIGHT}>
          {props.post ? <> {props.post}<NavbarDivider /></>  : null }
            {loggedUser?<Button className={Classes.MINIMAL} icon="user" text={loggedUser ? `${loggedUser.displayName} (@${loggedUser.username})` : ''} onClick={() => {
              props.history.push('/profile')
            }}/>:null}
            <NavbarDivider />
            {/* <Button
              minimal={true}
              icon={theme === "dark" ? "lightbulb" : "moon"}
              onClick={switchTheme}
            ></Button> */}
            <Link to="/logout">
            <Button
              className={Classes.MINIMAL}
              icon="log-out"
              // onClick={() => {
              //   logoutUser()(props.dispatch);
              // }}
            /></Link>
          </NavbarGroup>
        </Navbar>
        {props.notFixed ? null : <div style={{paddingTop: 50, width: '100%'}}></div>}
       </>
    );
  });
  
  export default withRouter(NavBar);
  