import { ITEM_FRAGMENT, PROJECT_BASE_FRAGMENT, PROJECT_VIEW_FRAGMENT } from '../../graphql';
import { PROJECT_ATTRIBUTES_FETCH_REQUEST, PROJECT_ATTRIBUTE_CREATE_REQUEST, PROJECT_ATTRIBUTE_DELETE_REQUEST, PROJECT_ATTRIBUTE_UPDATE_REQUEST, PROJECT_ATTRIBUTE_VALUE_CREATE_REQUEST, PROJECT_ATTRIBUTE_VALUE_DELETE_REQUEST, PROJECT_ATTRIBUTE_VALUE_UPDATE_REQUEST, PROJECT_CREATE_REQUEST, PROJECT_FETCH_ALL_REQUEST, PROJECT_FETCH_REQUEST, PROJECT_INVITE_ACCEPT_REQUEST, PROJECT_INVITE_CREATE_REQUEST, PROJECT_INVITE_DELETE_REQUEST, PROJECT_ITEMS_DATA, PROJECT_ITEMS_SYNC_REQUEST, PROJECT_ITEM_CREATE_REQUEST, PROJECT_ITEM_DELETE_REQUEST, PROJECT_ITEM_SUBSCRIPTION_CREATED, PROJECT_ITEM_SUBSCRIPTION_DELETED, PROJECT_ITEM_UPDATE_REQUEST, PROJECT_MEMBERS_FETCH_REQUEST, PROJECT_MEMBER_DELETE_REQUEST, PROJECT_VIEW_CREATE_REQUEST, PROJECT_VIEW_DATA, PROJECT_VIEW_DELETE_REQUEST, PROJECT_VIEW_PANE_BATCH_UPDATE_REQUEST, PROJECT_VIEW_PANE_CREATE_REQUEST, PROJECT_VIEW_PANE_DELETE_REQUEST, PROJECT_VIEW_PANE_UPDATE_REQUEST, PROJECT_VIEW_START, PROJECT_VIEW_STOP, PROJECT_VIEW_UPDATE_REQUEST } from './types';
import { actionChannel, call, cancel, cancelled, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { channel, eventChannel } from 'redux-saga';
import { client, request } from '../../utils/graphql';
import { errorAttributesFetch, errorCreate, errorFetchAll, receiveAttributesFetch, receiveCreate, receiveFetchAll, receiveMembersFetch, requestDeleteItem, requestFetchAll, requestUpdateItem } from './actions';
import { itemById, viewItems } from './selectors';
import { mutationDeleteItem, mutationUpdateItem } from '../../mutations';

import api from '../../utils/api'
import errorHandler from '../../utils/errorHandler';
import gql from 'graphql-tag'
import { trackEvent } from '../../utils/trackEvent';

const buildQuery = (projectId) => {
    const qq = gql`query singleProject($id: ID,$lastSync: DateTime){
        me{
            id
            project(id: $id){
                id
                items(updatedAfter: $lastSync){
                    ...ItemFragment
                }
            }
        }
    }
    ${ITEM_FRAGMENT}
    `
    const vv = { id: projectId, lastSync: (new Date(0)).toISOString() }
    return {
        query: qq,
        variables: vv
    }
}
const mergeItems = (setA, setB) => {
    const concat = setA.concat(setB)
    const itemsMap = concat.reduce((a, b) => ({ ...a, [b.id]: b }), {})
    const items = Object.keys(itemsMap).map((key) => itemsMap[key])
    return items
}

function* create(action: any) {
    trackEvent('PROJECT_CREATE_REQUEST')
    const createApi = (data) => {
        return client.mutate({
            mutation: gql`mutation createProject($input: CreateProjectInput){
                createProject(input: $input){
                    id
                    name
    
                }
            }`,
            variables: { input: data },
            refetchQueries: ['me.projects']
        })
    }
    try {
        const createRequest = yield call(createApi, action.payload)
        yield put(receiveCreate());
        yield put(requestFetchAll())
        if (action.payload.resolve) {
            action.payload.resolve(createRequest)
        }
    } catch (e) {
        errorHandler({
            error: e,
        })
        yield put(errorCreate())
        if (action.payload.reject) {
            action.payload.reject(e)
        }
    }
}
function* viewStart(action) {
    
    // const vq = yield fork(viewQuery, action)
    const v2 = yield fork(syncItems, action)
    
    // yield put({ type: PROJECT_ATTRIBUTES_FETCH_REQUEST, payload: { projectId: action.payload.projectId } })
    yield fork(itemsSubscription, { payload: { projectId: action.payload.projectId } })
    // When websocket reconnects, fetch latest items
    yield takeLatest('WS/RECONNECTED', function* () {
        yield put({ type: PROJECT_ITEMS_SYNC_REQUEST })
    })
}
function* viewSubscription(action: any) {
    const wq = client.subscribe({
        query: gql`subscription projectView($input: SubscriptionProjectViewInput){
            projectView(input: $input){
              view{
                ...ProjectViewFragment
              }
            }
          }
          ${PROJECT_VIEW_FRAGMENT}
          `,
        variables: { input: { projectViewId: action.payload.viewId } },

    })
    // console.log('vvv', action)
    const channel = eventChannel((emitter: any) => {
        // console.log('SUB RUNNING')
        const sub = wq.subscribe({
            start: () => {
                // console.log('STARTTTT')
            },
            next: ({ data }) => {
                // console.log('INCOMING DATA')
                if (data)
                    emitter({ data })
            },
            error: (e) => {
                // console.error('Pippo')
                console.error(e)
            },

        })
        return () => {
            // console.log('SUB STOP')
            sub.unsubscribe()
        }
    })
    yield takeEvery(channel, function* ({ data }) {
        // console.log('INCOMING DATA - ACTION')
        // CI PENSA LA CACHE NON FACCIO NULLA
        // yield put({ type: PROJECT_VIEW_DATA, payload: { data } })
    })
    yield take(PROJECT_VIEW_STOP)
    channel.close()
}
function* itemsSubscription(action: any) {
    const wq = client.subscribe({
        query: gql`subscription projectItems($input: SubscriptionProjectItemInput){
            projectItems(input: $input){
              operation
              item{
                ...ItemFragment
              }
            }
          }
          ${ITEM_FRAGMENT}
          `,
        variables: { input: { projectId: action.payload.projectId } },
    })
    const channel = eventChannel((emitter: any) => {
        const sub = wq.subscribe({
            next: ({ data }) => {

                if (data)
                    emitter({ data })
            },
            error: (e) => {

                console.error(e)
            },

        })
        return () => {
            // console.log('SUB STOP')
            try{
                sub.unsubscribe()
            }catch(e){
                console.error(e)
            }
            
        }
    })
    yield takeEvery(channel, function* ({ data }) {
        // console.log('INCOMING DATA - ACTION')
        // CI PENSA LA CACHE NON FACCIO NULLA
        const query = buildQuery(action.payload.projectId)
        const oldData = client.readQuery(query)
        client.writeQuery({
            ...query,
            data: {
                ...oldData,
                me: {
                    ...oldData.me,
                    project: {
                        ...oldData.me.project,
                        items: mergeItems(oldData.me.project.items, [data.projectItems.item])
                    }
                }
            }
        })


    })
    yield take(PROJECT_VIEW_STOP)
    channel.close()
}

function* viewQuery(action: any) {
    const wq = client.watchQuery({
        fetchPolicy: "cache-and-network",
        query: gql`query singleProject($id: ID,$viewId: ID){
                me{
                    id
                    project(id: $id){
                        ...ProjectBase
                        view(id: $viewId) {
                            ...ProjectViewFragment
                        }
                    }
                }
            }
            ${PROJECT_VIEW_FRAGMENT}
            ${PROJECT_BASE_FRAGMENT}
            `,
        variables: { id: action.payload.projectId, viewId: action.payload.viewId },

    })
    const channel = eventChannel((emitter: any) => {
        const sub = wq.subscribe(({ data }) => {
            if (data)
                emitter({ data })
        })
        return () => {

            sub.unsubscribe()
        }
    })
    let viewSubs = null
    yield takeEvery(channel, function* ({ data }) {
        // yield take(viewSubscription, action)
        if (viewSubs === null)
            viewSubs = yield fork(viewSubscription, { payload: { viewId: data.me.project.view.id } })
        yield put({ type: PROJECT_VIEW_DATA, payload: { data } })
    })
    yield take(PROJECT_VIEW_STOP)
    channel.close()
}
function* syncItems(action: any) {

    const query = buildQuery(action.payload.projectId)
    let cached = true
    try {
        client.readQuery(query)
    } catch{
        cached = false
    }
    const wq = client.watchQuery({
        fetchPolicy: "cache-first",
        ...query
    })

    const channel = eventChannel((emitter: any) => {
        const sub = wq.subscribe(({ data }) => {
            if (data)
                emitter({ data })
        })
        return () => {

            try{
                sub.unsubscribe()
            }catch(e){
                console.error(e)
            }
        }
    })
    let viewSubs = null
    yield takeEvery(channel, function* ({ data }) {
        if (data)
            yield put({ type: PROJECT_ITEMS_DATA, payload: { data } })
    })
    yield takeEvery(PROJECT_ITEMS_SYNC_REQUEST, function* () {
        let lastUpdatedAt = (new Date(0)).toISOString()
        try {
            const oldQ = client.readQuery(query)
            const sortedItems = oldQ.me.project.items.sort((a, b) => (a > b ? -1 : 1)).reverse()
            
            if (sortedItems.length > 0) {
                lastUpdatedAt = sortedItems[0].updatedAt
            }
        }catch(e){
            console.log(e)
            
        }
        wq.fetchMore({
            query: query.query,
            variables: { ...query.variables, lastSync: lastUpdatedAt },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    ...fetchMoreResult,
                    me: {
                        ...fetchMoreResult.me,
                        project: {
                            ...fetchMoreResult.me.project,
                            items: mergeItems(prev.me.project.items, fetchMoreResult.me.project.items)
                        }
                    }

                });
            }
        }).catch((e) => {
            console.error(e)
        })
    })
    if (cached) {
        yield put({ type: PROJECT_ITEMS_SYNC_REQUEST })
    }
    yield take(PROJECT_VIEW_STOP)
    channel.close()
}
function* fetchList() {
    const fetchAllApi = () => {
        return client.query({
            fetchPolicy: "network-only",
            query: gql`{
                me{
                    id
                    projects{
                        id
                        name
                        members{
                            id
                            firstName
                            lastName
                            username
                            photoUrl
                            displayName
                        }
                    }
                }
            }`
        })
        // client.cache.read({rootId:""})
    }
    try {
        const fetchResponse = yield call(fetchAllApi)
        yield put(receiveFetchAll(fetchResponse));
    } catch (e) {
        errorHandler({
            error: e,
        })
        yield put(errorFetchAll());
    }
}

function* updateItem(action) {
    trackEvent('PROJECT_ITEM_UPDATE_REQUEST')
    const x = () => client.mutate({
        mutation: mutationUpdateItem,
        optimisticResponse: {
            __typename: "Mutation",
            updateProjectItem: {
              id: action.payload.itemId,
              __typename: "ProjectItem",
              ...action.payload.data
            }
          },
        variables: { input: { projectId: action.payload.projectId, itemId: action.payload.itemId, ...action.payload.data } }
    })
    try {
        const fetchResponse = yield call(x)
        if (action.payload.resolve) {
            action.payload.resolve(fetchResponse)
        }
    } catch (e) {
        errorHandler({
            error: e,
        })
        if (action.payload.reject) {
            action.payload.reject(e)
        }
    }
}
function* deleteItem(action) {
    trackEvent('PROJECT_ITEM_DELETE_REQUEST')
    const x = () => client.mutate({
        mutation: mutationDeleteItem,
        variables: { input: { projectId: action.payload.projectId, itemId: action.payload.itemId} }
    })
    try {
        const fetchResponse = yield call(x)
        if (action.payload.resolve) {
            action.payload.resolve(fetchResponse)
        }
    } catch (e) {
        errorHandler({
            error: e,
        })
        if (action.payload.reject) {
            action.payload.reject(e)
        }
    }
}


function* moveItem(action){
    
    const [type, itemId] = action.payload.event.draggableId.split('-')
    if(type !== 'item'){
        return
    }
    const dst = action.payload.event.destination
    
    let state = yield select()
    let item = yield select(itemById, itemId)
    const projectId = state.project.actualProject.id
    const view = state.project.actualProject.view
    
    let groupedItems = yield select(viewItems, view.filters, view.groupBy, null)

    if(dst){
        if(dst.droppableId === 'archive'){

            yield put(requestDeleteItem(projectId, itemId))
            return
        }

        const targetList = (groupedItems[dst.droppableId.replace('list-', '')]||[]).sort((a,b) => b.order-a.order)
        for(let x = 0; x < targetList.length; x++){
            if(targetList[x].id === itemId){
                // REMOVE FROM ARRAY
                targetList.splice(x, 1)
            }
        }
        targetList.splice(dst.index, 0, {id: itemId})
        console.table({targetList})
        let prev, next, order
        if(targetList.length > dst.index + 1){
            next = targetList[dst.index + 1]
        }
        if(dst.index >= 1){
            prev = targetList[dst.index - 1]
        }
        if(prev !== undefined && next !== undefined){
            order = (prev.order+next.order)/2
        }
        if(prev !== undefined && next === undefined){
            order = prev.order - 10
        }
        if(prev  === undefined && next !== undefined){
            order = next.order + 10
        }
        let newAttributes = JSON.parse(item.attributes) || {}
        // Se c'è un groupBy
        if(view.groupBy != ''){
            const attributeId = view.groupBy
            const valueId = dst.droppableId.replace('list-', '')
            // const attribute = view.itemAttributes.find((att) => att.id === attributeId)
            

            if(valueId === ''){
                // Unset
                delete newAttributes[attributeId]
            }else{
                // Set del value
                newAttributes[attributeId] = valueId
            }
        }
        // console.log(order)
        // if(dst.index === targetList.length-1){
        //     prev = targetList[dst.index]
        // }
        console.log({prev: prev ? prev.title : null, next: next ? next.title : null})
        // dst.droppableId
        // dst.index
    
        yield put(requestUpdateItem(projectId, itemId, {order: order, attributes: JSON.stringify(newAttributes)}))
    }
}

function* acceptProjectInvite(action) {
    trackEvent('PROJECT_INVITE_ACCEPT_REQUEST')
    const x = () => client.mutate({
        mutation: gql`mutation acceptProjectInvite($input: AcceptProjectInviteInput){
            acceptProjectInvite(input: $input){
                id
                role
            }
        }`,
        variables: { input: { projectId: action.payload.projectId, token: action.payload.token } },
    })
  
    try {
        const fetchResponse = yield call(x)
        if (action.payload.resolve) {
            action.payload.resolve(fetchResponse)
        }
    } catch (e) {
        
        errorHandler({
            error: e,
        })
        if (action.payload.reject) {
            action.payload.resolve(e)
        }
    }
}
function* sagas() {
    yield takeEvery(PROJECT_CREATE_REQUEST, create);
    yield takeLatest(PROJECT_FETCH_ALL_REQUEST, fetchList)


    yield takeEvery(PROJECT_INVITE_ACCEPT_REQUEST, acceptProjectInvite)
    yield takeEvery('dnd/ON_DRAG_END', moveItem)
    yield takeEvery(PROJECT_ITEM_DELETE_REQUEST, deleteItem);
    yield takeEvery(PROJECT_ITEM_UPDATE_REQUEST, updateItem)
    yield takeLatest(PROJECT_VIEW_START, viewStart)

}

export default sagas;