import { Button, EditableText, H1, Icon, InputGroup, NavbarDivider, Popover, ProgressBar } from "@blueprintjs/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import RGL, { WidthProvider } from "react-grid-layout";
import React, { useCallback, useState } from "react";
import { createView, deleteView, updateProjectView } from "../../state/ducks/project/operations";
import { useMutationCreateProjectProject, useMutationCreateView, useMutationDeleteProjectProject } from "../../state/mutations";

import Body from "../../components/Body";
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { PROJECT_PROJECTS } from "../../state/graphql";
import ProjectMenu from "../../components/ProjectMenu";
import ProjectNavBar from "../../components/ProjectNavBar";
import { Query } from "@apollo/react-components";
import Table from "../../components/Table";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";
import moment from "moment";
import { useParams } from "react-router-dom";
import { usePlaceholderData } from "../../utils";

const ProjectProjects= connect((state, ownProps) => {
    return {
      loggedUser: loggedUser(state)
    };
  })(props => {
    const [showSidebar, setShowSidebar] = useState(true);
  const [selectedItems, setSelectedItems] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  
  const [addProjectProject, { data }]= useMutationCreateProjectProject()
  const [deleteProjectProject] = useMutationDeleteProjectProject()

  const [name, setName] = useState("")
  const hasSelectedItems = Object.keys(selectedItems).length > 0
  const history = props.history
  let { projectId } = useParams();
  const createProjectCb = useCallback(async () => {
    
    if (isCreating) {
        return;
      }
      setIsCreating(true);
      try{
          
        const response = await addProjectProject({
            variables: {
                input: {
                    projectId: projectId,
                    name: name
                }
            }
        })
        setIsCreating(false);
        setName("")
        setOpenCreate(false)
        
      }catch(e){
        console.error(e)
        setIsCreating(false);
          setName("")
          setOpenCreate(false)
      }
      
    
  }, [name])
    
 
      
    return (
        <Query fetchPolicy={'cache-and-network'} query={PROJECT_PROJECTS} variables={{id: projectId}}>
      {({ loading, error, data: realData }) => {
        const {data, skeltonClass} = usePlaceholderData({
          data: {
            me: {
              project: {
                name: 'Lorem ipsum dolor',
                id: '123',
                projectViews: [
  
                ],
                itemAttributes: [
  
                ]
              }
            }
          },
          realData,
          loading
        })
          const project = data.me?.project
          const selected = project && hasSelectedItems ? Object.keys(selectedItems).map((k, i) => project.projectProjects[k]) : []
          
          
        return (

      
      <div>
          
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            overflow: "hidden"
          }}
        >
          {showSidebar ? (
            <ProjectMenu project={project} activeMenu={'projects'}></ProjectMenu>
          ) : null}
          <div style={{ flex: "1 1", backgroundColor: "#FBFDFF" }}>
            <ProjectNavBar
              notFixed={true}
              pre={
                <>
                  
                  <Button
                    minimal={true}
                    icon={showSidebar ? "chevron-left" : "menu"}
                    text={showSidebar ? "Menu" : "Menu"}
                    onClick={() => setShowSidebar(!showSidebar)}
                  />
                  <NavbarDivider></NavbarDivider>
                  <div style={{color: 'white', display: 'flex', fontSize: '0.8em'}}>
                    <Link to={'/'} style={{color:'white'}}>
                      All Workspaces
                    </Link> 
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link className={skeltonClass} to={`/projects/${project ? project.id:''}`} style={{color:'white'}}>
                      {project ? project.name:''}
                    </Link>
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link className={skeltonClass} to={`/projects/${project ? project.id:''}/projects`} style={{color:'white'}}>
                      Projects
                    </Link>
                  </div>
                  
                  
                  
                </>
              }
              post={
                <>
                   
                </>
              }
            ></ProjectNavBar>
            <Body boxed>
               
                    <H1><span className={skeltonClass}><Icon style={{marginRight: '0.4em'}} iconSize={38} icon={'projects'}></Icon> Projects</span></H1>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end", marginBottom: '2em' }}
                    >
                    {hasSelectedItems ? <Button icon={"trash"} intent={"danger"}
                    loading={deleting}
                    onClick={async ()=>{
                        setDeleting(true)
                        for(let single of selected){
                            try{
                                await deleteProjectProject({variables: {input: {projectId: project.id, projectProjectId: single.id} }})
                            }catch(e){
                                setSelectedItems({})
                                setDeleting(false)
                            }
                        }
                        setSelectedItems({})
                        setDeleting(false)
                    }}
                    >Delete
                      </Button> : 
                      

<Popover onInteraction={(v) => setOpenCreate(v)} isOpen={openCreate} position={'bottom'} content={(<div style={{padding: '1em', display: 'flex', flexDirection: 'column'}}>
                    <InputGroup autoFocus={true} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} onKeyDown={(e)=>{
                        if (e.keyCode === 13) {
                            createProjectCb();
                          }
                    }}></InputGroup>
                    <div style={{flex:'0 0 1em'}}></div>
                    <Button  fill={true} intent={'primary'} loading={isCreating} onClick={createProjectCb}>
                        Create
                      </Button></div>
                )}>
                <Button className={skeltonClass} icon={"add"} intent={'success'} >
                        Add Project
                </Button>
                </Popover>


                      
                       }
                    </div>
                    <Table
                    className={skeltonClass}
                    selectedRows={selectedItems}
                    onSelectedRowsChange={(r) => setSelectedItems(r)}
                      //   onRowsSelected={(r) => setSelectedItems(r)}
                      columns={[
                        
                        {
                            Header: 'Name',
                            accessor: 'name',
                            Cell: ({cell: { value, row: {original} } }) => {
                              return (<div style={{display: 'flex', alignItems: 'center'}}>
                              <div style={{width: 10, height: 10, backgroundColor: original.color ? original.color : '#b3bac5', marginRight: 10, borderRadius: 5 }}></div> {original.name}</div>)
                            }
                          },
                          {
                            Header: 'Billable',
                            accessor: 'billable',
                            Cell: ({cell: { value, row: {original} } }) => {
                              return original.billable  ? <Icon style={{opacity: 0.6}} icon={'tick-circle'}/> : null
                            }
                          },
                          {
                            Header: 'Final Price',
                            accessor: 'finalPrice',
                            Cell: ({cell: { value, row: {original} } }) => {
                              return <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$'} value={original.finalPrice}></NumberFormat>
                            }
                          },
                          {
                            Header: 'Budget Spent',
                            accessor: 'budget',
                            Cell: ({cell: { value, row: {original} } }) => {
                              return <><NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$'} value={original.budgetSpent}></NumberFormat></>
                            }
                          },
                          
                        {
                          Header: "",
                          accessor: "other",
                          Cell: ({cell: { value, row: {original} } }) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  flex: "1"
                                }}
                              >
                                  
                                  <Link
                          to={`/projects/${project ? project.id : ""}/projects/${original.id}`}
                          style={{ color: "white" }}
                        >
                                <Button
                                  rightIcon={"chevron-right"}
                                  
                                  intent={'primary'}
                                >
                                  Open
                                </Button>
                                </Link>
                              </div>
                            );
                          }
                        }
                      ]}
                      data={[...(data.me?.project.projectProjects || [])]}
                    ></Table>
                  
                </Body>
            
          </div>
        </div>
      </div>
        )
    }}
  </Query>
    );
  });
  
  export default ProjectProjects;
  