const USER_SETUP_REQUEST = "app/user/SETUP_REQUEST";
const USER_SETUP_SUCCESS = "app/user/SETUP_SUCCESS";
const USER_SETUP_FAILURE = "app/user/SETUP_FAILURE";
const USER_UPDATE_REQUEST = "app/user/UPDATE_REQUEST";
const USER_ME_FETCH_REQUEST = "app/user/ME_FETCH_REQUEST";
const USER_ME_FETCH_SUCCESS = "app/user/ME_FETCH_SUCCESS";
const USER_ME_FETCH_FAILURE = "app/user/ME_FETCH_FAILURE";
const USER_LOGOUT_CLEAR_REQUEST = "app/user/LOGOUT_CLEAR_REQUEST";


export {
    USER_SETUP_REQUEST,
    USER_SETUP_SUCCESS,
    USER_SETUP_FAILURE,
    USER_UPDATE_REQUEST,
    USER_ME_FETCH_REQUEST,
    USER_ME_FETCH_SUCCESS,
    USER_ME_FETCH_FAILURE,
    USER_LOGOUT_CLEAR_REQUEST,
};