import * as actions from "./actions";

import { PROJECT_VIEW_START, PROJECT_VIEW_STOP } from "./types";

export const setActual = (project) => ( dispatch: any ) => {
    dispatch( actions.setActual(project) )
}

// This is a thunk which dispatches multiple actions from actions.js
export const createProject = (name, resolve?, reject?) => ( dispatch: any ) => {
    dispatch( actions.requestCreate(name,resolve, reject) )
}
export const fetchAllProject = () => ( dispatch: any ) => {
    dispatch( actions.requestFetchAll() )
}
export const fetchProject = (id) => ( dispatch: any ) => {
    dispatch( actions.requestFetch(id))
}

/* VIEW */
export const startProjectView = (projectId, viewId?) => ( dispatch: any ) => {
    dispatch({type: PROJECT_VIEW_START, payload:{projectId, viewId}})
}
export const stopProjectView = () => ( dispatch: any ) => {
    dispatch({type: PROJECT_VIEW_STOP})
}

/* PANES */
export const createPane = (projectViewId, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestCreatePane(projectViewId, resolve, reject))
}
export const deletePane = (projectViewPaneId, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestDeletePane(projectViewPaneId, resolve, reject))
}
export const updatePane = (projectViewPaneId, data,resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestUpdatePane(projectViewPaneId, data,resolve, reject))
}
export const batchUpdatePane = (inputs,resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestBatchUpdatePane(inputs,resolve, reject))
}


/* ITEMS */
export const createItem = (projectId, data, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestCreateItem(projectId,data, resolve, reject))
}
export const updateItem = (projectId, itemId, data, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestUpdateItem(projectId,itemId,data, resolve, reject))
}

export const fetchAttributes = (projectId, onListen) => (dispatch: any) => {
    dispatch(actions.requestAttributesFetch(projectId, onListen))
}
export const fetchMembers = (projectId, onListen) => (dispatch: any) => {
    dispatch(actions.requestMembersFetch(projectId, onListen))
}

export const createAttribute = (projectId, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestAttributeCreate(projectId, resolve, reject))
} 
export const updateAttribute = (projectId, attributeId, data, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestAttributeUpdate(projectId, attributeId, data, resolve, reject))
} 
export const deleteAttribute = (projectId, attributeId, data, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestAttributeDelete(projectId, attributeId, resolve, reject))
} 

export const createAttributeValue = (projectId, attributeId, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestAttributeValueCreate(projectId, attributeId, resolve, reject))
} 
export const updateAttributeValue = (projectId, attributeId, valueId, data, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestAttributeValueUpdate(projectId, attributeId,valueId, data, resolve, reject))
} 
export const deleteAttributeValue = (projectId, attributeId, valueId, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestAttributeValueDelete(projectId, attributeId,valueId, resolve, reject))
} 


export const updateProjectView = (projectId, projectViewId, data, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestProjectViewUpdate(projectId, projectViewId, data, resolve, reject))
}

export const createInvite = (projectId, role, email, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestProjectInviteCreate(projectId, role,email, resolve, reject))
}
export const deleteInvite = (projectId, inviteId, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestProjectInviteDelete(projectId, inviteId, resolve, reject))
}
export const acceptInvite = (projectId, token, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestProjectInviteAccept(projectId, token, resolve, reject))
}
export const deleteMember = (projectId, memberRoleId, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestProjectMemberDelete(projectId, memberRoleId, resolve, reject))
}

export const createView = (projectId, resolve?, reject?) => (dispatch: any) => {
    
    dispatch(actions.requestCreateView(projectId, resolve, reject))
}

export const deleteView = (projectId, viewId, resolve?, reject?) => (dispatch: any) => {
    dispatch(actions.requestDeleteView(projectId, viewId, resolve, reject))
}