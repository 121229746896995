import * as reducers from "./ducks";

import { applyMiddleware, combineReducers, createStore } from 'redux';

import appSaga from './ducks/sagas'
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist'
import { verifyAuth } from "./ducks/auth/operations";

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers(reducers as any);
const store = createStore(
    rootReducer,composeWithDevTools(applyMiddleware(sagaMiddleware))  
);
sagaMiddleware.run(appSaga)

verifyAuth()(store.dispatch);



  if ((module as any).hot && process.env.NODE_ENV !== 'production') {
     (module as any).hot.accept('./ducks', () => {
      const nextRootReducers = require('./ducks');
      const nextRootReducer = combineReducers(nextRootReducers as any);
      store.replaceReducer(nextRootReducer as any);
    });
  }

export default store

export const persistor = persistStore(store)
