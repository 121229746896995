import * as actions from './actions'

import { LOGIN_REQUEST, LOGOUT_REQUEST, RESET_REQUEST, SIGNUP_REQUEST, VERIFY_REQUEST } from './types';
import { call, put, takeLatest } from 'redux-saga/effects';
import { client, subscriptionClient } from '../../utils/graphql';
import { trackEvent, trackIdentity, trackLogout } from '../../utils/trackEvent';

import { myFirebase } from '../../../firebase/firebase';
import { rejects } from 'assert';
import { resolve } from 'dns';

function *signup(action: any){
    trackEvent('SIGNUP_REQUEST')
    try{
        const auth = myFirebase.auth()
        const user = yield call([auth, auth.createUserWithEmailAndPassword], action.email, action.password);
        yield put(actions.receiveSignup());
        yield put(actions.verifyRequest())
    }catch (e){
        yield put(actions.signupError(e.message))
    }
}

function *login(action: any){
    trackEvent('LOGIN_REQUEST')
    try{
        const auth = myFirebase.auth()
        const user = yield call([auth, auth.signInWithEmailAndPassword], action.email, action.password);
        yield put(actions.receiveLogin(user));
    }catch (e){
        yield put(actions.loginError(e.message))
    }
}
function *reset(action: any){
    trackEvent('RESETPW_REQUEST')
    try{
        const auth = myFirebase.auth()
        const user = yield call([auth, auth.sendPasswordResetEmail], action.email);
        yield put(actions.receiveReset());
    }catch (e){
        yield put(actions.resetError(e.message))
    }
}
function *logout(action?){
    trackEvent('LOGOUT_REQUEST')
    try{
        const auth = myFirebase.auth()
        yield call([client, client.clearStore])
        yield call([auth, auth.signOut]);
        subscriptionClient.close()
        trackLogout()
        yield put(actions.receiveLogout());
        if(action && action.payload && action.payload.resolve){
            action.payload.resolve()
        }
    }catch (e){
        console.error(e)
        yield put(actions.logoutError())
        if(action && action.payload && action.payload.reject){
            action.payload.reject()
        }
    }
}

function *verify(action: any){
    const pp = () => new Promise<firebase.User | null>((resolve,reject) => {
        myFirebase.auth().onAuthStateChanged((user) => {
            // console.log(user)
            if (user){
                
                resolve(user)
            }else{
                reject(null)
            }
           
        })
    })
    try{
        const user = yield call(pp);
        yield put(actions.receiveLogin(user));
        yield put(actions.verifySuccess())
    }catch (e){
        yield put(actions.verifySuccess())
        yield logout()
    }
}
function *authSagas(){
    yield takeLatest(LOGIN_REQUEST, login);
    yield takeLatest(LOGOUT_REQUEST, logout);
    yield takeLatest(VERIFY_REQUEST, verify);
    yield takeLatest(SIGNUP_REQUEST, signup);
    yield takeLatest(RESET_REQUEST, reset);
}

export default authSagas;