export const PROJECT_SET_ACTUAL = "app/project/SET_ACTUAL";

export const PROJECT_CREATE_REQUEST = "app/project/CREATE_REQUEST";
export const PROJECT_CREATE_SUCCESS = "app/project/CREATE_SUCCESS";
export const PROJECT_CREATE_FAILURE = "app/project/CREATE_FAILURE";

export const PROJECT_FETCH_ALL_REQUEST = "app/project/FETCH_ALL_REQUEST";
export const PROJECT_FETCH_ALL_SUCCESS = "app/project/FETCH_ALL_SUCCESS";
export const PROJECT_FETCH_ALL_FAILURE = "app/project/FETCH_ALL_FAILURE";

export const PROJECT_FETCH_REQUEST = "app/project/FETCH_REQUEST";

export const PROJECT_VIEW_START = "app/project/VIEW_START"
export const PROJECT_VIEW_DATA = "app/project/VIEW_DATA"
export const PROJECT_VIEW_STOP = "app/project/VIEW_STOP"

export const PROJECT_VIEW_CREATE_REQUEST = "app/project/VIEW_CREATE_REQUEST"
export const PROJECT_VIEW_DELETE_REQUEST = "app/project/VIEW_DELETE_REQUEST"


export const PROJECT_VIEW_PANE_CREATE_REQUEST = "app/project/VIEW_PANE_CREATE_REQUEST"
export const PROJECT_VIEW_PANE_DELETE_REQUEST = "app/project/VIEW_PANE_DELETE_REQUEST"
export const PROJECT_VIEW_PANE_UPDATE_REQUEST = "app/project/VIEW_PANE_UPDATE_REQUEST"
export const PROJECT_VIEW_PANE_BATCH_UPDATE_REQUEST = "app/project/VIEW_PANE_BATCH_UPDATE_REQUEST"

export const PROJECT_ITEM_CREATE_REQUEST = "app/project/ITEM_CREATE_REQUEST"
export const PROJECT_ITEM_UPDATE_REQUEST = "app/project/ITEM_UPDATE_REQUEST"
export const PROJECT_ITEM_DELETE_REQUEST = "app/project/ITEM_DELETE_REQUEST"


export const PROJECT_ITEMS_DATA = "app/project/ITEMS_DATA"
export const PROJECT_ITEMS_SYNC_REQUEST = "app/project/ITEMS_SYNC_REQUEST"

export const PROJECT_ITEM_SUBSCRIPTION_CREATED = "app/project/ITEM_SUBSCRIPTION_CREATED"
export const PROJECT_ITEM_SUBSCRIPTION_DELETED = "app/project/ITEM_SUBSCRIPTION_DELETED"

export const PROJECT_ATTRIBUTES_FETCH_REQUEST = "app/project/ATTRIBUTES_FETCH_REQUEST"
export const PROJECT_ATTRIBUTES_FETCH_SUCCESS = "app/project/ATTRIBUTES_FETCH_SUCCESS"
export const PROJECT_ATTRIBUTES_FETCH_FAILURE = "app/project/ATTRIBUTES_FETCH_FAILURE"

export const PROJECT_MEMBERS_FETCH_REQUEST = "app/project/MEMBERS_FETCH_REQUEST"
export const PROJECT_MEMBERS_FETCH_SUCCESS = "app/project/MEMBERS_FETCH_SUCCESS"
export const PROJECT_MEMBERS_FETCH_FAILURE = "app/project/MEMBERS_FETCH_FAILURE"

export const PROJECT_ATTRIBUTE_CREATE_REQUEST = "app/project/ATTRIBUTE_CREATE_REQUEST"
export const PROJECT_ATTRIBUTE_UPDATE_REQUEST = "app/project/ATTRIBUTE_UPDATE_REQUEST"
export const PROJECT_ATTRIBUTE_DELETE_REQUEST = "app/project/ATTRIBUTE_DELETE_REQUEST"
export const PROJECT_ATTRIBUTE_VALUE_CREATE_REQUEST = "app/project/ATTRIBUTE_VALUE_CREATE_REQUEST"
export const PROJECT_ATTRIBUTE_VALUE_UPDATE_REQUEST = "app/project/ATTRIBUTE_VALUE_UPDATE_REQUEST"
export const PROJECT_ATTRIBUTE_VALUE_DELETE_REQUEST = "app/project/ATTRIBUTE_VALUE_DELETE_REQUEST"

export const PROJECT_VIEW_UPDATE_REQUEST = "app/project/VIEW_UPDATE_REQUEST"

export const PROJECT_INVITE_CREATE_REQUEST = "app/project/INVITE_CREATE_REQUEST"
export const PROJECT_INVITE_DELETE_REQUEST = "app/project/INVITE_DELETE_REQUEST"
export const PROJECT_INVITE_ACCEPT_REQUEST = "app/project/INVITE_ACCEPT_REQUEST"
export const PROJECT_MEMBER_DELETE_REQUEST = "app/project/MEMBER_DELETE_REQUEST"