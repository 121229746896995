import {
    Alignment,
    Button,
    ButtonGroup,
    Card,
    Classes,
    FormGroup,
    H2,
    Icon,
    InputGroup,
    Intent,
    Popover,
    Switch
} from "@blueprintjs/core";
import Avatar, { AppearanceType, SizeType } from "@atlaskit/avatar";
import React, { useCallback, useEffect, useState } from "react";
import {
    createProject,
    fetchAllProject
} from "../../state/ducks/project/operations";
import { fetchLoggedUser, updateUser } from "../../state/ducks/user/operations";

import AvatarGroup from "@atlaskit/avatar-group";
import Body from "../../components/Body";
import FlexView from "react-flexview";
import Fuse from "fuse.js";
import Logo from "../../components/UI/Logo";
import NavBar from "../../components/NavBar";
import ProfileForm from "../../components/ProfileForm";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";
import { useDebounce } from "use-debounce";
import {useDropzone} from 'react-dropzone'

const Profile = connect(state => {
    return {
      theme: state.interface.theme,
      projects: state.project.data,
      isCreatingProject: state.project.isCreating,
      isFetching: state.project.isFetching,
      loggedUser: loggedUser(state)
    };
  })(props => {
      
    const { theme, dispatch, projects, isCreatingProject, isFetching, loggedUser } = props;
    const [saving, setSaving]  = useState(false)
    const initialErrors = {
      firstName: [],
      lastName: [],
      username: []
    }
    const [formErrors, setErrors] = useState(initialErrors)
    const [form, setForm] = useState({
      firstName: loggedUser ? loggedUser.firstName || '' : '',
      lastName: loggedUser ? loggedUser.lastName || '' : '',
      username: loggedUser ? loggedUser.username || '' : '',
    });
    
    const saveUser = useCallback(() => {
      setSaving(true)
      setErrors(initialErrors)
      updateUser(form)(dispatch).then(() => {
          fetchLoggedUser()(dispatch).then(() => {
              setSaving(false)
            //   props.history.push('/')
          })
      }).catch((e) => {
        if(e.graphQLErrors){
          // console.log(e.graphQLErrors)
          setErrors(e.graphQLErrors[0].extensions.exception.data)
        }
          setSaving(false)
      });
    }, [form])
    return (
      <div>
        <NavBar></NavBar>
        <Body boxed>
          <Card elevation={2} style={{maxWidth: '500px', margin: 'auto'}}>
            <ProfileForm form={form} setForm={setForm} formErrors={formErrors}></ProfileForm>
            <Button fill={true} large={true} intent={'primary'} onClick={saveUser} loading={saving}>Save</Button>
            <div style={{paddingTop: '1em', textAlign:'center'}}>
            <Button minimal={true} onClick={() => {
                props.history.goBack()
            }}>Back</Button>
            </div>
            
          </Card>
        </Body>
      </div>
    );
  });
  
  export default Profile;
  