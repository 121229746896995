import * as types from "./types";

const setTheme = (theme) => ({
    type: types.THEME_SET_REQUEST,
    payload: {
        theme
    }
});

export {
    setTheme
};