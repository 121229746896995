import { call, put, takeLatest } from 'redux-saga/effects';

function *login(action: any){
    try{
        // const loginResponse = yield call(()=>{
        //     return {}
        // }, action.username, action.password);
        // yield put(sessionActions.loginSuccess(username));
    }catch (e){
        // yield put(sessionActions.loginError(e))
    }
}

function *loginSagas(){
    yield takeLatest("LOGIN_REQUEST", login);
}

export default loginSagas;