import React, { useEffect } from 'react'
export default () => {
    useEffect(() => {
        const config = {
          selector: ".changelogLink",
          trigger: ".toggleWidget",
          account: "yoXLOx"
        };
        (window as any).Headway.init(config);
    }, [])
    return (<a className={'changelogLink toggleWidget'}>What's new?</a>)
}