import 'd3-color'
import 'd3-interpolate'

import React, { useEffect, useState } from 'react'

import ValidateJS from 'validate.js'
import {interpolateCool} from 'd3-scale-chromatic'
import toaster from '../state/utils/toaster'

export const joinList = (elems) => {
    return (elems.length > 1 ? <ul>{elems.map((el, i) => (<li key={i}>{el}</li>))}</ul> : elems[0])
}

export const parseErrors = (e) => {
    if(e.graphQLErrors){
        return e.graphQLErrors[0].extensions.exception.data
    }
    return null
}
export const usePlaceholderData = ({data, realData, loading}) => {
    // console.log({realData})
    let newData = realData ? realData : data
    let skelton = !realData
    return {data: newData, skelton, skeltonClass: skelton?'bp3-skeleton': '' }
}
export const useForm = ({values, successMessage,isNew=false, constraints=null,createConstraints=null, updateConstraints=null} : {values: any,successMessage: string,isNew?: boolean,constraints?: any, createConstraints?: any, updateConstraints?: any}) => {
    const [initialValues, setInitialValues] = useState(values)
    const [internalValues, setValues] = useState(values)
    const [changes, setChanges] = useState({})
    const [errors, setErrors] = useState({})
    const [isDirty, setIsDirty] = useState(false)
    const [saving, setSaving] = useState(false)
    useEffect(() => {
        setInitialValues(values)
        setValues(values);
        setIsDirty(false)
        setChanges({})
   }, [values])
    const getValue = (key, fallback:any = undefined) => {
        return internalValues ? internalValues[key] : fallback
    }
    const getInitialValue = (key, fallback: any = undefined) => {
        return initialValues ? initialValues[key] : fallback
    }
    const setValue = (key, val) => {
        setValues((a) => ({...a, [key]: val}))
        setChanges((a) => ({...a, [key]: val}))
        setIsDirty(true)
    }
    const groupProps = (key) => {
        return {
            helperText: errors[key] ? joinList(errors[key]) as any : null,
            intent: errors[key] && errors[key].length > 0 ? 'danger' as any :'none' as any
        }
    }
    const handleCatch = (e) => {
        if (!e.status && !e.graphQLErrors) {
            toaster.show({ message: 'We cannot reach our servers, please check your connection.', icon: 'error', intent: 'danger'})
        }else if(e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message){
            toaster.show({ message: e.graphQLErrors[0].message, icon: 'error', intent: 'danger'})
            setErrors(e.graphQLErrors[0].extensions.exception.data)
        }else if(e.message){
            toaster.show({ message: e.message, icon: 'error', intent: 'danger'})
        }
        setSaving(false)
    }
    const handleThen = (response) => {
        setSaving(false)
        setChanges({})
        setIsDirty(false)
        setInitialValues(internalValues)
        setErrors({})
        if(successMessage){
            toaster.show({ message: successMessage, icon: 'saved', intent: 'success'})
        }
    }
    const validate = () => {
        if(createConstraints && isNew){
            const validation = ValidateJS.validate(changes, createConstraints)
            if(validation){
                setErrors(validation)
                return false
            }
           
        }
        if(updateConstraints && !isNew){
            const validation = ValidateJS.validate(changes, updateConstraints)
            if(validation){
                setErrors(validation)
                return false
            }
           
        }
        if(constraints){
            const validation = ValidateJS.validate(internalValues, constraints)
            if(validation){
                setErrors(validation)
                return false
            }
        }
        return true
    }
    const revert = () => {
        setChanges({})
        setValues(initialValues)
        setIsDirty(false)
        setErrors({})
    }
    return {getValue, setValue, isDirty, groupProps, handleThen, handleCatch, saving, setSaving, changes,revert, getInitialValue, validate}
}


function calculatePoint(i, intervalSize, colorRangeInfo) {
    var { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
    return (useEndAsStart
      ? (colorEnd - (i * intervalSize))
      : (colorStart + (i * intervalSize)));
  }
  
  /* Must use an interpolated color scale, which has a range of [0, 1] */
  export const interpolateColors = ({dataLength}) => {
    const colorScale = interpolateCool;
    const colorRangeInfo = {
    colorStart: 0,
    colorEnd: 1,
    useEndAsStart: false,
    };
    var { colorStart, colorEnd } = colorRangeInfo;
    var colorRange = colorEnd - colorStart;
    var intervalSize = colorRange / dataLength;
    var i, colorPoint;
    var colorArray: any = [];
    for (i = 0; i < dataLength; i++) {
      colorPoint = calculatePoint(i, intervalSize, colorRangeInfo);
      colorArray.push(colorScale(colorPoint));
    }
    return colorArray;
  }