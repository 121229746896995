import { Button, Card, EditableText, FormGroup, H1, H2, H3, InputGroup, NavbarDivider, NumericInput, Popover, ProgressBar } from "@blueprintjs/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import RGL, { WidthProvider } from "react-grid-layout";
import React, { useCallback, useState } from "react";
import { createView, deleteView, updateProjectView } from "../../state/ducks/project/operations";
import { interpolateColors, joinList, useForm } from "../../utils";
import { useMutationCreateProjectProject, useMutationCreateProjectProjectFee, useMutationCreateView, useMutationDeleteProjectProject, useMutationDeleteProjectProjectFee, useMutationUpdateProjectProject } from "../../state/mutations";

import Body from "../../components/Body";
import ColorPickerField from "../../components/ColorPickerField";
import { Doughnut } from 'react-chartjs-2';
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { PROJECT_PROJECT } from "../../state/graphql";
import ProjectMenu from "../../components/ProjectMenu";
import ProjectNavBar from "../../components/ProjectNavBar";
import { Query } from "@apollo/react-components";
import Select from "react-select";
import Table from "../../components/Table";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";
import moment from "moment";
import { useParams } from "react-router-dom";

const ProjectProjectForm = ({project, projectProject}) => {
    const {getValue, setValue, isDirty, groupProps, handleCatch, saving, setSaving, changes, handleThen, revert, getInitialValue} = useForm({values: projectProject, successMessage: 'Project saved!'})
    const [updateProjectProject] = useMutationUpdateProjectProject()
  const billableTypes = [
    {label: 'No', value: false},
    {label: 'Yes', value: true}
  ]
  const baselinePrice = projectProject ? projectProject.projectProjectFees.map((line) => (line.unitFee || 0) * (line.qtyEstimate || 0)).reduce((a,b) => a+b, 0):0
  const finalPrice = getValue('finalPrice', 0)
  const costs = projectProject ? projectProject.projectProjectFees.map((line) => (line.unitCost || 0) * (line.qtyEstimate || 0)).reduce((a,b) => a+b, 0) : 0
  const realCosts = projectProject ? projectProject.projectProjectFees.map((line) => (line.budgetSpent || 0)).reduce((a,b) => a+b, 0) : 0

  const marginValue = getValue('finalPrice', 0) - costs
  const realMarginValue = getValue('finalPrice', 0) - realCosts
  return (<div >
        <div style={{display:'flex', justifyContent:'flex-end'}}>
            {isDirty && <Button style={{marginRight: '1em'}} icon={'undo'} onClick={() => {
                revert()
            }}>Revert</Button>}
            <Button intent={'primary'} disabled={!isDirty} loading={saving} icon={'floppy-disk'} onClick={() => {
                setSaving(true)
                console.log({changes})
                updateProjectProject({
                    variables: {
                        input: {
                            projectId: project.id,
                            projectProjectId: projectProject.id,
                            ...changes
                        }
                    }
                }).then((a) => {
                    handleThen(a)
                    console.log({a})
                }).catch((b) => {
                    handleCatch(b)
                })
            }}>Save</Button>
            </div>
            <div style={{display: 'grid', gridGap: "0.5em", gridTemplateColumns: "0.5fr 2fr 1fr 3fr"}}>
            <FormGroup label={<span style={{opacity: 0}}>A</span>} {...groupProps('color')}>
          <ColorPickerField value={getValue('color', null)}  onChange={val => {
                setValue('color', val)
                
            }}></ColorPickerField>
           
        </FormGroup>
            <FormGroup label="Project Name" labelInfo={'*'} {...groupProps('name')}>
            <InputGroup
            
            large={true}
            id="firstName"
            placeholder={"Project Name"}
            
            value={getValue('name', "")}
            onChange={e => {
                setValue('name', e.target.value)
                
            }}
            ></InputGroup>
        </FormGroup>
        <FormGroup label="Billable" labelInfo={'*'} {...groupProps('billable')}>
        <Select
            placeholder={"Select a type"}
            className={"SESelect"}
            classNamePrefix={"SESelect"}
            value={billableTypes.find((v) => v.value === getValue('billable'))}
            onChange={(v: any) => {
              setValue("billable", v.value);
              
            }}
            options={billableTypes}
          />

        </FormGroup>
        <FormGroup label="Final Price" {...groupProps('finalPrice')}>
            <NumberFormat thousandSeparator={true} prefix={'$'} placeholder={"Final Price"} allowNegative={false} 
            value={getValue('finalPrice', null)}
            className={'bp3-input bp3-large bp3-fill'}
            onValueChange={(valueAsNumber) => {
                
                if(valueAsNumber.floatValue !== getInitialValue('finalPrice'))
                    setValue('finalPrice', valueAsNumber.floatValue ? valueAsNumber.floatValue : null)
            }}
             />
           
        </FormGroup>
        

            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
              <div style={{display: 'grid',width: '300px', gridGap: "0.5em", textAlign: 'right', gridTemplateColumns: "1fr 1fr 1fr"}}>
                <div></div>
                <div></div>
                <div style={{ fontWeight: 'bold', textTransform: 'uppercase', opacity: 0.6}}>Actual</div>
                <div>Baseline Price:</div>
                <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$ '} value={baselinePrice}></NumberFormat>
                <div></div>
                <div>Discount:</div>
                <div>{((((finalPrice-baselinePrice)/finalPrice)*100) || 0).toFixed(2)} %</div>
                <div></div>
                <div>Final Price:</div>
                <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$ '} value={finalPrice}></NumberFormat>
                <div></div>
                <div>Costs:</div>
                <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$ '} value={costs}></NumberFormat>
                <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$ '} value={realCosts}></NumberFormat>
                <div>Margin:</div>
                <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$ '} value={marginValue}></NumberFormat>
                <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$ '} value={realMarginValue}></NumberFormat>
                <div>Margin:</div>
                <div>{(((marginValue/finalPrice)*100) || 0).toFixed(2)} %</div>
                <div>{(((realMarginValue/finalPrice)*100)||0).toFixed(2)} %</div>
              </div>
            
             
            </div>
    </div>)
}
const ProjectProject= connect((state, ownProps) => {
    return {
      loggedUser: loggedUser(state)
    };
  })(props => {
    const [showSidebar, setShowSidebar] = useState(true);
  const [selectedItems, setSelectedItems] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [addProjectProjectFee, { data }]= useMutationCreateProjectProjectFee()
  const [deleteProjectProjectFee] = useMutationDeleteProjectProjectFee()

  const [name, setName] = useState("")
  const hasSelectedItems = Object.keys(selectedItems).length > 0
  const history = props.history
  let { projectId, projectProjectId } = useParams();
  const createProjectCb = useCallback(async () => {
    
    if (isCreating) {
        return;
      }
      setIsCreating(true);
      try{
          
        const response = await addProjectProjectFee({
            variables: {
                input: {
                    projectId: projectId,
                    projectProjectId: projectProjectId,
                    name: name
                }
            }
        })
        setIsCreating(false);
        setName("")
        setOpenCreate(false)
        
      }catch(e){
        console.error(e)
        setIsCreating(false);
          setName("")
          setOpenCreate(false)
      }
      
    
  }, [name])
    
 
      
    return (
        <Query fetchPolicy={'network-only'} query={PROJECT_PROJECT} variables={{id: projectId, where: {id: {equals: projectProjectId}}}}>
      {(response) => {
        const { loading, error, data } = response
          const project = data?.me?.project
          const actual = data?.me?.project.actualProjectProject[0]
          const selected = project && hasSelectedItems ? Object.keys(selectedItems).map((k, i) => actual.projectProjectFees[k]) : []
         
          return (

      
      <div>
          
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            overflow: "hidden"
          }}
        >
          {showSidebar ? (
            <ProjectMenu project={project} activeMenu={'projects'}></ProjectMenu>
          ) : null}
          <div style={{ flex: "1 1", backgroundColor: "#FBFDFF" }}>
            <ProjectNavBar
              notFixed={true}
              pre={
                <>
                  
                  <Button
                    minimal={true}
                    icon={showSidebar ? "chevron-left" : "menu"}
                    text={showSidebar ? "Menu" : "Menu"}
                    onClick={() => setShowSidebar(!showSidebar)}
                  />
                  <NavbarDivider></NavbarDivider>
                  <div style={{color: 'white', display: 'flex', fontSize: '0.8em'}}>
                    <Link to={'/'} style={{color:'white'}}>
                      All Workspaces
                    </Link> 
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link to={`/projects/${project ? project.id:''}`} style={{color:'white'}}>
                      {project ? project.name:''}
                    </Link>
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link to={`/projects/${project ? project.id:''}/projects`} style={{color:'white'}}>
                      Projects
                    </Link>
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link to={`/projects/${project ? project.id:''}/projects/${actual ? actual.id : ''}`} style={{color:'white'}}>
                      {actual && actual.name}
                    </Link>
                  </div>
                  
                  
                  
                </>
              }
              post={
                <>
                   
                </>
              }
            ></ProjectNavBar>
            <Body boxed>
                
                     
                    <H1>{actual && actual.name}</H1>

                    <Card style={{marginBottom:'2em'}}>
                        <ProjectProjectForm project={project} projectProject={actual}/>
                    </Card>
                    
                    
                    <Card>
                      <H2>Activities</H2>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                    {hasSelectedItems ? <Button icon={"trash"} intent={"danger"}
                    loading={deleting}
                    onClick={async ()=>{
                        setDeleting(true)
                        for(let single of selected){
                            try{
                                await deleteProjectProjectFee({variables: {input: {projectId: project.id, projectProjectId: projectProjectId, projectProjectFeeId: single.id} }})
                            }catch(e){
                                setSelectedItems({})
                                setDeleting(false)
                            }
                        }
                        setSelectedItems({})
                        setDeleting(false)
                    }}
                    >Delete
                      </Button> : 
                      

<Popover onInteraction={(v) => setOpenCreate(v)} isOpen={openCreate} position={'bottom'} content={(<div style={{padding: '1em', display: 'flex', flexDirection: 'column'}}>
                    <InputGroup autoFocus={true} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} onKeyDown={(e)=>{
                        if (e.keyCode === 13) {
                            createProjectCb();
                          }
                    }}></InputGroup>
                    <div style={{flex:'0 0 1em'}}></div>
                    <Button fill={true} intent={'primary'} loading={isCreating} onClick={createProjectCb}>
                        Create
                      </Button></div>
                )}>
                <Button icon={"add"} intent={'success'} >
                        Add Activity
                </Button>
                </Popover>


                      
                       }
                    </div>
                    <div>
                    <Table
                    selectedRows={selectedItems}
                    onSelectedRowsChange={(r) => setSelectedItems(r)}
                      //   onRowsSelected={(r) => setSelectedItems(r)}
                      columns={[
                        
                        {
                            Header: 'Name',
                            accessor: 'name',
                            width: 1
                          },
                          
                          {
                            Header: 'Qty',
                            accessor: 'qtyEstimate',
                            width: 1,
                            Cell: ({cell: { value, row: {original} } }) => {
                            return <>{original.qtyEstimate || 0} {original.type === 'time' ? 'h':''}</>
                            }
                          },
                          {
                            Header: 'Unit Fee',
                            accessor: 'unitFee',
                            width: 1,
                            Cell: ({cell: { value, row: {original} } }) => {
                              return <div>
                                <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$'} value={original.unitFee}></NumberFormat>
                                <div style={{fontSize: '0.8em', opacity: 0.6}}>x {original.qtyEstimate}{original.type==='time'?'h':''} = <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$'} value={original.unitFee * original.qtyEstimate}></NumberFormat></div>
                              </div>
                            }
                          },
                          {
                            Header: 'Unit Cost',
                            accessor: 'unitCost',
                            width: 1,
                            Cell: ({cell: { value, row: {original} } }) => {
                              return <div>
                                <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$'} value={original.unitCost}></NumberFormat>
                                <div style={{fontSize: '0.8em', opacity: 0.6}}>x {original.qtyEstimate}{original.type==='time'?'h':''} = <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$'} value={original.unitCost * original.qtyEstimate}></NumberFormat></div>
                              </div>
                             
                            }
                          },
                          // {
                          //   Header: 'Logged Hours',
                          //   accessor: 'hoursEstimate',
                          //   Cell: ({cell: { value, row: {original} } }) => {
                          //   return <><span>{original.hoursSpent}</span><span style={{fontSize: '0.8em', paddingLeft: '0.5em'}}>/ {original.hoursEstimate || 0}</span></>
                          //   }
                          // },
                          {
                            Header: 'Logged Progress',
                            accessor: 'budget',
                            width: 1,
                            Cell: ({cell: { value, row: {original} } }) => {
                              return <div>
                                <div>
                                  {((original.qtySpent/original.qtyEstimate || 0)*100).toFixed(2)} %
                                </div>
                               {original.type === 'time' ?  <div style={{fontSize: '0.8em', opacity: 0.6}}>
                                <span>{original.type==='time'?'H':''} {original.qtySpent}</span><span style={{ paddingLeft: '0.3em'}}>/ {original.qtyEstimate || 0}</span>
                                </div>: null}

                                <div style={{fontSize: '0.8em', opacity: 0.6}}>
                                <NumberFormat thousandSeparator={true} displayType={'text'} prefix={'$ '} value={original.budgetSpent}></NumberFormat><span style={{paddingLeft: '0.3em'}}>/ <NumberFormat thousandSeparator={true} displayType={'text'} value={original.budget || 0}></NumberFormat></span>
                                </div>
                              </div>
                            }
                          },
                          
                        {
                          Header: "",
                          accessor: "other",
                          width: 1,
                          Cell: ({cell: { value, row: {original} } }) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  flex: "1"
                                }}
                              >
                                  
                                  <Link
                          to={`/projects/${project ? project.id : ""}/projects/${projectProjectId}/fees/${original.id}`}
                          style={{ color: "white" }}
                        >
                                <Button
                                  rightIcon={"chevron-right"}
                                  
                                  intent={'primary'}
                                >
                                  Open
                                </Button>
                                </Link>
                              </div>
                            );
                          }
                        }
                      ]}
                      data={[...(actual && actual.projectProjectFees || [])]}
                    ></Table>
                    </div>
                  </Card>
                  <div style={{marginTop: '3em',marginBottom: '3em',display: 'grid', gridGap: "2em", gridTemplateColumns: "1fr 1fr"}}>
                  {actual && actual.projectProjectFees && actual.projectProjectFees.length ? <Card>
                   <H3>Hours Allocation</H3>
                 <Doughnut data={{
                    datasets: [{
                        data: actual.projectProjectFees.filter((t) => t.type === 'time').map((fee) => fee.qtyEstimate),
                        backgroundColor: interpolateColors({dataLength: actual.projectProjectFees.length})
                    }],
                    labels: actual.projectProjectFees.filter((t) => t.type === 'time').map((fee) => fee.name),
                    
                }} />
                 </Card> : null}
                 {actual && actual.projectProjectFees && actual.projectProjectFees.length ? <Card>
                   <H3>Budget Allocation</H3>
                 <Doughnut data={{
                    datasets: [{
                      data: actual.projectProjectFees.map((fee) => fee.budget),
                      backgroundColor: interpolateColors({dataLength: actual.projectProjectFees.length})
                  }],
                  labels: actual.projectProjectFees.map((fee) => fee.name)
                    
                }} />
                 </Card> : null}
                 
                 </div>
                </Body>
            
          </div>
        </div>
      </div>
        )
    }}
  </Query>
    );
  });
  
  export default ProjectProject;
  