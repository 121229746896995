import ItemListColumn from '../ItemListColumn'
import React from "react"
import { connect } from "react-redux";
import { viewItems } from "../../state/ducks/project/selectors";

export default connect((state, ownProps) => ({
    items: viewItems(state, ownProps.filters, ownProps.groupBy, ownProps.searchText),
    
  }))(props => {
    let groups = [{ id: "", value: "" }];
    if (props.groupBy) {
      const groupByAttribute = props.project.itemAttributes.find(
        i => i.id === props.groupBy
      );
      groups = [{ id: "", value: "Not Assigned" }, ...groupByAttribute.values];
    }
    const stx = props.groupBy
      ? { width: 250, marginRight: 20, flex: "0 0 250px" }
      : { width: 900, margin: "auto" };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "1em",
          paddingBottom: "80px",
          position: "absolute",
          width: "100%"
        }}
      >
        {groups.map(k => (
          <ItemListColumn key={k.id} stx={stx} k={k} {...props} />
        ))}
      </div>
    );
  });