import { Popover } from '@blueprintjs/core';
import React from 'react'

export default (props) => {
  const {displayName, shortDisplayName, imageUrl, size} = props

  return (
    <Popover
      hoverCloseDelay={100}
      transitionDuration={300}
      content={
        <div className={"listItemMemberPopover"}>{displayName}</div>
      }
      interactionKind={"hover"}
      position={"bottom"}
    >
      <div className={"listItemMember"} style={{
          width: size ? size + 'px' : '20px',
          height: size ? size + 'px' : '20px',
          fontSize: '70%'
      }}>{shortDisplayName}</div>
    </Popover>
  );
};
