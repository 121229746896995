import { Redirect, Route } from "react-router-dom";

import React from "react";
import { Spinner } from "@blueprintjs/core";
import {
  withRouter
} from 'react-router-dom'

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isVerifying,
  loggedUser,
  isOnboard = false,
  ...rest
}) => {
  let scase = 'component'
  const intendedRedirect = window.localStorage.getItem('intendedRedirect')
  const userDataLoading = (isAuthenticated && (loggedUser === undefined))
  const userAlreadyOnboarded = (loggedUser !== null && loggedUser !== undefined)
  const userMissingOnboard = (loggedUser === null)
  const isMobile = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)
  if(isVerifying || userDataLoading){
    scase = 'spinner'
  }else{
    if(!isAuthenticated){
      scase = 'loginRedirect'
    }
    if(isAuthenticated && userMissingOnboard && rest.path !== '/onboard'){
      scase = 'onboardRedirect'
    }
    if(isAuthenticated && userAlreadyOnboarded && isMobile && rest.path !== '/mobile'){
      scase = 'mobileRedirect'
    }
    if(isAuthenticated && userAlreadyOnboarded && intendedRedirect){
      scase = 'intendedRedirect'
      window.localStorage.removeItem('intendedRedirect')
    }
  }
  
  return (<Route
    {...rest}
    render={props => {
      
      let val
      switch(scase){
        case 'spinner':
          val = (<div style={{paddingTop: '45vh'}}><Spinner></Spinner></div>)
          break;
        case 'onboardRedirect':
          val = (<Redirect to={'/onboard'}></Redirect>)
          break;
        case 'mobileRedirect':
          val = (<Redirect to={'/mobile-alert'}></Redirect>)
          break;
        case 'component':
          val = (<Component {...props} />)
          break;
        case 'loginRedirect':
          // Set intended
          window.localStorage.setItem('intendedRedirect', props.location.pathname)
          val = <Redirect
            to={{
              pathname: "/login",

              state: { from: props.location, showAlert: true }
            }}
          />
          break;
        case 'intendedRedirect':
          val = <Redirect
            to={{
              pathname: intendedRedirect,
              state: { from: props.location }
            }}
          />
          break;
      }
      return val
    }}
  />)
  };

export default ProtectedRoute;