import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { from, split } from 'apollo-link';

// Remove the apollo-boost import and change to this:
import ApolloClient from "apollo-client";
import { GraphQLClient } from 'graphql-request'
import { HttpLink } from 'apollo-link-http';
// Setup the network "links"
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import gql from 'graphql-tag'
import { myFirebase } from '../../firebase/firebase'
import { persistCache } from 'apollo-cache-persist';
import { setContext } from "apollo-link-context";
import store from '../store';

const auth = myFirebase.auth()

export const request  = async (query, variables = {}) => {
    let headers: any = {}
    const currentUser = auth.currentUser
    if(currentUser){
        const token = await currentUser.getIdToken()
        headers.authorization = 'Bearer ' + token
    }
    const graphQLClient = new GraphQLClient(process.env.REACT_APP_GRAPHQL_URL as any, {
        headers
    })
    return graphQLClient.request(query, variables)
}
const getAuthorizationHeader = async () => {
    const currentUser = auth.currentUser
    if(currentUser){
        const token = await currentUser.getIdToken()
        return 'Bearer ' + token
    }
    return null
}
const httpLink1 = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL, // use https for secure endpoint
  });
  const authMiddleware = setContext(async (operation) => {
        let headers: any = {}
        const token = await getAuthorizationHeader()
        if(token){
            headers.authorization = token
        }
      return {
        headers: headers
      };
    
  });
  const httpLink = from([authMiddleware, httpLink1])
  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WEBSOCKET_URL as any, // use wss for a secure endpoint
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        let params: any = {}
        const token = await getAuthorizationHeader()
        if(token){
            params.Authorization = token
        }
        return params
      },
    }
  });
  // @ts-ignore at the time of writing the field is private and untyped
  export const subscriptionClient = wsLink.subscriptionClient as SubscriptionClient;

  subscriptionClient.onConnecting(() => {
    store.dispatch({type:'WS/CONNECTING'})
  });

  subscriptionClient.onConnected(() => {
    store.dispatch({type:'WS/CONNECTED'})
  });

  subscriptionClient.onReconnecting(() => {
    store.dispatch({type:'WS/RECONNECTING'})
  });

  subscriptionClient.onReconnected(() => {
    store.dispatch({type:'WS/RECONNECTED'})
  });

  subscriptionClient.onDisconnected(() => {
    store.dispatch({type:'WS/DISCONNECTED'})
  });
  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation }: any = getMainDefinition(query);

      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );
  const cache = new InMemoryCache({
    dataIdFromObject: (object: any) => {
      switch (object.__typename) {
        case 'ProjectItemAttribute': 
          if(object.id.length < 25){ // Fixo la cache dei values di campi preesistenti
            return null;
          }else{
            return `ProjectItemAttribute:${object.id}`;
          }
        default: return defaultDataIdFromObject(object); // fall back to default handling
      }
    }
  });
   persistCache({ //await
    cache,
    storage: window.localStorage as any,
  });
// Instantiate client
export const client = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
  })
//   setTimeout(() => {
   
//   }, 5000)
//   client.writeQuery({
//     query: gql`query{mez{
//         id
//         photoUrl
//     }}`,
//     data: {mez: {__typename:'User', id: 'ck4yashed0002kp1847jnvypq', photoUrl:'cocacola'}}
// })
// console.log(client.cache.extract())
// // client.subscribe({query:'', variables: {}}).subscribe()
// client.mutate({mutation:'',})

// Più sagas sotto project ognuno si occupa di un silos e del fetchmore
// Ogni sagas fa una watchQuery con fetchMore
// 
// projectPanes - No sync/No Batch
// projectView - Single / Sync con sotto panes
// items - Sync/Batch
// deletedAt sempre come campo
