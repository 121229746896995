import React, { useEffect, useState } from 'react'
import { Redirect, Route } from "react-router-dom";

import { acceptInvite } from '../state/ducks/project/operations';
import { connect } from "react-redux";

export default connect((state, ownProps) => {
    
})((props) => {
    useEffect(() => {
        acceptInvite(props.match.params.projectId, props.match.params.token, () => {
            props.history.push('/projects/'+props.match.params.projectId)
        }, (e) => {
            props.history.push('/')
        })(props.dispatch)
        
    }, [])
    return (<div></div>) 
})