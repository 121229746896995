import "./style.css";
import "react-quill/dist/quill.bubble.css";
import "moment/min/locales";
import "react-datepicker/dist/react-datepicker.css";

import {
  Button,
  EditableText,
  FormGroup,
  H2,
  Icon,
  InputGroup,
  MenuItem,
  NumericInput,
  Spinner
} from "@blueprintjs/core";
import React, { useState } from "react";
import { itemById, projectView } from "../../state/ducks/project/selectors";
import { useDebounce, useDebounceCallback } from "@react-hook/debounce";

import DatePicker from 'react-datepicker';
import DateTime from "react-datetime";
// import { DateTimePicker } from '@atlaskit/datetime-picker';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import BalloonEditor from '@ckeditor/ckeditor5-build-balloon-block';
// import CKEditor from '@ckeditor/ckeditor5-react';
import ReactQuill from "react-quill";
import Select from "react-select";
import { connect } from "react-redux";
import moment from "moment";
import styled from 'styled-components';
import { updateItem } from "../../state/ducks/project/operations";
import { useMutationUpdateItem } from "../../state/mutations";

moment.locale(
  (window as any).navigator.userLanguage || (window as any).navigator.language
);
const selectStyles = {
  menuPortal: (base, state) => ({
    ...base,
    zIndex: 99999
  })
};
const dateTextVal = (oldAttributes, attribute) => {
  let value = oldAttributes[attribute.id]
    ? moment(oldAttributes[attribute.id])
    : undefined;
  let textValue = "";
  if (value) {
    if (
      value.isSame(value.clone().startOf("day")) &&
      attribute.id === "startDate"
    ) {
      console.log(
        value.toISOString(),
        value
          .clone()
          .startOf("day")
          .toISOString()
      );
      textValue = value.format("L");
    } else if (
      value.isSame(value.clone().endOf("day")) &&
      attribute.id === "endDate"
    ) {
      textValue = value.format("L");
    } else {
      textValue = value.format("L") + " " + value.format("LT");
    }
  }
  return textValue;
};
const SavingDiv = styled.div`
    display: inline-block;
    float: right;
    position:relative;
    right: 0.8em;
`
const StyledLabels = styled.div`
  .bp3-label{
    text-transform: uppercase;
    color: #c7c5c5;
  }
`
const StyledFieldsGrid = styled.div`
    background: #f9f9f9;
    display: grid;
    gap: 0.5em;
    grid-template-columns: repeat(6, 1fr);
    margin-left: -3em;
    margin-right: -3em;
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 2em;
    padding-bottom: 2em;
    margin-bottom:2em;
    > div{
      height: 60px;
    }
`
const Field = connect((state, ownProps) => ({}))(props => {
  const [savingStoryPoints, setSavingStoryPoints] = useState(false);
  const { attribute, oldAttributes, item } = props;
  const [updateItem] = useMutationUpdateItem();
  return (
    <>
      <StyledLabels>
        {attribute.type === "select" || attribute.type === "memberSelect" ? (
          <FormGroup label={oldAttributes[attribute.id] ? <b>{attribute.name}</b>:attribute.name} labelInfo={props.saving ? <SavingDiv><Spinner size={Spinner.SIZE_SMALL} /></SavingDiv> : null}>
            {props.editing ? (
              <Select
                menuIsOpen={true}
                autoFocus={true}
                onMenuClose={() => {
                  props.onFinishEditing();
                }}
                onBlur={() => {
                  props.onFinishEditing();
                }}
                placeholder={"Not Assigned"}
                styles={selectStyles}
                className={"SESelect"}
                classNamePrefix={"SESelect SESelectSmall"}
                menuPortalTarget={document.querySelector("body")}
                value={
                  oldAttributes[attribute.id] &&
                  attribute.values.find(
                    v => v.id === oldAttributes[attribute.id]
                  )
                    ? {
                        label: attribute.values.find(
                          v => v.id === oldAttributes[attribute.id]
                        ).value,
                        value: oldAttributes[attribute.id]
                      }
                    : undefined
                }
                onChange={(v: any) => {
                  console.log(v);
                  const oldV = { ...oldAttributes };
                  if (v) {
                    oldV[attribute.id] = v.value;
                  } else {
                    delete oldV[attribute.id];
                  }
                  props.onSetSaving(true)
                  updateItem({
                    variables: {
                      input: {
                        projectId: props.projectId,
                        itemId: item.id,
                        attributes: JSON.stringify(oldV)
                      }
                    }
                  }).then(()=>{
                    props.onSetSaving(false)
                  }).catch(()=>{
                    props.onSetSaving(false)
                  });
                  
                }}
                isClearable={true}
                options={attribute.values.map(value => ({
                  label: value.value,
                  value: value.id
                }))}
                key={attribute.id}
              />
            ) : (
              <div
                style={{
                  textDecoration: "underline",
                  cursor: props.editable?"pointer":undefined
                }}
                onClick={() => {
                  if(props.editable)
                    props.onEditing();
                }}
              >
                {oldAttributes[attribute.id] &&
                attribute.values.find(v => v.id === oldAttributes[attribute.id])
                  ? attribute.values.find(
                      v => v.id === oldAttributes[attribute.id]
                    ).value
                  : "Choose " + attribute.name}
              </div>
            )}
          </FormGroup>
        ) : null}
        {attribute.type === "storyPoints" ? (
          <FormGroup label={oldAttributes[attribute.id] ? <b>{attribute.name}</b>:attribute.name} labelInfo={props.saving ? <SavingDiv><Spinner size={Spinner.SIZE_SMALL} /></SavingDiv> : null}>
            {props.editing ? (
              <NumericInput
                fill={true}
                leftIcon={"stopwatch"}
                autoFocus={true}
                buttonPosition={"none"}
                onKeyDown={(e)=>{
                    if (e.keyCode === 13) {
                      (e.target as any).blur()
                    }
                }}
                onBlur={e => {
                  props.onFinishEditing();
                  setSavingStoryPoints(true);
                  const stringVal = e.target.value;

                  const oldV: any = { ...oldAttributes };
                  if (stringVal !== "") {
                    oldV["storyPoints"] = parseFloat(stringVal);
                  } else {
                    delete oldV["storyPoints"];
                  }
                  props.onSetSaving(true)
                  updateItem({
                    variables: {
                      input: {
                        projectId: props.projectId,
                        itemId: item.id,
                        attributes: JSON.stringify(oldV)
                      }
                    }
                  })
                    .then(response => {
                      props.onSetSaving(false)
                    })
                    .catch(e => {
                      props.onSetSaving(false)
                    });

                    
                }}
                value={oldAttributes["storyPoints"]}
                
              ></NumericInput>
            ) : (
              <div
                style={{
                  textDecoration: "underline",
                  cursor: props.editable?"pointer":undefined
                }}
                onClick={() => {
                  if(props.editable)
                  props.onEditing();
                }}
              >
                {oldAttributes["storyPoints"] ? (
                  <>{oldAttributes["storyPoints"]}</>
                ) : (
                  "Set " + attribute.name
                )}
               
              </div>
            )}
          </FormGroup>
        ) : null}
        {attribute.type === "dateTime" ? (
          <FormGroup label={oldAttributes[attribute.id] ? <b>{attribute.name}</b>:attribute.name} labelInfo={props.saving ? <SavingDiv><Spinner size={Spinner.SIZE_SMALL} /></SavingDiv> : null}>
            {props.editing ? (
              <DatePicker
              autoFocus={true}
              className={'bp3-input'}
              // isClearable={true}
              onCalendarClose={()=>props.onFinishEditing()}
              selected={oldAttributes[attribute.id]
                      ? moment(oldAttributes[attribute.id]).toDate()
                      : undefined}
              onChange={date => {
                // moment(date)
                const oldV = { ...oldAttributes };
                if(date){
                  const v = moment(date)
                  if (
                      attribute.id === "endDate" &&
                      v.isSame(v.clone().startOf("day"))
                    ) {
                      oldV[attribute.id] = v
                        .clone()
                        .endOf("day")
                        .toISOString();
                    }else{
                      oldV[attribute.id] = v.toISOString();
                    }
                  
                }else{
                  delete oldV[attribute.id];
                }
                console.log({onChange: date})
                props.onSetSaving(true)
                  updateItem({
                    variables: {
                      input: {
                        projectId: props.projectId,
                        itemId: item.id,
                        attributes: JSON.stringify(oldV)
                      }
                    }
                  }) .then(response => {
                    props.onSetSaving(false)
                  })
                  .catch(e => {
                    props.onSetSaving(false)
                  });
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={5}
              timeCaption="time"
              dateFormat="M/d/yyyy, h:mm aa"
            />
              // <DateTime
              //   viewMode={"days"}
                
                
              //   {...({
              //     renderInput: (props2, openCalendar, closeCalendar) => {
              //       let textValue = dateTextVal(oldAttributes, attribute);

              //       return (
              //         <InputGroup
              //           {...props2}
              //           autoFocus={true}
              //           leftIcon={
              //             attribute.id === "dueDate"
              //               ? "notifications"
              //               : "calendar"
              //           }
              //           value={textValue}
              //         ></InputGroup>
              //       );
              //     }
              //   } as any)}
              //   inputProps={{ placeholder: "Select date" }}
              //   value={
              //     oldAttributes[attribute.id]
              //       ? moment(oldAttributes[attribute.id])
              //       : undefined
              //   }
              //   onBlur={(v: any) => {
              //     props.onFinishEditing()
              //     const oldV = { ...oldAttributes };
              //     if (!v.toISOString) {
              //       // Cancella
              //       if (v === "") {
              //         delete oldV[attribute.id];
              //       } else {
              //         return;
              //       }

              //       console.log(v);
              //     } else {
              //       console.log(v.toISOString());
              //       if (
              //         attribute.id === "endDate" &&
              //         v.isSame(v.clone().startOf("day"))
              //       ) {
              //         oldV[attribute.id] = v
              //           .clone()
              //           .endOf("day")
              //           .toISOString();
              //       } else if (
              //         attribute.id === "dueDate" &&
              //         v.isSame(v.clone().startOf("day"))
              //       ) {
              //         oldV[attribute.id] = v
              //           .clone()
              //           .set({
              //             hours: moment().get("hours"),
              //             minutes: moment().get("minutes")
              //           })
              //           .toISOString();
              //       } else {
              //         oldV[attribute.id] = v.toISOString();
              //       }
              //     }
              //     updateItem({
              //       variables: {
              //         input: {
              //           projectId: props.projectId,
              //           itemId: item.id,
              //           attributes: JSON.stringify(oldV)
              //         }
              //       }
              //     });
              //   }}
              // ></DateTime>
            ) : (
              <div
                style={{
                  textDecoration: "underline",
                  cursor: props.editable?"pointer":undefined
                }}
                onClick={() => {
                  if(props.editable)
                    props.onEditing();
                }}
              >
                {oldAttributes[attribute.id] ? (
                  <>{dateTextVal(oldAttributes, attribute)}</>
                ) : (
                  "Set " + attribute.name
                )}
              </div>
            )}
          </FormGroup>
        ) : null}
        {/* {attribute.type === 'progress' ? <FormGroup label={attribute.name}><NumericInput fill={true} leftIcon={'percentage'} min={0} max={100} stepSize={10}></NumericInput></FormGroup>: null} */}
      </StyledLabels>
    </>
  );
});
export default connect((state, ownProps) => {
  return {
    item: itemById(state, ownProps.itemId)
  };
})(props => {
  const { item } = props;
  const oldAttributes = item ? JSON.parse(item.attributes) || {} : {};
  const [editing, setEditing]: any = useState({});
  const [saving, setSaving]: any = useState({});
  const [updateItem] = useMutationUpdateItem();
  const debounceDescription = useDebounceCallback(
    val => {
      updateItem({
        variables: {
          input: {
            projectId: props.projectId,
            itemId: item.id,
            description: val
          }
        }
      });
    },
    3000,
    false
  );

  let editorValue;
  if (item) {
    try {
      editorValue = JSON.parse(item.description);
    } catch (e) {}
  }

  return (
    <div>
      {item ? (
        <>
          <div style={{ paddingTop: "1em", paddingBottom: "2em" }}>
           

            <div style={{ fontSize: "1.5em", color: "#333333" }}>
              <EditableText
                confirmOnEnterKey={true}
                isEditing={!item.title}
                multiline={true}
                placeholder={"Item Title"}
                defaultValue={item.title || ""}
                onConfirm={val => {
                  updateItem({
                    variables: {
                      input: {
                        projectId: props.projectId,
                        itemId: item.id,
                        title: val
                      }
                    }
                  });
                }}
              ></EditableText>
            </div>
          </div>
          <div style={{fontSize: '1em', display:'flex', justifyContent:'space-between', paddingTop: '0em', paddingBottom: '0.5em'}}>
          <div>#{item.number}</div>
          <div>Created {moment(item.createdAt).calendar()}, last update {moment(item.updatedAt).calendar()}</div>
          </div>
          
          <StyledFieldsGrid>
            {props.project && props.project.itemAttributes
              ? props.project.itemAttributes.map(attribute => (
                  <Field
                    editable={!Object.keys(saving).map((k) => saving[k]).reduce((a,b)=> a || b, false)}
                    saving={saving[attribute.id] ? saving[attribute.id] : false}
                    onSetSaving={(b)=>{
                      setSaving({ ...editing, [attribute.id]: b });
                    }}
                    editing={
                      editing[attribute.id] ? editing[attribute.id] : false
                    }
                    onEditing={() => {
                      setEditing({ ...editing, [attribute.id]: true });
                    }}
                    onFinishEditing={() => {
                      setEditing({ ...editing, [attribute.id]: false });
                    }}
                    key={attribute.id}
                    attribute={attribute}
                    oldAttributes={oldAttributes}
                    item={item}
                    projectId={props.projectId}
                  ></Field>
                ))
              : null}
            
          </StyledFieldsGrid>
        
          
         
          <div>
            <div style={{ flex: "1", fontSize: "1rem" }}>
              <div
                style={{
                  fontSize: "0.8em",
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "1em",
                  textTransform: "uppercase"
                }}
              >
                <Icon icon={"manually-entered-data"} iconSize={18}></Icon>{" "}
                <div style={{ paddingLeft: "0.6em" }}>Description</div>
              </div>
              <div id={"quill-item-editor"}>
                <ReactQuill
                  placeholder={"Type your description here"}
                  bounds={"#quill-item-editor"}
                  theme={"bubble"}
                  style={{
                    minHeight: "300px",
                    backgroundColor: "#fff",
                    color: "#333333",
                    letterSpacing: "-0.5px",
                    lineHeight: "1.5em"
                  }}
                  defaultValue={editorValue}
                  onBlur={(previousRange, source, editor) =>
                    updateItem({
                      variables: {
                        input: {
                          projectId: props.projectId,
                          itemId: item.id,
                          description: JSON.stringify(editor.getContents())
                        }
                      }
                    })
                  }
                  onChange={(content, delta, source, editor) =>
                    debounceDescription(JSON.stringify(editor.getContents()))
                  }
                />
              </div>

              <div style={{ paddingTop: "2em" }}></div>
            </div>
            {/* <div style={{flex: '0 0 10px'}}></div> */}
            {/* <div style={{flex: '0 0 220px', padding: '1em', paddingRight: 0, paddingTop: 0, marginTop: '-25px'}}>
  {props.projectView && props.projectView.itemAttributes ? props.projectView.itemAttributes.filter((a) => a.editable === false).map((attribute) => (<Field attribute={attribute} oldAttributes={oldAttributes} item={item}></Field>)) : null}
   
  </div>
  <div style={{flex: '0 0 220px', padding: '1em', paddingRight: 0, paddingTop: 0, marginTop: '-25px'}}>
  {props.projectView && props.projectView.itemAttributes ? props.projectView.itemAttributes.filter((a) => a.editable === true).map((attribute) => (<Field attribute={attribute} oldAttributes={oldAttributes} item={item}></Field>)) : null}
    
  </div> */}
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
});
