import {
  Button,
  Card,
  ControlGroup,
  FormGroup,
  H2,
  HTMLSelect,
  InputGroup,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { useMutationCreateInvite, useMutationDeleteInvite, useMutationDeleteMember } from "../../state/mutations";

import UserAvatar from "../UserAvatar";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";

export default connect((state, ownProps) => {
  return {
    
    loggedUser: loggedUser(state)
  };
})(props => {

  const [email, setEmail] = useState("")
  const [sendingInvite, setSendingInvite] = useState(false)
  const [createInvite] = useMutationCreateInvite()
  const [deleteInvite] = useMutationDeleteInvite()
  const [deleteMember] = useMutationDeleteMember()
  return (
    <div>
      <Card elevation={2}>
      
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
      <FormGroup labelFor="text-input">
        <ControlGroup>
        <HTMLSelect options={[{label:'Admin', value: 'admin'}/*, {label: 'Super User', value: 'superUser'}, {label:'Manager', value:'manager'}*/]}></HTMLSelect>
          <InputGroup id="text-input" style={{width: '200px'}} placeholder="Email" value={email} onChange={(v) => setEmail(v.target.value)} />
          <Button icon="arrow-right" text="Send Invite" intent={"success"} loading={sendingInvite} onClick={async ()=>{
            setSendingInvite(true)
            try{
              await createInvite({variables: {input: {
                projectId: props.projectId,
                recipientEmail: email,
                role: 'admin' }}})
                setSendingInvite(false)
                setEmail("")
            }catch(e){
              setSendingInvite(false)
            }
          }} />
        </ControlGroup>
      </FormGroup>
      </div>
      {props.projectInvites && props.projectInvites.length > 0 ?  <div>
        <H2>Invites</H2>
        <table className="bp3-html-table bp3-html-table-striped" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          
          {props.projectInvites.map(invite => (
            <tr key={invite.id}>
              <td>
                
                  {invite.recipientEmail}
                
              </td>
              <td>{invite.role}</td>

              <td>
                <Button
                onClick={() => {
                  deleteInvite({variables: {input: {projectId: props.projectId, inviteId: invite.id}}})
                  
                }}
                  intent={"danger"}
                  icon={"trash"}
                  minimal={true}
                ></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table></div> : null }
      </Card>
      <Card elevation={2} style={{marginTop: '2em'}}>
      <H2>Members</H2>
      <table className="bp3-html-table bp3-html-table-striped" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{width: '40px'}}></th>
            <th>Name</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.memberRoles.map(memberRole => (
            <tr key={memberRole.id}>
              <td>
              <UserAvatar size={32} displayName={memberRole.member.displayName} shortDisplayName={memberRole.member.shortDisplayName} ></UserAvatar>
              </td>
              <td>
                
                 <div><b>{memberRole.member.displayName}</b></div>
                <div style={{fontSize: "0.8em"}}>@{memberRole.member.username}</div>
              </td>
              <td>{memberRole.role}</td>

              <td>
                {memberRole.member.id !== props.loggedUser.id ? <Button
                  intent={"danger"}
                  icon={"trash"}
                  minimal={true}
                  onClick={()=>{
                    deleteMember({variables: {input: {projectId: props.projectId, memberRoleId: memberRole.id }}})
                    
                  }}
                ></Button> : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </Card>
    </div>
  );
});
