import { H1 } from '@blueprintjs/core'
import React from 'react'
export default() => {
    return (<div style={{textAlign: 'center', padding: '1em', paddingTop: 100, fontSize: 18 }}>
        <H1>Hey! 👋</H1>
        <p>Sprintespresso is currently available only for desktop and tablet.</p>
        <p>
        <a href="https://sprintespresso.com/">Back to the website</a>
        </p>
    </div>)
}