const LOGIN_REQUEST = "app/auth/LOGIN_REQUEST";
const LOGIN_SUCCESS = "app/auth/LOGIN_SUCCESS";
const LOGIN_FAILURE = "app/auth/LOGIN_FAILURE";

const SIGNUP_REQUEST = "app/auth/SIGNUP_REQUEST";
const SIGNUP_SUCCESS = "app/auth/SIGNUP_SUCCESS";
const SIGNUP_FAILURE = "app/auth/SIGNUP_FAILURE";

const LOGOUT_REQUEST = "app/auth/LOGOUT_REQUEST";
const LOGOUT_SUCCESS = "app/auth/LOGOUT_SUCCESS";
const LOGOUT_FAILURE = "app/auth/LOGOUT_FAILURE";

const VERIFY_REQUEST = "app/auth/VERIFY_REQUEST";
const VERIFY_SUCCESS = "app/auth/VERIFY_SUCCESS";

const RESET_REQUEST = "app/auth/RESET_REQUEST";
const RESET_SUCCESS = "app/auth/RESET_SUCCESS";
const RESET_FAILURE = "app/auth/RESET_FAILURE";
export {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    VERIFY_REQUEST,
    VERIFY_SUCCESS,
    RESET_REQUEST,
    RESET_SUCCESS,
    RESET_FAILURE
};