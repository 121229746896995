import LogoH from '../../../assets/logo-black@2x.png'
import LogoHB from '../../../assets/logo-white@2x.png'
import LogoV from '../../../assets/logo-rec.png'
import React from 'react'
import { connect } from "react-redux";
export default connect((state) => ({
  theme: state.interface.theme,
}))(({format, width, theme, color='black'}) => {
        return (<div style={{textAlign: 'center'}}>{format === 'v' ? <img
            style={{width: width}}
            src={color==='black'?LogoH: LogoHB}
    /> :  <img
            style={{width: width}}
            src={color==='black'?LogoH: LogoHB}
    />}</div>)
    })