import React from 'react'

export default props => {
  return (
   
    <div className={"scroller"} style={{ width: '100%', height: '100%', position: "relative", overflow: "auto"  }}>
      {props.boxed ? (
        <div style={{paddingTop: 50, margin: "auto", width: 960, paddingBottom: 50, position:'relative', zIndex: 1 }}>
          {props.children}
        </div>
      ) : (
        <>
          {props.children}
        </>
      )}
    </div>
  );
};
