import {
  Button,
  Card,
  EditableText,
  H2,
  H3,
  InputGroup,
  Popover,
  Tab,
  Tabs
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { createAttribute, createAttributeValue, deleteAttribute, deleteAttributeValue, fetchAttributes, updateAttribute, updateAttributeValue } from "../../state/ducks/project/operations";
import { useMutationCreateProjectAttribute, useMutationCreateProjectAttributeValue, useMutationDeleteProjectAttribute, useMutationDeleteProjectAttributeValue, useMutationUpdateProjectAttribute, useMutationUpdateProjectAttributeValue } from "../../state/mutations";

import { BlockPicker } from "react-color";
import ColorPicker from "../ColorPicker";
import { connect } from "react-redux";

export default connect((state, ownProps) => {
  return {
    
  };
})(props => {
  const [loaders, setLoaders] = useState<any>({})
  const [openCreate, setOpenCreate] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [name, setName] = useState("")
  const [createProjectAttribute,{data}] = useMutationCreateProjectAttribute()
  const [updateProjectAttribute] = useMutationUpdateProjectAttribute()
  const [deleteProjectAttribute] = useMutationDeleteProjectAttribute()
  const [createProjectAttributeValue] = useMutationCreateProjectAttributeValue()
  const [updateProjectAttributeValue] = useMutationUpdateProjectAttributeValue()
  const [deleteProjectAttributeValue] = useMutationDeleteProjectAttributeValue()

  const createAttrCb = () => {
    setLoaders({...loaders, 'createAttribute': true})
    setIsCreating(true)
    createProjectAttribute({variables: {
      input: {
        projectId:  props.projectId,
        name: name
      }
    }}).then(() => {
      setLoaders({...loaders, 'createAttribute': false})
      setName("")
      setIsCreating(false)
      setOpenCreate(false)
    }).catch(() => {
      setLoaders({...loaders, 'createAttribute': false})
      setName("")
      setIsCreating(false)
      setOpenCreate(false)
    })
  }
  return (
    <div>
      {/* <Tabs id="TabsExample" vertical={false}>
        <Tab
          id="attributes"
          title="Attributes"
          panel={ */}
          <div style={{display: 'flex', justifyContent:'flex-end'}}>

          <Popover onInteraction={(v) => setOpenCreate(v)} isOpen={openCreate} position={'bottom'} content={(<div style={{padding: '1em', display: 'flex', flexDirection: 'column'}}>
                    <InputGroup autoFocus={true} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} onKeyDown={(e)=>{
                        if (e.keyCode === 13) {
                          createAttrCb();
                          }
                    }}></InputGroup>
                    <div style={{flex:'0 0 1em'}}></div>
                    <Button fill={true} intent={'primary'} loading={isCreating} onClick={createAttrCb}>
                        Create
                      </Button></div>
                )}>
                <Button icon={"add"} intent={'success'} >
                        Add Field
                </Button>
                </Popover>

         </div>
            <div style={{paddingTop: '2em'}}>
              {props.attributes && props.attributes.sort((a, b) => {
                return (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime();
              }).filter((a) => a.editable).map(attribute => (
                <div style={{ marginBottom: "1em", background: "rgb(242, 247, 250)", borderRadius: 5, padding: '2em'}} key={attribute.id}>
                  <div style={{ marginBottom: "1em", display: 'flex', justifyContent: 'space-between' }}>
                    {/* <Button
                      minimal={true}
                      icon={"drag-handle-horizontal"}
                      style={{ cursor: "grab" }}
                    ></Button> */}
                    
                    <H3><EditableText
                      disabled={!attribute.editable}
                      multiline={false}
                      key={attribute.name}
                      defaultValue={attribute.name}
                      onConfirm={(v)=>{
                        updateProjectAttribute({variables: {input: {
                          projectId: props.projectId,
                          attributeId: attribute.id,
                          name: v
                        }}})
                        
                      }}
                      placeholder="Untitled"
                      selectAllOnFocus={true}
                    /></H3>
                    {attribute.editable ? <Button icon={"trash"} intent={'danger'} minimal={true}
                    loading={loaders['deleteAttribute-'+attribute.id] ? true : false}
                    onClick={async ()=>{
                      setLoaders({...loaders, ['deleteAttribute-'+attribute.id]: true})
                      try{
                        await deleteProjectAttribute({variables: {input: {
                          projectId: props.projectId,
                          attributeId: attribute.id
                        }}})
                        setLoaders({...loaders, ['deleteAttribute-'+attribute.id]: false})
                      }catch(e){
                        setLoaders({...loaders, ['deleteAttribute-'+attribute.id]: false})
                      }
                     
                    }}> 
                    
                  </Button>: null}
                    {/* <Select></Select> */}
                  </div>
                  {attribute.values.map((value) => (
                      <div
                      key={value.id}
                      style={{
                        background: "#ffffff",
                        borderRadius: 3,
                        padding: "1em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: '1em'
                      }}
                    >
                      {/* <Button
                        minimal={true}
                        icon={"drag-handle-horizontal"}
                        style={{ cursor: "grab" }}
                      ></Button> */}
  
                      <EditableText 
                      disabled={!attribute.editable}
                      multiline={false}
                      key={value.value}
                      onConfirm={async (v)=>{
                        try{
                          await updateProjectAttributeValue({variables: {input: {
                            projectId: props.projectId,
                            attributeId: attribute.id,
                            valueId: value.id,
                            value: v
                          }}})  
                        }catch(e){

                        }
                       
                      }}
                      selectAllOnFocus={true}
                      defaultValue={value.value}
                      placeholder="Untitled" />
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Popover
                          content={
                            <ColorPicker
                              color={value.color?value.color:'#b3bac5'}
                              onChange={async (v)=>{
                                try{
                                  await updateProjectAttributeValue({variables: {input: {
                                    projectId: props.projectId,
                                    attributeId: attribute.id,
                                    valueId: value.id,
                                    color: v
                                  }}})  
                                }catch(e){

                                }
                                
                                
                              }}
                              colors={[
                                "#61bd4f",
                                "#f2d600",
                                "#ff9f1a",
                                "#eb5a46",
                                "#c377e0",
                                "#0079bf",
                                "#00c2e0",
                                "#51e898",
                                "#ff78cb",
                                "#344563",
                                "#b3bac5"
                              ]}
                            ></ColorPicker>
                          }
                        >
                          <div
                            style={{
                              width: 20,
                              height: 20,
                              marginTop: 5,
                              background: value.color?value.color:'#b3bac5',
                              borderRadius: 3,
                              cursor: "pointer"
                            }}
                          ></div>
                        </Popover>
                       {attribute.editable ? <Button
                          icon={"trash"}
                          intent={"danger"}
                          minimal={true}
                          loading={loaders['deleteAttributeValue-'+value.id]? true:false}
                          onClick={async ()=>{
                            setLoaders({...loaders, ['deleteAttributeValue-'+value.id]: true})
                            try{
                              await deleteProjectAttributeValue({variables: {
                                input: {
                                  projectId: props.projectId,
                                  attributeId: attribute.id,
                                  valueId: value.id
                                }
                              }})
                              setLoaders({...loaders, ['deleteAttributeValue-'+value.id]: false})
                            }catch(e){
                              setLoaders({...loaders, ['deleteAttributeValue-'+value.id]: false})
                            }
                            
                            
                          }}
                        ></Button> : null}
                      </div>
                      
                    </div>
                  ))}
                  
                
                  {attribute.editable ? <Button icon={"add"} minimal={true} 
                  loading={loaders['createAttributeValue-'+attribute.id]? true : false}
                  onClick={async ()=>{
                    setLoaders({...loaders, ['createAttributeValue-'+attribute.id]: true})
                    try{
                      await createProjectAttributeValue({variables: {input: {
                        projectId: props.projectId,
                        attributeId: attribute.id
                      }}})
                      setLoaders({...loaders, ['createAttributeValue-'+attribute.id]: false})
                    }catch(e){
                      setLoaders({...loaders, ['createAttributeValue-'+attribute.id]: false})
                    }
                   
                  }}>
                    Add Value
                  </Button> : null }
                  
                </div>
              ))}

              
            </div>
          {/* }
        />
       
      </Tabs> */}
    </div>
  );
});
