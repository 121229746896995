import { Icon, Popover } from '@blueprintjs/core'

import ColorPicker from '../ColorPicker'
import React from 'react'

export default ({value, onChange}) => {
    return (<Popover
        fill={true}
        content={
          <ColorPicker
            color={value?value:'#b3bac5'}
            onChange={onChange}
            colors={[
              "#f44336",
              "#e91e63",
              "#9c27b0",
              "#673ab7",
              "#3f51b5",
              "#2196f3",
              "#03a9f4",
              "#00bcd4",
              "#009688",
              "#4caf50",
              "#8bc34a",
              "#cddc39",
              "#ffeb3b",
              "#ffc107",
              "#ff9800",
              "#ff5722",
              "#795548",
              "#9e9e9e",
              "#607d8b"


              // "#61bd4f",
              // "#f2d600",
              // "#ff9f1a",
              // "#eb5a46",
              // "#c377e0",
              // "#0079bf",
              // "#00c2e0",
              // "#51e898",
              // "#ff78cb",
              // "#344563",
              // "#b3bac5"
            ]}
          ></ColorPicker>
        }
      >
        <div
          style={{
            width: '100%',
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: value?value:'#b3bac5',
            borderRadius: 3,
            cursor: "pointer"
          }}
        >
          <Icon style={{color: 'rgba(255,255,255,0.5)'}} icon={'edit'}></Icon>
        </div>
      </Popover>)
}