import { Button, Card, EditableText, H1, H2, Icon, InputGroup, NavbarDivider, Popover, ProgressBar, Tag } from "@blueprintjs/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import RGL, { WidthProvider } from "react-grid-layout";
import React, { useCallback, useState } from "react";
import { createView, updateProjectView } from "../../state/ducks/project/operations";
import { useMutationCreateView, useMutationDeleteView, useMutationUpdateView } from "../../state/mutations";

import Body from "../../components/Body";
import { Link } from "react-router-dom";
import { PROJECT_VIEWS } from "../../state/graphql";
import ProjectMenu from "../../components/ProjectMenu";
import ProjectNavBar from "../../components/ProjectNavBar";
import { Query } from "@apollo/react-components";
import { SIcon } from "../../utils/Icons";
import Table from "../../components/Table";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";
import moment from "moment";
import { useParams } from "react-router-dom";
import { usePlaceholderData } from "../../utils";
import { viewAttrHelper } from "./ProjectView";

const ProjectViews = connect((state, ownProps) => {
  return {
    loggedUser: loggedUser(state)
  };
})(props => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [selectedItems, setSelectedItems] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [addView, { data }]= useMutationCreateView()
  const [deleteView] = useMutationDeleteView()
  const [updateView] = useMutationUpdateView()
  const [name, setName] = useState("")
  const hasSelectedItems = Object.keys(selectedItems).length > 0
  const history = props.history
  let { projectId } = useParams();
  const createViewCb = useCallback(async () => {
    
    if (isCreating) {
        return;
      }
      setIsCreating(true);
      try{
        const response = await addView({
            variables: {
                input: {
                    projectId: projectId,
                    name: name
                }
            }
        })
        setIsCreating(false);
        setName("")
        setOpenCreate(false)
        history.push(`/projects/${projectId}/v/${response.data.createProjectView.id}`)
      }catch(e){
        console.error(e)
        setIsCreating(false);
          setName("")
          setOpenCreate(false)
      }
      
    
  }, [name])
  return (
    <Query
      fetchPolicy={"cache-and-network"}
      query={PROJECT_VIEWS}
      variables={{ id: projectId }}
    >
      {({ loading, error, data: realData }) => {
        
        const {data, skeltonClass} = usePlaceholderData({
          data: {
            me: {
              project: {
                name: 'Lorem ipsum dolor',
                id: '123',
                projectViews: [
  
                ],
                itemAttributes: [
  
                ]
              }
            }
          },
          realData,
          loading
        })
        
        const project = data.me?.project;
        const selectedViews = project && hasSelectedItems ? Object.keys(selectedItems).map((k, i) => project.projectViews[k]) : []
     
        return (
          <div>
            <div
              style={{
                display: "flex",
                width: "100vw",
                height: "100vh",
                overflow: "hidden"
              }}
            >
              {showSidebar ? (
                <ProjectMenu
                  project={project}
                  activeMenu={"views"}
                ></ProjectMenu>
              ) : null}
              <div style={{ flex: "1 1", backgroundColor: "#FBFDFF" }}>
                <ProjectNavBar
                  notFixed={true}
                  pre={
                    <>
                      <Button
                        minimal={true}
                        icon={showSidebar ? "chevron-left" : "menu"}
                        text={showSidebar ? "Menu" : "Menu"}
                        onClick={() => setShowSidebar(!showSidebar)}
                      />
                      <NavbarDivider></NavbarDivider>
                      <div
                        style={{
                          color: "white",
                          display: "flex",
                          fontSize: "0.8em"
                        }}
                      >
                        <Link to={"/"} style={{ color: "white" }}>
                          All Workpsaces
                        </Link>
                        <div style={{ padding: "0em 0.4em" }}>/</div>
                        <Link
                          className={skeltonClass}
                          to={`/projects/${project ? project.id : ""}`}
                          style={{ color: "white" }}
                        >
                          {project ? project.name : ""}
                        </Link>
                        <div style={{ padding: "0em 0.4em" }}>/</div>
                        <Link
                          className={skeltonClass}
                          to={`/projects/${project ? project.id : ""}/views`}
                          style={{ color: "white" }}
                        >
                          Kanban Boards
                        </Link>
                      </div>
                    </>
                  }
                  post={<></>}
                ></ProjectNavBar>
                <Body boxed>
                  
                    <H1><span className={skeltonClass}><SIcon style={{marginRight: '0.4em'}} size={38} icon={'kanban'}></SIcon> Kanban Boards</span></H1>
                      {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <Popover  position={'bottom'} content={<div style={{padding: '1em'}}>Multiple boards coming soon!</div>}>
                        <Button  intent={'success'} icon={'plus'}>Add Board</Button>
                        </Popover>
                        </div> */}
                    <Card style={{paddingTop: '2em'}}>
                      <H2>Workspace Board</H2>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end", marginBottom: '2em' }}
                    >
                    {hasSelectedItems ? <Button icon={"trash"} intent={"danger"}
                    loading={deleting}
                    onClick={async ()=>{
                        setDeleting(true)
                        for(let view of selectedViews){
                            try{
                                await deleteView({
                                  variables: {
                                    input: {
                                      projectId: project.id,
                                      projectViewId: view.id
                                    }
                                  }
                                })
                                
                                
                            }catch(e){
                                setSelectedItems({})
                                setDeleting(false)
                            }
                        }
                        setSelectedItems({})
                        setDeleting(false)
                    }}
                    >Delete
                      </Button> : 
                      <>
                      <Button style={{marginRight: '1em'}} icon={'people'} disabled={true} minimal={true}>All Workspace</Button>
<Link to={`/projects/${project?.id}/custom-fields`}>
  <Button style={{marginRight: '1em'}} icon={'tag'}>Custom Fields</Button>
              
              </Link>
<Popover onInteraction={(v) => setOpenCreate(v)} isOpen={openCreate} position={'bottom'} content={(<div style={{padding: '1em', display: 'flex', flexDirection: 'column'}}>
                    <InputGroup autoFocus={true} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} onKeyDown={(e)=>{
                        if (e.keyCode === 13) {
                            createViewCb();
                          }
                    }}></InputGroup>
                    <div style={{flex:'0 0 1em'}}></div>
                    <Button fill={true} intent={'primary'} loading={isCreating} onClick={createViewCb}>
                        Create
                      </Button></div>
                )}>
                <Button className={skeltonClass} icon={"add"} intent={'success'} >
                        Add View
                </Button>
                </Popover>
</>

                      
                       }
                    </div>
                    <Table
                    className={skeltonClass}
                    selectedRows={selectedItems}
                    onSelectedRowsChange={(r) => setSelectedItems(r)}
                      //   onRowsSelected={(r) => setSelectedItems(r)}
                      columns={[
                        
                        {
                          Header: "Name",
                          accessor: "name",
                          Cell: ({cell: { value, row: {original} } }) => {
                            
                            return <div>
                              <div><EditableText
                            defaultValue={value}
                            onConfirm={val => {
                              updateView({variables: {input: {projectId: project.id, projectViewId: original.id, name: val}}})}}
                          ></EditableText></div>
                          
                          </div>;
                          }
                        },
                        {
                          Header: "Filters & Columns",
                          accessor: "other2",
                          minWidth: 290,
                          Cell: ({cell: { value, row: {original} } }) => {
                            const {groupedByAttribute, actualFilters, actualCardLayout, filtersTextual} = viewAttrHelper(original, project?.itemAttributes)

                            return <div>
                            
                          <div>
                            {filtersTextual.length > 0 &&  <>{filtersTextual.map((name) => (
                             
                                <Tag key={name} style={{marginBottom: '0.5em', marginRight: '0.5em'}} className={'tagWithIcon'} minimal={true} round={true} icon={<SIcon icon={'filter'} />}>{name}</Tag> 
                             
                            )) }</>
                            }
                            {groupedByAttribute && 
                           
                              <Tag style={{marginBottom: '0.5em', marginRight: '0.5em'}} className={'tagWithIcon'} minimal={true} round={true} icon={<SIcon icon={'kanban'} />}>{groupedByAttribute.name}</Tag> 
                            
                            }
                          </div>
                          </div>;
                          }
                        },
                        {
                          Header: "",
                          accessor: "other",
                          maxWidth: 100,
                          Cell: ({cell: { value, row: {original} } }) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  flex: "1"
                                }}
                              >
                                  
                                  <Link
                          to={`/projects/${project ? project.id : ""}/v/${original.id}`}
                          style={{ color: "white" }}
                        >
                                <Button
                                  rightIcon={"chevron-right"}
                                  
                                  intent={'primary'}
                                >
                                  Open View
                                </Button>
                                </Link>
                              </div>
                            );
                          }
                        }
                      ]}
                      data={[...(data.me?.project.projectViews || [])]}
                    ></Table>
                 </Card>
                </Body>
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
});

export default ProjectViews;
