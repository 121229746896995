import { Link, Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { ApolloProvider } from '@apollo/react-hooks';
import {DragDropContext} from 'react-beautiful-dnd'
import ForgotPassword from "./views/Auth/ForgotPassword";
import Home from "./views/Home";
import Join from "./views/Join";
import Login from "./views/Auth/Login";
import Logout from "./views/Logout";
import MobileAlert from "./views/MobileAlert";
import NewProject from "./views/NewProject";
import Onboard from "./views/User/Onboard";
import Profile from "./views/User/Profile";
import ProjectFields from "./views/Project/ProjectFields";
import ProjectProject from "./views/Project/ProjectProject";
import ProjectProjectFee from "./views/Project/ProjectProjectFee";
import ProjectProjects from "./views/Project/ProjectProjects";
import ProjectTeam from "./views/Project/ProjectTeam";
import ProjectTime from "./views/Project/ProjectTime";
import ProjectView from "./views/Project/ProjectView";
import ProjectViews from "./views/Project/ProjectViews";
import ProjectWorkload from "./views/Project/ProjectWorkload";
import ProtectedRoute from "./components/ProtectedRoute";
import { Provider } from "react-redux";
import React from "react";
import Register from "./views/Auth/Register";
import Verify from "./views/VerifyEmail";
import {client} from "./state/utils/graphql"
import { connect } from "react-redux";
import { loggedUser } from "./state/ducks/user/selectors";
import { logoutUser } from "./state/ducks/auth/operations";
import store from "./state/store";

function Users() {
  return <h2>Users</h2>;
}
const App: React.FC = (props: any) => {
  const { isAuthenticated, isVerifying, theme, loggedUser } = props;
  const protectedProps = {isAuthenticated, isVerifying, loggedUser}
  return (
      <div className={theme === 'light'? 'theme':'theme bp3-dark'}>
        
        <Switch>
          
          <ProtectedRoute
            exact
            path="/"
            component={Home}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/onboard"
            component={Onboard}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/profile"
            component={Profile}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId"
            component={ProjectView}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId/views"
            component={ProjectViews}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId/v/:viewId"
            component={ProjectView}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId/projects/:projectProjectId"
            component={ProjectProject}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId/projects/:projectProjectId/fees/:projectProjectFeeId"
            component={ProjectProjectFee}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId/team"
            component={ProjectTeam}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId/custom-fields"
            component={ProjectFields}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId/time-tracking"
            component={ProjectTime}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId/time-tracking/u/:userId"
            component={ProjectTime}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId/workload"
            component={ProjectWorkload}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/projects/:projectId/projects"
            component={ProjectProjects}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/join/:projectId/:token"
            component={Join}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/new-project"
            component={NewProject}
            {...protectedProps}
          />
          <ProtectedRoute
            exact
            path="/verify"
            component={Verify}
            {...protectedProps}
          />
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/mobile-alert">
            <MobileAlert />
          </Route>
          <Route path="/signup">
            <Register />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          
        </Switch>
        </div>
  );
};
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    theme: state.interface.theme,
    loggedUser: loggedUser(state)
  };
}

const AppMap = connect(mapStateToProps)(App)

function Root() {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
      <DragDropContext
      onBeforeCapture={(event) => {
        store.dispatch({type: 'dnd/ON_BEFORE_CAPTURE', payload: {event}})
      }}
      onBeforeDragStart={(event) => {
        store.dispatch({type: 'dnd/ON_BEFORE_DRAG_START', payload: {event}})
      }}
      onDragStart={(event) => {
        console.log(event)
        store.dispatch({type: 'dnd/ON_DRAG_START', payload: {event}})
      }}
      onDragUpdate={(event) => {
        store.dispatch({type: 'dnd/ON_DRAG_UPDATE', payload: {event}})
      }}
      onDragEnd={(event) => {
        console.log(event)
        store.dispatch({type: 'dnd/ON_DRAG_END', payload: {event}})
      }}
      >
      <Router>
        <AppMap />
      </Router>
      </DragDropContext>
      </ApolloProvider>
    </Provider>
  );
}

export default Root;


