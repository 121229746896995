import { ITEM_FRAGMENT, PROJECT_VIEW_FRAGMENT, TIME_LOG_FRAGMENT } from "./graphql"

import gql from "graphql-tag"
import { trackEvent } from "./utils/trackEvent"
import { useMutation } from "@apollo/react-hooks"

const augmentHook = (hook, func) => {
    return [(...p) => {
        const ret = func(...p)
        if(ret){
            return hook[0](ret)    
        }
        return hook[0](...p)
    }, hook[1]]
}
export const useMutationCreateView = () => {

    const hook = useMutation(gql`
    mutation createProjectView($input: CreateProjectViewInput){
        createProjectView(input: $input){
            id
            name
        }
    }`, {
        refetchQueries: ['PROJECT_VIEWS']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_VIEW_CREATE_REQUEST')
    })
}
export const useMutationUpdateView = () => {

    const hook = useMutation(gql`
    mutation updateProjectView($input: UpdateProjectViewInput){
        updateProjectView(input: $input){
            ...ProjectViewFragment
        }
    }
    ${PROJECT_VIEW_FRAGMENT}
    `, {
        refetchQueries: []
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_VIEW_UPDATE_REQUEST')
    })
}
export const useMutationDeleteView = () => {

    const hook = useMutation(gql`
    mutation deleteProjectView($input: DeleteProjectViewInput){
        deleteProjectView(input: $input){
            id
        }
    }`, {
        refetchQueries: ['PROJECT_VIEWS']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_VIEW_DELETE_REQUEST')
    })
}

export const useMutationCreateProjectProject = () => {
   
    const hook = useMutation(gql`
    mutation createProjectProject($input: CreateProjectProjectInput){
        createProjectProject(input: $input){
            id
            name
        }
    }`, {
        refetchQueries: ['PROJECT_PROJECTS']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_PROJECT_CREATE_REQUEST')
    })
}
export const useMutationUpdateProjectProject = () => {
   
    const hook = useMutation(gql`
    mutation updateProjectProject($input: UpdateProjectProjectInput){
        updateProjectProject(input: $input){
            id
            name
            color
            finalPrice
            billable
        }
    }`, {
        refetchQueries: []
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_PROJECT_UPDATE_REQUEST')
    })
}
export const useMutationDeleteProjectProject = () => {
   
    const hook = useMutation(gql`
    mutation deleteProjectProject($input: DeleteProjectProjectInput){
        deleteProjectProject(input: $input){
            id
            name
        }
    }`, {
        refetchQueries: ['PROJECT_PROJECTS']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_PROJECT_DELETE_REQUEST')
    })
}

export const useMutationCreateInvite = () => {
    console.log('MUTATION')
      const hook = useMutation(gql`
   mutation createProjectInvite($input: CreateProjectInviteInput){
            createProjectInvite(input: $input){
                id
                recipientEmail
                role
            }
        }`, {
        refetchQueries: ['PROJECT_TEAM']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_INVITE_CREATE_REQUEST')
    })
}
export const useMutationDeleteInvite = () => {
    const hook = useMutation(gql`
    mutation deleteProjectInvite($input: DeleteProjectInviteInput){
        deleteProjectInvite(input: $input){
            id
            recipientEmail
            role
        }
    }`, {
         refetchQueries: ['PROJECT_TEAM']
     })
     return augmentHook(hook, () => {
         trackEvent('PROJECT_INVITE_DELETE_REQUEST')
     })
}
export const useMutationDeleteMember = () => {
    const hook = useMutation(gql`
    mutation deleteMember($input: DeleteMemberInput){
        deleteMember(input: $input){
            id
            
        }
    }`, {
         refetchQueries: ['PROJECT_TEAM']
     })
     return augmentHook(hook, () => {
         trackEvent('PROJECT_MEMBER_DELETE_REQUEST')
     })
}

export const useMutationCreateProjectAttribute = () => {
    const hook = useMutation(gql`
    mutation createProjectItemAttribute($input: CreateProjectItemAttributeInput){
            createProjectItemAttribute(input: $input){
                id
                name
            
            }
    }`, {
        refetchQueries: ['PROJECT_FIELDS']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_ATTRIBUTE_CREATE_REQUEST')
    })
}
//  { projectId: action.payload.projectId, attributeId: action.payload.attributeId, ...action.payload.data }
export const useMutationUpdateProjectAttribute = () => {
    const hook = useMutation(gql`
    mutation updateProjectItemAttribute($input: UpdateProjectItemAttributeInput){
            updateProjectItemAttribute(input: $input){
                id
                name
            
            }
        }`, {
        refetchQueries: []
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_ATTRIBUTE_UPDATE_REQUEST')
    })
}

export const useMutationDeleteProjectAttribute = () => {
    const hook = useMutation(gql`
    mutation deleteProjectItemAttribute($input: DeleteProjectItemAttributeInput){
            deleteProjectItemAttribute(input: $input){
                id
                name
            }
        }`, {
        refetchQueries: ['PROJECT_FIELDS']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_ATTRIBUTE_DELETE_REQUEST')
    })
}
// { projectId: action.payload.projectId, attributeId: action.payload.attributeId }
export const useMutationCreateProjectAttributeValue = () => {
    const hook = useMutation(gql`
    mutation createProjectItemAttributeValue($input: CreateProjectItemAttributeValueInput){
        createProjectItemAttributeValue(input: $input){
            id
            value
            color
        }
    }`, {
        refetchQueries: ['PROJECT_FIELDS']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_ATTRIBUTE_VALUE_CREATE_REQUEST')
    })
}

export const useMutationUpdateProjectAttributeValue = () => {
    const hook = useMutation(gql`
    mutation updateProjectItemAttributeValue($input: UpdateProjectItemAttributeValueInput){
        updateProjectItemAttributeValue(input: $input){
            id
            value
            color
        }
    }`, {
        refetchQueries: []
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_ATTRIBUTE_VALUE_UPDATE_REQUEST')
    })
}

export const useMutationDeleteProjectAttributeValue = () => {
    const hook = useMutation(gql`
    mutation deleteProjectItemAttributeValue($input: DeleteProjectItemAttributeValueInput){
            deleteProjectItemAttributeValue(input: $input){
                id
                value
                color
            }
        }`, {
        refetchQueries: ['PROJECT_FIELDS']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_ATTRIBUTE_VALUE_DELETE_REQUEST')
    })
}

export const useMutationCreateItem = () => {

    const hook = useMutation(gql`
    mutation createProjectItem($input: CreateProjectItemInput){
        createProjectItem(input: $input){
            id
        }
    }`, {
        refetchQueries: []
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_ITEM_CREATE_REQUEST')
    })
}

export const mutationUpdateItem = gql`
mutation updateProjectItem($input: UpdateProjectItemInput){
        updateProjectItem(input: $input){
            ...ItemFragment
        }
    }
    ${ITEM_FRAGMENT}
`
export const useMutationUpdateItem = () => {

    const hook = useMutation(mutationUpdateItem, {
        refetchQueries: [],
    })
    return augmentHook(hook, (data) => {
        trackEvent('PROJECT_ITEM_UPDATE_REQUEST')
        
    })
}
export const mutationDeleteItem = gql`
mutation deleteProjectItem($input: DeleteProjectItemInput){
        deleteProjectItem(input: $input){
            ...ItemFragment
        }
    }
${ITEM_FRAGMENT}`
export const useMutationDeleteItem = () => {

    const hook = useMutation(mutationDeleteItem, {
        refetchQueries: []
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_ITEM_DELETE_REQUEST')
    })
}


export const useMutationCreateProjectProjectFee = () => {
   
    const hook = useMutation(gql`
    mutation createProjectProjectFee($input: CreateProjectProjectFeeInput){
        createProjectProjectFee(input: $input){
            id
            name
        }
    }`, {
        refetchQueries: ['PROJECT_PROJECT']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_PROJECT_FEE_CREATE_REQUEST')
    })
}
export const useMutationUpdateProjectProjectFee = () => {
   
    const hook = useMutation(gql`
    mutation updateProjectProjectFee($input: UpdateProjectProjectFeeInput){
        updateProjectProjectFee(input: $input){
            id
            name
            type
            unitFee
            unitCost
            qtyEstimate
            budget
        }
    }`, {
        refetchQueries: []
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_PROJECT_FEE_UPDATE_REQUEST')
    })
}
export const useMutationDeleteProjectProjectFee = () => {
   
    const hook = useMutation(gql`
    mutation deleteProjectProjectFee($input: DeleteProjectProjectFeeInput){
        deleteProjectProjectFee(input: $input){
            id
        }
    }`, {
        refetchQueries: ['PROJECT_PROJECT']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_PROJECT_FEE_DELETE_REQUEST')
    })
}

export const useMutationCreateProjectTimeLog = () => {
    const hook = useMutation(gql`
    mutation createProjectTimeLog($input: CreateProjectTimeLogInput){
        createProjectTimeLog(input: $input){
            id
        }
    }
    `, {
        refetchQueries: ['PROJECT_TIME']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_TIMELOG_CREATE_REQUEST')
    })
}

export const useMutationUpdateProjectTimeLog = () => {
    const hook = useMutation(gql`
    mutation updateProjectTimeLog($input: UpdateProjectTimeLogInput){
        updateProjectTimeLog(input: $input){
            ...TimeLogFragment
        }
    }
    ${TIME_LOG_FRAGMENT}
    `, {
        refetchQueries: []
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_TIMELOG_UPDATE_REQUEST')
    })
}

export const useMutationDeleteProjectTimeLog = () => {
    const hook = useMutation(gql`
    mutation deleteProjectTimeLog($input: DeleteProjectTimeLogInput){
        deleteProjectTimeLog(input: $input){
            id
        }
    }
    `, {
        refetchQueries: ['PROJECT_TIME']
    })
    return augmentHook(hook, () => {
        trackEvent('PROJECT_TIMELOG_DELETE_REQUEST')
    })
}