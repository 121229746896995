import gql from 'graphql-tag'

export const ITEM_FRAGMENT = gql`fragment ItemFragment on ProjectItem{
    id
    number
    title
    description
    order
    attributes
    createdAt
    updatedAt
    deletedAt
}`
export const PROJECT_VIEW_FRAGMENT = gql`fragment ProjectViewFragment on ProjectView{
    id
    name
    groupBy
    cardLayout
    filters
    
}`
export const TIME_LOG_FRAGMENT = gql`fragment TimeLogFragment on ProjectTimeLog{
    id
    description
    start
    end
    projectProject{
        id
        name
        color
    }
    projectProjectFee{
        id
        name
    }
}`
export const PROJECT_BASE_FRAGMENT = gql`
    fragment ProjectBase on Project{
        id
        name
        members{
            id
            displayName
            shortDisplayName
            photoUrl
        }
        itemAttributes{
            id
            name
            editable
            type
            createdAt
            values{
                id
                value
                color
            }
        }
        projectViews{
            id
            name
            cardLayout
            filters
            groupBy
        }
        projectProjects{
            id
            name
        }
    }
`

export const PROJECT_VIEWS = gql`
    query PROJECT_VIEWS($id: ID){
        me{
            id
            project(id: $id){
                id
                name
                itemAttributes{
                    id
                    name
                    editable
                    type
                    createdAt
                    values{
                        id
                        value
                        color
                    }
                }
                projectViews{
                    id
                    name
                    cardLayout
                    filters
                    groupBy
                }
                
            }
        }
        
    }
`

export const PROJECT_VIEW = gql`
    query PROJECT_VIEW($id: ID,$viewId: ID){
        me{
            id
            project(id: $id){
                ...ProjectBase
                view(id: $viewId) {
                    ...ProjectViewFragment
                }
            }
        }
    }
    ${PROJECT_VIEW_FRAGMENT}
    ${PROJECT_BASE_FRAGMENT}       
`


export const PROJECT_PROJECTS = gql`
    query PROJECT_PROJECTS($id: ID){
        me{
            id
            project(id: $id){
                id
                name
                projectProjects{
                    id
                    name
                    color
                    budgetSpent
                    billable
                    finalPrice
                }
            }
        }
        
    }
`
export const PROJECT_PROJECT = gql`
    query PROJECT_PROJECT($id: ID,$where: ProjectProjectWhereInput){
        me{
            id
            project(id: $id){
                ...ProjectBase
                actualProjectProject: projectProjects(where: $where){
                    id
                    name
                    color
                    budgetSpent
                    finalPrice
                    billable
                    projectProjectFees{
                        id
                        name
                        type
                        unitCost
                        unitFee
                        qtyEstimate
                        budget
                        budgetSpent
                        qtySpent
                    }
                }
            }
        }
    }
    ${PROJECT_BASE_FRAGMENT}       
`

export const PROJECT_PROJECT_FEE = gql`
    query PROJECT_PROJECT($id: ID,$where1: ProjectProjectWhereInput, $where2: ProjectProjectFeeWhereInput){
        me{
            id
            project(id: $id){
                ...ProjectBase
                actualProjectProject: projectProjects(where: $where1){
                    id
                    name
                    actualProjectProjectFee: projectProjectFees(where: $where2){
                        id
                        name
                        type
                        unitCost
                        unitFee
                        qtyEstimate
                        budget
                    }
                }
            }
        }
    }
    ${PROJECT_BASE_FRAGMENT}     
`
export const PROJECT_TEAM = gql`
    query PROJECT_TEAM($id: ID){
        me{
            id
            project(id: $id){
                ...ProjectBase
                projectInvites{
                    id
                    recipientEmail
                    role
                }
                memberRoles{
                    id
                    member{
                        id
                        firstName
                        lastName
                        displayName
                        shortDisplayName
                        username
                    }
                    
                    role
                }
            }
        }
    }
    ${PROJECT_BASE_FRAGMENT}       
`

export const PROJECT_FIELDS = gql`
    query PROJECT_FIELDS($id: ID){
        me{
            id
            project(id: $id){
                ...ProjectBase
            }
        }
        
    }
    ${PROJECT_BASE_FRAGMENT}
`


export const PROJECT_TIME = gql`
    query PROJECT_TIME($id: ID, $view: TimeLogsViewInput){
        me{
            id
            project(id: $id){
                ...ProjectBase
                timeLogsView(input: $view){
                    ...TimeLogFragment
                }
            }
        }
    }
    ${PROJECT_BASE_FRAGMENT}
    ${TIME_LOG_FRAGMENT}
`

export const PROJECT_TIME_FEES = gql`
    query PROJECT_TIME_FEES($id: ID,$where: ProjectProjectWhereInput){
        me{
            id
            project(id: $id){
                id
                actualProjectProject: projectProjects(where: $where){
                    id
                    projectProjectFees{
                        id
                        name
                        type
                        unitCost
                        unitFee
                        qtyEstimate
                        budget
                    }
                }
            }
        }
    }       
`