import React, { useEffect, useState } from 'react'
import { Redirect, Route } from "react-router-dom";

import { acceptInvite } from '../state/ducks/project/operations';
import { connect } from "react-redux";
import { logoutUser } from '../state/ducks/auth/operations';
import { useHistory } from "react-router-dom";

export default connect((state, ownProps) => ({
    
}))((props) => {
    let history = useHistory();
    useEffect(() => {
        logoutUser(() => {
            console.log('Resolve')
            history.push('/login')
        })(props.dispatch);
        
        
    }, [])
    return (<div></div>) 
})