import { AnchorButton, Button, Classes, Code, Dialog, H5, Intent, Switch, Tooltip } from "@blueprintjs/core";

import MigrationImage from '../../assets/migration.svg'
import { Modal } from '@atlaskit/onboarding';
import React from "react"

export default (props) => {
    const { open } = props
    return (<>
        
            <Dialog
              isOpen={open}
              onClose={() => props.onClose()}
            >
                <div style={{textAlign: 'center', padding: '3em'}}>
                <img src={MigrationImage} style={{display: 'block', margin:'auto', width: '300px', marginBottom: '3em'}}></img>
              <H5>
                Let us do the heavy work
              </H5>
              <p>We are able to import tasks from:<br /> Jira, Trello, Asana, Excell, and other software.</p>
              <p>The service is provided for FREE!</p>
              <div style={{height: '2em'}}></div>
              <Button onClick={()=>{
                  (window as any).Tawk_API.toggle()
              }} large={true} intent={'primary'}>Talk to a migration expert</Button>
              </div>
            </Dialog>
        
    </>)
}