import toaster from './toaster'
export default ({
    error,
    shouldNotify = true
}) => {
    console.error(error)
   
    if (!error.status && !error.graphQLErrors) {
        toaster.show({ message: 'We cannot reach our servers, please check your connection.', icon: 'error', intent: 'danger'})
    }
    if(shouldNotify && error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message)
        toaster.show({ message: error.graphQLErrors[0].message, icon: 'error', intent: 'danger'})
}