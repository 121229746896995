import "firebase/auth";
import "firebase/firestore";

import firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCs1cYtUZrEjHOcyVoLcqEz8E9ng10U4WQ",
    authDomain: "agile-e3eed.firebaseapp.com",
    databaseURL: "https://agile-e3eed.firebaseio.com",
    projectId: "agile-e3eed",
    storageBucket: "agile-e3eed.appspot.com",
    messagingSenderId: "586445408701",
    appId: "1:586445408701:web:4c1a5424bfe8b36e28ead4"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;