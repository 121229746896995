import * as actions from "./actions";

import { myFirebase } from "../../../firebase/firebase";

const loginUser = (email, password) => (dispatch: any) => {
    dispatch(actions.requestLogin(email, password));
}
const logoutUser = (resolve?,reject?) => dispatch => {
    dispatch(actions.requestLogout(resolve,reject));
};
const verifyAuth = () => dispatch => {
    dispatch(actions.verifyRequest());
}
const signupUser = (email, password) => dispatch => {
    dispatch(actions.requestSignup(email, password));
}
const resetUser = (email) => dispatch => {
    dispatch(actions.requestReset(email));
}
export {
    loginUser,
    logoutUser,
    verifyAuth,
    signupUser,
    resetUser
};