import {
  Button,
  Callout,
  Card,
  Elevation,
  FormGroup,
  H2,
  Icon,
  InputGroup,
  Intent,
  Switch
} from "@blueprintjs/core";
import { Link, Redirect } from "react-router-dom";
import React, { Component, useCallback, useState } from "react";

import FlexView from 'react-flexview'
import Logo from "../../components/UI/Logo";
import { connect } from "react-redux";
import { loginUser } from "../../state/ducks/auth/operations";
import { setTheme } from "../../state/ducks/interface/operations";
import {
useLocation
} from "react-router-dom";

const Login = props => {
  const location = useLocation()
  const { dispatch, loginError, isAuthenticated, isLoggingIn, theme } = props;
  const [credentials, setCredentials] = useState({
    email: "",
    password: ""
  });
  const login = useCallback(() => {
    loginUser(credentials.email, credentials.password)(dispatch);
  }, [credentials]);
  const switchTheme = useCallback(e => {
    const darkMode = (e.target as HTMLInputElement).checked;
    const theme = darkMode ? "dark" : "light";
    setTheme(theme)(dispatch);
  }, []);
  return (
    <>
      {isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <div style={{ paddingTop: 100 }}>

          <Card
            elevation={4}
            style={{
              margin: "auto",
              display: "block",
              marginTop: 0,
              width: "100%",
              maxWidth: "400px",
              minHeight: "50%"
            }}
          >
              <div style={{padding: '2em 0em'}}><Logo format="v" width={150}></Logo></div>
            <FlexView style={{'justifyContent': 'space-between'}}>
              <FlexView>
                {/* <H2>Login</H2> */}
              </FlexView>
              <FlexView vAlignContent='center'>
                {/* <Switch checked={theme === "dark"} onChange={switchTheme}>
                  <Icon icon="moon" />
                </Switch> */}
              </FlexView>
            </FlexView>

                {location && location.state && location.state.showAlert ? (<div style={{paddingBottom: '2em'}}>
                <Callout icon={'lock'} title={'Login Required'}>You should login or sign up to access this page.</Callout>
                </div>):null} 
            <InputGroup
            large={true}
              id="email"
              placeholder={"Email"}
              value={credentials.email}
              onChange={e => {
                setCredentials({ ...credentials, email: e.target.value });
              }}
            ></InputGroup>
            <div style={{ padding: "0.5em" }}></div>
            <FormGroup
            helperText={(
                <Link to="/forgot-password">
                {/* <Button intent={Intent.PRIMARY} minimal={true}> */}
                Forgot my password
                {/* </Button> */}
              </Link>
              )}>
                  <InputGroup
            large={true}
              id="password"
              placeholder={"Password"}
              type="password"
              value={credentials.password}
              onChange={e => {
                setCredentials({ ...credentials, password: e.target.value });
              }}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  login();
                }
              }}
              
            ></InputGroup>
              </FormGroup>
            
           
              {loginError && (<div style={{ paddingTop: "1em", paddingBottom: "1em" }}>
             
               
                <Callout intent={Intent.DANGER}>{loginError}</Callout>
               
              
            </div>)}
            <Button
              intent={Intent.PRIMARY}
              loading={isLoggingIn}
              fill={true}
              onClick={login}
              large={true}
            >
              Log in
            </Button>
            <div style={{ textAlign: "center", padding: "1em" }}>
              Don't have an account?
              <div>
                <Link to="/signup">
                  <Button intent={Intent.PRIMARY} minimal={true}>
                    Sign up
                  </Button>
                </Link>
              </div>
            </div>
            
          </Card>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated,
    theme: state.interface.theme
  };
}

export default connect(mapStateToProps)(Login);
