import React from "react";
import pSBC from 'shade-blend-color';
var getContrast = function(hexcolor) {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      .map(function(hex) {
        return hex + hex;
      })
      .join("");
  }

  // Convert to RGB value
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "black" : "white";
};

export default props => {
  const contrastColor = getContrast(props.color);

  return (
    <div style={{width: 180}}>
      <div
        style={{
          backgroundColor: props.color,
          height: "100px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: contrastColor === 'white' ? pSBC(0.30, props.color) : pSBC(-0.30, props.color)
        }}
      >
        {props.color}
      </div>
      <div style={{ padding: "1em" }}>
        {props.colors.map(color => (
          <div key={color} style={{padding: '0.2em', paddingBottom: 0, display: 'inline-block'}} >
              <div style={{backgroundColor: color, border: '1px solid', borderColor: pSBC(-0.30, color), borderRadius: 3, width: 32, height: 32, cursor: 'pointer'}} onClick={()=>{props.onChange(color)}}></div>
          </div>
        ))}
      </div>
    </div>
  );
};
