import * as types from "./types";

const requestLogin = (email, password) => {
    return {
        type: types.LOGIN_REQUEST,
        email,
        password
    };
};

const receiveLogin = user => {
    return {
        type: types.LOGIN_SUCCESS,
        user
    };
};
const loginError = (error) => {
    return {
      type: types.LOGIN_FAILURE,
      error
    };
  };

  const requestSignup = (email, password) => {
    return {
        type: types.SIGNUP_REQUEST,
        email,
        password
    };
};

const receiveSignup = () => {
    return {
        type: types.SIGNUP_SUCCESS
    };
};
const signupError = (error) => {
    return {
      type: types.SIGNUP_FAILURE,
      error
    };
  };

  const requestLogout = (resolve?, reject?) => {
    return {
      type: types.LOGOUT_REQUEST,
      payload:{
        resolve,
        reject
      }
    };
  };
  
  const receiveLogout = () => {
    return {
      type: types.LOGOUT_SUCCESS,
      
    };
  };
  
  const logoutError = () => {
    return {
      type: types.LOGOUT_FAILURE
    };
  };
  
  const verifyRequest = () => {
    return {
      type: types.VERIFY_REQUEST
    };
  };
  
  const verifySuccess = () => {
    return {
      type: types.VERIFY_SUCCESS
    };
  };


  const requestReset = (email) => {
    return {
        type: types.RESET_REQUEST,
        email
    };
};

const receiveReset = () => {
    return {
        type: types.RESET_SUCCESS
    };
};
const resetError = (error) => {
    return {
      type: types.RESET_FAILURE,
      error
    };
  };
export {
    requestLogin,
    receiveLogin,
    loginError,
    requestSignup,
    receiveSignup,
    signupError,
    requestLogout,
    receiveLogout,
    logoutError,
    verifyRequest,
    verifySuccess,
    requestReset,
    receiveReset,
    resetError
};