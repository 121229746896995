import * as types from "./types";

import { combineReducers } from "redux";

const reducer = ( state = {
    q1: null,
    items: {},
    isCreating: false,
    isFetching: false,
    showBin: false,
    memberRoles: [],
    projectInvites: [],
    actualProject: null
}, action:any ) => {
    switch( action.type ) {
        case types.PROJECT_SET_ACTUAL: return {
            ...state,
            actualProject: action.payload.project
        };
        case types.PROJECT_VIEW_DATA: return {
            ...state,
            q1: action.payload.data.me.project
        };
        case types.PROJECT_ITEMS_DATA: return {
            ...state,
            items: { 
                ...state.items,
                ...action.payload.data.me.project.items.reduce((a,b) => ({...a, [b.id]:b}),{})
            }
        };
        case types.PROJECT_CREATE_REQUEST: return {
            ...state,
            isCreating: true
        };
        case types.PROJECT_CREATE_SUCCESS: return {
            ...state,
            isCreating: false
        };
        case types.PROJECT_CREATE_FAILURE: return {
            ...state,
            isCreating: false
        };
        case types.PROJECT_FETCH_ALL_REQUEST: return {
            ...state,
            isFetching: true,
        };
        case types.PROJECT_FETCH_ALL_SUCCESS: return {
            ...state,
            isFetching: false,
            data: action.payload.response.me.projects
        }
        case types.PROJECT_FETCH_ALL_FAILURE: return {
            ...state,
            isFetching: false,
            data: []
        }
        case types.PROJECT_ATTRIBUTES_FETCH_SUCCESS: return {
            ...state,
            attributes: action.payload.response.me.project.itemAttributes
        }
        case types.PROJECT_MEMBERS_FETCH_SUCCESS: return {
            ...state,
            memberRoles: action.payload.response.me.project.memberRoles,
            projectInvites: action.payload.response.me.project.projectInvites,
        }
        case 'dnd/ON_DRAG_START': return {
            ...state,
            showBin: true,
        }
        case 'dnd/ON_DRAG_END': return {
            ...state,
            showBin: false,
        }
        case types.PROJECT_VIEW_STOP: return {
            ...state,
            q1: null,
            items: {},
            isCreating: false,
            isFetching: false,
            showBin: false,
            memberRoles: [],
            projectInvites: []
        }
        /* ... */
        default: return state;
    }
}

export default reducer;