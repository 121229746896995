function loggedUser( state ) {
    const myId = state.user.me
    if(myId !== undefined && myId !== null){
        return state.user.data[myId]
    }else{
        return myId
    }
}

export {
    loggedUser
};