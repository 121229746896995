import * as actions from "./actions";

// This is a link to an action defined in actions.js.
// const simpleQuack = actions.quack;

// // This is a thunk which dispatches multiple actions from actions.js
const setupUser = ( form ) => ( dispatch: any ) => {
    return new Promise((resolve, reject) => {
        dispatch(actions.setupUser(form, resolve, reject))
    })
}
const updateUser = ( form ) => ( dispatch: any ) => {
    return new Promise((resolve, reject) => {
        dispatch(actions.updateUser(form, resolve, reject))
    })
}

const fetchLoggedUser=() => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(actions.fetchMe(resolve, reject))
    })
}
export {
    setupUser,
    fetchLoggedUser,
    updateUser
    // simpleQuack,
    // complexQuack
};