import Fuse from "fuse.js"

function projectView(state, projectId, viewId ) {
    if(viewId){
        return state.projectView.data.find((o)=>o.id === viewId)
    }else{
        // Prima vista disponibile
        // TODO: Sort by date
        return state.projectView.data.find((o)=>o.projectId === projectId)
    }
}
function projectViews(state, projectId ) {
    return state.projectView.data.filter((o)=>o.projectId === projectId)
}
function laneTasks( state, filter ) {
    const items: any = []
    for(const key of Object.keys(state.project.items)){
        const item = state.project.items[key]
        items.push(item)
    }
    return items
}
function viewItems(state,filters, groupBy, searchText){
    
    const parsedFilters = JSON.parse(filters) || {}
    let groupedItems: any = {'':[]}

    let allItems = Object.keys(state.project.items).map((k) => state.project.items[k])

    if(searchText !== "" && searchText !== null){
        var fuse = new Fuse(allItems, {keys: ['title', 'name','description', 'number']})
        allItems = fuse.search(searchText);
    }
    
    // TODO: Filter based on filters
    for(const item of allItems){
       
        const itemAttributes = JSON.parse(item.attributes) || {}

        let show = true
        if(item.deletedAt){
            show = false
        }

        // Filtro di testo

        for(const attributeId of Object.keys(parsedFilters)){
            if(parsedFilters[attributeId] === 'NA' && itemAttributes[attributeId]){
                show = false
            }
            if(parsedFilters[attributeId] !== 'NA' && itemAttributes[attributeId] !== parsedFilters[attributeId]){
                show = false
            }
        }
        if(show){
            if(!groupBy){
                groupedItems[''].push(item)
            }else{
                item.parsedAttributes = JSON.parse(item.attributes) || {}
                const key = item.parsedAttributes[groupBy]
                groupedItems = {...groupedItems,[key ? key : '']: [...(groupedItems[key ? key : '']||[]), item] }
            }
        }
        

    }
    return groupedItems
    
}
function itemById(state, itemId){
    return state.project.items[itemId]
}
export {
    projectView,
    projectViews,
    laneTasks,
    itemById,
    viewItems
};