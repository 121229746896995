import {
FormGroup,
InputGroup,
} from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";

import FlexView from "react-flexview";
import { joinList } from "../../utils";

const ProfileForm = ({formErrors, form, setForm}) => {
   

    return <>
    {/* <FormGroup label="Profile Picture">
        <div {...getRootProps()} style={{cursor: 'pointer'}}>
        <input {...getInputProps()} />
            <Avatar onClick={() => {}} appearance={'circle' as AppearanceType} size={'large' as SizeType} src={loggedUser.photoURL ? loggedUser.photoURL : 'https://api.adorable.io/avatars/64/'+loggedUser.uid+'.png'}></Avatar>
            </div>
            
          </FormGroup> */}
          <FlexView style={{width: '100%'}}>
          <FormGroup label="First Name" labelInfo={'*'} style={{flex: '1'}} helperText={formErrors.firstName ? joinList(formErrors.firstName) : null} intent={formErrors.firstName && formErrors.firstName.length > 0 ? 'danger': 'none'}>
            <InputGroup
              large={true}
              id="firstName"
              placeholder={"First Name"}
              
              value={form.firstName}
              onChange={e => {
                setForm({ ...form, firstName: e.target.value });
              }}
            ></InputGroup>
          </FormGroup>
          <div style={{flex: '0 0 1em'}}></div>
          <FormGroup label="Last Name" labelInfo={'*'} style={{flex: '1'}} helperText={formErrors.lastName ? joinList(formErrors.lastName):null} intent={formErrors.lastName && formErrors.lastName.length > 0 ? 'danger': 'none'}>
            <InputGroup
              large={true}
              id="lastName"
              placeholder={"Last Name"}
              value={form.lastName}
              onChange={e => {
                setForm({ ...form, lastName: e.target.value });
              }}
            ></InputGroup>
          </FormGroup>
          </FlexView>
          <FormGroup label="Username" labelInfo={'*'} helperText={formErrors.username ? joinList(formErrors.username) : null} intent={formErrors.username && formErrors.username.length > 0 ? 'danger': 'none'}>
            <InputGroup
              large={true}
              id="lastName"
              placeholder={"Username"}
              value={form.username}
              leftIcon={'user'}
              onChange={e => {
                setForm({ ...form, username: e.target.value });
              }}
            ></InputGroup>
          </FormGroup>
    </>
}

export default ProfileForm