import * as types from "./types";

export const requestCreate = (name,resolve?,reject?) => ( {
    type: types.PROJECT_CREATE_REQUEST,
    payload: {
        name,
        resolve,
        reject
    }
} );

export const setActual = (project) => ({type: types.PROJECT_SET_ACTUAL, payload: {project}})
export const receiveCreate = () => ({type: types.PROJECT_CREATE_SUCCESS})
export const errorCreate = () => ({type: types.PROJECT_CREATE_FAILURE})

export const requestFetchAll = () => ({type: types.PROJECT_FETCH_ALL_REQUEST})
export const receiveFetchAll = (response) => ({type: types.PROJECT_FETCH_ALL_SUCCESS, payload: {response: response.data}})
export const errorFetchAll = () => ({type: types.PROJECT_FETCH_ALL_FAILURE})

export const requestFetch = (id) => ({type: types.PROJECT_FETCH_REQUEST, payload: {id: id}})
export const requestCreatePane = (projectViewId, resolve?, reject?) => ({type: types.PROJECT_VIEW_PANE_CREATE_REQUEST, payload: {projectViewId, resolve, reject} })
export const requestDeletePane = (projectViewPaneId, resolve?, reject?) => ({type: types.PROJECT_VIEW_PANE_DELETE_REQUEST, payload: {projectViewPaneId, resolve, reject} })
export const requestUpdatePane = (projectViewPaneId, data, resolve?, reject?) => ({type: types.PROJECT_VIEW_PANE_UPDATE_REQUEST, payload: {projectViewPaneId, ...data, resolve, reject} })
export const requestBatchUpdatePane = (inputs, resolve?, reject?) => ({type: types.PROJECT_VIEW_PANE_BATCH_UPDATE_REQUEST, payload: {inputs, resolve, reject}})

export const requestCreateView = (projectId, resolve?, reject?) => ({type: types.PROJECT_VIEW_CREATE_REQUEST, payload: {projectId, resolve, reject}})
export const requestDeleteView = (projectId, viewId, resolve?, reject?) => ({type: types.PROJECT_VIEW_DELETE_REQUEST, payload: {projectId, viewId, resolve,reject}})
export const requestCreateItem = (projectId, data, resolve?, reject?) => ({type: types.PROJECT_ITEM_CREATE_REQUEST, payload: {projectId, data, resolve, reject} })
export const requestUpdateItem = (projectId, itemId, data, resolve?, reject?) => ({type: types.PROJECT_ITEM_UPDATE_REQUEST, payload: {projectId,itemId,data, resolve, reject} })
export const requestDeleteItem = (projectId, itemId, resolve?, reject?) => ({type: types.PROJECT_ITEM_DELETE_REQUEST, payload: {projectId,itemId, resolve, reject} })


export const requestAttributesFetch = (projectId, onListen) => ({type: types.PROJECT_ATTRIBUTES_FETCH_REQUEST, payload: {projectId: projectId, onListen}})
export const requestMembersFetch = (projectId, onListen) => ({type: types.PROJECT_MEMBERS_FETCH_REQUEST, payload: {projectId: projectId, onListen}})

export const receiveAttributesFetch = (response) => ({type: types.PROJECT_ATTRIBUTES_FETCH_SUCCESS, payload: {response: response}})
export const errorAttributesFetch = () => ({type: types.PROJECT_ATTRIBUTES_FETCH_FAILURE})

export const receiveMembersFetch = (response) => ({type: types.PROJECT_MEMBERS_FETCH_SUCCESS, payload: {response: response}})



export const requestAttributeCreate = (projectId, resolve, reject) => ({type: types.PROJECT_ATTRIBUTE_CREATE_REQUEST, payload: {projectId, resolve, reject}})
export const requestAttributeUpdate = (projectId, attributeId, data, resolve, reject) => ({type: types.PROJECT_ATTRIBUTE_UPDATE_REQUEST, payload: {projectId, attributeId, data, resolve, reject}})
export const requestAttributeDelete = (projectId, attributeId, resolve, reject) => ({type: types.PROJECT_ATTRIBUTE_DELETE_REQUEST, payload: {projectId, attributeId, resolve, reject}})

export const requestAttributeValueCreate = (projectId, attributeId, resolve, reject) => ({type: types.PROJECT_ATTRIBUTE_VALUE_CREATE_REQUEST, payload: {projectId, attributeId, resolve, reject}})
export const requestAttributeValueUpdate = (projectId, attributeId, valueId, data, resolve, reject) => ({type: types.PROJECT_ATTRIBUTE_VALUE_UPDATE_REQUEST, payload: {projectId, attributeId, valueId, data, resolve, reject}})
export const requestAttributeValueDelete = (projectId, attributeId, valueId, resolve, reject) => ({type: types.PROJECT_ATTRIBUTE_VALUE_DELETE_REQUEST, payload: {projectId, attributeId, valueId, resolve, reject}})

export const requestProjectViewUpdate = (projectId, projectViewId, data, resolve?, reject?) => ({type: types.PROJECT_VIEW_UPDATE_REQUEST, payload: {projectId, projectViewId, data, resolve, reject}})

export const requestProjectInviteCreate = (projectId, role, email, resolve?, reject?) => ({type: types.PROJECT_INVITE_CREATE_REQUEST, payload: {projectId, role, email, resolve, reject}})
export const requestProjectInviteDelete = (projectId, inviteId, resolve?, reject?) => ({type: types.PROJECT_INVITE_DELETE_REQUEST, payload: {projectId, inviteId, resolve, reject}})
export const requestProjectInviteAccept = (projectId, inviteId, resolve?, reject?) => ({type: types.PROJECT_INVITE_ACCEPT_REQUEST, payload: {projectId, inviteId, resolve, reject}})
export const requestProjectMemberDelete = (projectId, memberRoleId, resolve?, reject?) => ({type: types.PROJECT_MEMBER_DELETE_REQUEST, payload: {projectId, memberRoleId, resolve, reject}})

