import { Button, Icon, InputGroup, Popover } from "@blueprintjs/core";
import { Draggable, Droppable } from "react-beautiful-dnd";
import React, {useCallback, useState} from "react"

import UserAvatar from "../UserAvatar";
import { connect } from "react-redux";
import { createItem } from "../../state/ducks/project/operations";
import moment from "moment";
import pSBC from 'shade-blend-color';
import { useMutationCreateItem } from "../../state/mutations";

export default connect((state, ownProps) => ({}))(props => {
    const { stx, k } = props;
    const [openCreate, setOpenCreate] = useState(false);
    const [creating, setCreating] = useState(false);
    const [name, setName] = useState("");
    const [createItem] = useMutationCreateItem()
    const createItemCb = useCallback(
      (k) => {
        setCreating(true);
        createItem({variables: {
          input: {
            projectId: props.projectId,
            title: name,
            attributes: props.groupBy && k.id != "" ? JSON.stringify({
              [props.groupBy]: k.id,
              ...(props.jsonFilters || {})
            }) : JSON.stringify({ ...(props.jsonFilters || {}) })
          }
        }}).then((response) => {
          setCreating(false);
          setName("");
          setOpenCreate(false);
          // props.showEdit(response.data.createProjectItem.id);
        }).catch((e) => {
          setName("");
          setOpenCreate(false);
          setCreating(false);
        })
      },
      [props.projectId,props.groupBy, name, props.jsonFilters ],
    )
    return (
      <div style={{ ...stx }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "10px"
          }}
        >
          <div
            style={{
              color: "#A1A8B9",
              textTransform: "uppercase",
              fontSize: "0.9em",
              marginBottom: 0
            }}
          >
            {k.value} <span>({(props.items[k.id] || []).length})</span>
          </div>
          <Popover onInteraction={(v) => setOpenCreate(v)} isOpen={openCreate} position={'bottom'} content={(<div style={{padding: '1em', display: 'flex', flexDirection: 'column'}}>
                    <InputGroup autoFocus={true} placeholder="Title" value={name} onChange={(e) => setName(e.target.value)} onKeyDown={(e)=>{
                        if (e.keyCode === 13) {
                            createItemCb(k);
                          }
                    }}></InputGroup>
                    <div style={{flex:'0 0 1em'}}></div>
                    <Button fill={true} intent={'primary'} loading={creating} onClick={() => createItemCb(k)}>
                        Create
                      </Button></div>
                )}>
          <Button
            icon={"add"}
            style={{ opacity: 0.5 }}
            minimal={true}
            loading={creating}
            
          ></Button></Popover>
        </div>
        
        <Droppable droppableId={"list-" + k.id} ignoreContainerClipping={true}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                minHeight: 50,
                background: snapshot.isDraggingOver
                  ? "rgba(0,0,0,0.03)"
                  : "#F2F7FA",
                padding: "0.7em",
                paddingBottom: 0,
                borderRadius: "8px"
              }}
            >
              {(props.items[k.id] || [])
                .sort((a, b) => b.order - a.order)
                .map((item, index) => {
                  const itemAttr = item ? JSON.parse(item.attributes) || {} : {}
                  const owner = itemAttr['owner'] ? props.projectMembers.find((member) => member.id === itemAttr['owner']) : null
                  return (<Draggable
                    key={item.id}
                    draggableId={"item-" + item.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div key={item.id} className={"listItemContainer"}>
                          <div
                            className={"listItem"}
                            style={{
                              border:
                                snapshot.draggingOver === "archive"
                                  ? "1px solid red"
                                  : ""
                            }}
                            onClick={() => props.editItem(item.id)}
                          >
                            <div className={'itemSpace'}>
                            {(props.jsonCardLayout.visibleCA||[]).map((k) => {
                              
                              
                              let v: any = null
                              if(itemAttr[k]){
                               
                                v = props.projectAttributes.find((a) => a.id === k).values.find((v) => v.id === itemAttr[k])
                              }
                              return (v ? <span key={k} className={'itemTag'} style={{backgroundColor: v.color ? pSBC(0.80, v.color) : pSBC(0.80, '#51595F'), color: v.color ? v.color : '#51595F'}}>{v ? v.value : ''}</span> : <span key={k}></span>)
                              })}
                            
                            </div>
                            <div>{item.title}</div>
                            <div className={'itemSpace'} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                              {/* <div className={'listItemInfo'}>{item.number}</div> */}
                              <div style={{fontSize: '0.8em'}}>
                                {itemAttr['dueDate'] ? <><Icon iconSize={10} icon={'time'}/> {moment(itemAttr['dueDate']).calendar()}</> : null}
                              </div>
                              { owner ? <UserAvatar displayName={owner.displayName} shortDisplayName={owner.shortDisplayName} ></UserAvatar> : null}
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    )}
                  </Draggable>
                )}
                )}
                {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  });