import * as types from "./types";

import { combineReducers } from "redux";

/* State Shape
{
    quacking: bool,
    distance: number
}
*/

const reducer = ( state = {
    data: [
        {
            id: "Mock",
            projectId: "abc",
            name: "XYZ",
            splitView: {
                type:"flex",
                splitDir: "v",
                split: [
                    {
                        type:"flex",
                        splitDir: "h",
                        split: [
                            {
                                type: "panel",
                                name: "Sprint 132"
                            },
                            {
                                type: "panel",
                                name: "Done"
                            }
                        ]
                    },
                    {
                        type: "panel",
                        name: "To Do"
                    },
                    {
                        type: "panel",
                        name: "In Testing"
                    }
                ]
            }
        }
    ]
}, action:any ) => {
    switch( action.type ) {
        case types.QUACK: return true;
        /* ... */
        default: return state;
    }
}



export default reducer;