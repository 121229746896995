import * as types from "./types";

import { combineReducers } from "redux";

const authReducer = ( state = {
    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: false,
    isSigningUp: false,
    isResetting: false,
    loginError: false,
    logoutError: false,
    signupError: false,
    resetSuccess: false,
    resetError: false,
    isAuthenticated: false,
    user: {}
  }, action: any ) => {
    switch (action.type) {
        case types.LOGIN_REQUEST:
          return {
            ...state,
            isLoggingIn: true,
            loginError: false
          };
        case types.LOGIN_SUCCESS:
          return {
            ...state,
            isLoggingIn: false,
            isAuthenticated: true,
            user: action.user
          };
        case types.LOGIN_FAILURE:
          return {
            ...state,
            isLoggingIn: false,
            isAuthenticated: false,
            loginError: action.error
          };
        case types.SIGNUP_REQUEST:
          return {
            ...state,
            isSigningUp: true,
            signupError: false
          };
        case types.SIGNUP_SUCCESS:
          return {
            ...state,
            isSigningUp: false,
          };
        case types.SIGNUP_FAILURE:
          return {
            ...state,
            isSigningUp: false,
            signupError: action.error
          };
          case types.RESET_REQUEST:
            return {
              ...state,
              isResetting: true,
              resetError: false,
              resetSuccess: false
            };
          case types.RESET_SUCCESS:
            return {
              ...state,
              isResetting: false,
              resetSuccess: true
            };
          case types.RESET_FAILURE:
            return {
              ...state,
              isResetting: false,
              resetError: action.error,
              resetSuccess: false
            };
        case types.LOGOUT_REQUEST:
          return {
            ...state,
            isLoggingOut: true,
            logoutError: false
          };
        case types.LOGOUT_SUCCESS:
          return {
            ...state,
            isLoggingOut: false,
            isAuthenticated: false,
            user: {}
          };
        case types.LOGOUT_FAILURE:
          return {
            ...state,
            isLoggingOut: false,
            logoutError: true
          };
        case types.VERIFY_REQUEST:
          return {
            ...state,
            isVerifying: true,
            verifyingError: false
          };
        case types.VERIFY_SUCCESS:
          return {
            ...state,
            isVerifying: false
          };
        default:
          return state;
      }
}

// const reducer = combineReducers( {
//     auth: authReducer
// } );
const reducer = authReducer

export default reducer;