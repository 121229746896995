import * as types from "./types";

import { persistReducer, persistStore } from 'redux-persist'

import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'interface',
    storage,
}

const interfaceReducer = ( state = {
    theme: 'light'
}, action:any ) => {
    switch( action.type ) {
        case types.THEME_SET_REQUEST:
            return {
                ...state,
                theme: action.payload.theme
            }
        /* ... */
        default: return state;
    }
}


export default persistReducer(persistConfig, interfaceReducer);