import {
  Button,
  Classes,
  Dialog,
  Drawer,
  FormGroup,
  H1,
  InputGroup,
  NavbarDivider,
  Popover,
  Tag
} from "@blueprintjs/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { PROJECT_TIME, PROJECT_TIME_FEES } from "../../state/graphql";
import RGL, { WidthProvider } from "react-grid-layout";
import React, { useEffect, useState } from "react";
import {
  startProjectView,
  stopProjectView,
  updateProjectView
} from "../../state/ducks/project/operations";
import { useForm, usePlaceholderData } from "../../utils";
import { useHistory, useParams } from "react-router-dom";
import { useMutationCreateProjectTimeLog, useMutationDeleteProjectTimeLog, useMutationUpdateProjectTimeLog } from "../../state/mutations";

import Body from "../../components/Body";
import { Droppable } from "react-beautiful-dnd";
import ItemEditor from "../../components/ItemEditor";
import ItemList from "../../components/ItemList";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import ProjectMenu from "../../components/ProjectMenu";
import ProjectNavBar from "../../components/ProjectNavBar";
import { Query } from "@apollo/react-components";
import Select from "react-select";
import classNames from "classnames";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";
import moment from "moment";
import pSBC from 'shade-blend-color';
import styled from 'styled-components'
import { useQuery } from "@apollo/react-hooks";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
const GridLayout = WidthProvider(RGL);
const CalendarStyle = styled.div`
.rbc-time-view .rbc-allday-cell{
  display:none;
}
.rbc-time-view{
  border: 0px;
}
.rbc-time-content{
  border-top: 1px;
  
}
.rbc-time-slot{
  display: flex;
  justify-content: flex-end;
}
.rbc-day-slot .rbc-events-container{
  margin-left: 5px;
  margin-right: 5px;
}
.rbc-time-content > * + * > *{
  border-left: 0px;
}
.eventTitle{
  font-weight: bold;
  font-size: 0.8em;
}
.eventSubTitle{
  opacity: 0.8;
  font-size: 0.8em;
}
.rbc-day-slot .rbc-time-slot{
  border-top: 0px;
}
.eventCard{
  color: white;
  border-radius: 5px;
  border: 0px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.21);
  
}
`
const EventWrapper = styled.div`
  
  div:hover {
    transition: background-color 200ms linear;
    background-color: ${props => props.event && props.event.resource && props.event.resource.projectProject ? pSBC(0.1,props.event.resource.projectProject.color):pSBC(0.1,'#b3bac5')} !important;
  }
`
const TimeLogEditor = props => {
  const [createProjectTimeLog] = useMutationCreateProjectTimeLog();
  const [deleteProjectTimeLog] = useMutationDeleteProjectTimeLog()
  const [initialValue, setInitialValue]: any = useState(null)
  useEffect(()=>{
    if(props.edited)
    setInitialValue({
      description: props.edited.description,
      projectProjectId: props.edited.projectProject.id,
      projectProjectFeeId: props.edited.projectProjectFee.id
    })
  }, [props.edited])
  const values = initialValue ? initialValue : props.value
  console.log({values})
  const {
    getValue,
    setValue,
    isDirty,
    groupProps,
    handleCatch,
    saving,
    setSaving,
    changes,
    handleThen,
    revert,
    getInitialValue,
    validate
  } = useForm({ values,
    isNew: !props.edited,
    constraints: {
      projectProjectId: {
        presence: {
          message: "^choose a project"
        }
      },
      projectProjectFeeId: {
        presence: {
          message: "^choose an activity"
        }
      }
    },
    successMessage: "Time Log saved!" });
    const [updateTimeLog] = useMutationUpdateProjectTimeLog()
  const { loading, error, data } = useQuery(PROJECT_TIME_FEES, {
    skip: !getValue("projectProjectId"),
    variables: {
      id: props.projectId,
      where: { id: { equals: getValue("projectProjectId") } }
    }
  });
  let activities = [];
  let projects = props.projects
    ? props.projects.map(p => ({ label: p.name, value: p.id }))
    : [];
  let actualProject: any = null;
  let actualActivity: any = null;

  if (data && data.me) {
    activities = data.me.project.actualProjectProject[0].projectProjectFees.filter((up) => up.type=== 'time').map(
      ({ id, name }) => ({ value: id, label: name })
    );
  }
  if (getValue("projectProjectId")) {
    const found = props.projects.find(
      p => p.id === getValue("projectProjectId")
    );
    if (found) {
      const { id, name } = found;
      actualProject = { label: name, value: id };
    }
  }
  if (getValue("projectProjectFeeId") && data && data.me) {
    const found = data.me.project.actualProjectProject[0].projectProjectFees.find(
      p => p.id === getValue("projectProjectFeeId")
    );
    if (found) {
      const { id, name } = found;
      actualActivity = { label: name, value: id };
    }
  }
  const [deleting, setDeleting] = useState(false)
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {props.edited && <Button style={{marginRight: '1em'}} icon={'trash'} minimal={true} intent={'danger'} loading={deleting} onClick={()=>{
          setDeleting(true)
          deleteProjectTimeLog({
            variables: {
              input: {
                projectId: props.projectId,
                projectTimeLogId: props.edited.id,
              }
            }
          }).then(() => {
            setDeleting(false)
            props.onCreated();
          }).catch(() => {
            setDeleting(false)
          })
        }}>Delete</Button>}
        {isDirty && (
          <Button
            style={{ marginRight: "1em" }}
            icon={"undo"}
            onClick={() => {
              revert();
            }}
          >
            Revert
          </Button>
        )}
        <Button
          intent={"primary"}
          disabled={!isDirty}
          loading={saving}
          icon={"floppy-disk"}
          onClick={() => {
            if(!validate()){
              console.log({changes})
              return
            }
            setSaving(true);
            if(props.edited){
              updateTimeLog({
                variables:{
                  input: {
                    projectId: props.projectId,
                    projectTimeLogId: props.edited.id,
                    ...changes
                  }
                }
              }).then(response => {
                handleThen(response);
                props.onCreated();
              })
              .catch(error => {
                console.error(error)
                handleCatch(error);
              });
            }else{
              createProjectTimeLog({
                variables: {
                  input: {
                    userId: props.userId,
                    projectId: props.projectId,
                    projectProjectId: getValue("projectProjectId"),
                    projectProjectFeeId: getValue("projectProjectFeeId"),
                    start: moment(getValue("start")).toISOString(),
                    end: moment(getValue("end")).toISOString(),
                    description: getValue("description"),
                  }
                }
              })
                .then(response => {
                  handleThen(response);
                  props.onCreated();
                })
                .catch(error => {
                  console.error(error)
                  handleCatch(error);
                });
            }
            
          }}
        >
          Save
        </Button>
      </div>
      <div
        style={{
          display: "grid",
          gridGap: "0.5em",
          gridTemplateColumns: "repeat(2,1fr)",
          marginTop: "2em"
        }}
      >
        {/* <FormGroup label="From" {...groupProps('start')}>
          {moment(getValue('start')).calendar()}
      </FormGroup>
      <FormGroup label="To" {...groupProps('end')}>
      {moment(getValue('end')).calendar()}
      </FormGroup> */}
        <FormGroup
          label="Project"
          labelInfo={"*"}
          {...groupProps("projectProjectId")}
        >
          <Select
            placeholder={"Select a project"}
            className={"SESelect"}
            classNamePrefix={"SESelect"}
            value={actualProject}
            onChange={(v: any) => {
              setValue("projectProjectFeeId", null);
              setValue("projectProjectId", v.value);
            }}
            options={
              props.projects
                ? props.projects.map(p => ({ label: p.name, value: p.id }))
                : []
            }
          />
        </FormGroup>
        <FormGroup
          label="Activity"
          labelInfo={"*"}
          {...groupProps("projectProjectFeeId")}
        >
          <Select
            isDisabled={!getValue("projectProjectId")}
            placeholder={"Select a project"}
            className={"SESelect"}
            classNamePrefix={"SESelect"}
            value={actualActivity}
            onChange={(v: any) => {
              setValue("projectProjectFeeId", v.value);
            }}
            options={activities}
          />
        </FormGroup>
        <FormGroup
          style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
          label="Description"
          helperText={"*"}
          {...groupProps("description")}
        >
          <InputGroup
            large={true}
            placeholder={"Description"}
            value={getValue("description") || ""}
            onChange={e => {
              setValue("description", e.target.value);
            }}
          ></InputGroup>
        </FormGroup>
      </div>
    </div>
  );
};

const ProjectTime = connect((state, ownProps) => {
  return {
    loggedUser: loggedUser(state)
  };
})(props => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [startDate, setStartDate] = useState(moment().startOf('week'))
  let { projectId, userId: paramUserId } = useParams();
  const [updateTimeLog] = useMutationUpdateProjectTimeLog()
  const userId = paramUserId ? paramUserId : props.loggedUser.id;
  return (
    <Query
      fetchPolicy={"cache-and-network"}
      query={PROJECT_TIME}
      variables={{ id: projectId, view: {
        start: startDate.clone().subtract(1,'day').toISOString(),
        end: startDate.clone().add(1,'weeks').add(1,'day').toISOString(),
        userId: userId,
      } }}
    >
      {(result) => {
        const { loading, error, data: realData } = result as any
        const { data, skeltonClass } = usePlaceholderData({
          data: {
            me: {
              project: {
                name: "Lorem ipsum dolor",
                id: "123",
                projectViews: [],
                itemAttributes: [],
                timeLogsView: [],
                members: []
              }
            }
          },
          realData,
          loading
        });
       const [editedItem, setEditedItem] = useState(null)
        const [editOpen, setEditOpen] = useState(false);
        const [canClick, setCanClick] = useState(true);
        const [bound, setBound]: any = useState(null);
        const history = useHistory()
        const project = data.me?.project;
        
        return (
          <div>
            <Dialog
              style={{ backgroundColor: "#fff" }}
              onClosed={() => {
                setCanClick(true);
              }}
              isOpen={editOpen}
              onClose={() => {
                setBound(null)
                setEditOpen(false)
                setEditedItem(null)
              }}
            >
              <div style={{ padding: "1em" }}>
                <TimeLogEditor
                  projectId={projectId}
                  userId={userId}
                  projects={project.projectProjects}
                  value={bound}
                  edited={editedItem}
                  onCreated={()=>{
                    setBound(null)
                    setEditOpen(false)
                    setEditedItem(null)
                  }}
                />
              </div>
            </Dialog>
            <div
              style={{
                display: "flex",
                width: "100vw",
                height: "100vh",
                overflow: "hidden"
              }}
            >
              {showSidebar ? (
                <ProjectMenu
                  project={project}
                  activeMenu={"timeTracking"}
                ></ProjectMenu>
              ) : null}
              <div style={{ flex: "1 1", backgroundColor: "#FBFDFF" }}>
                <ProjectNavBar
                  notFixed={true}
                  pre={
                    <>
                      <Button
                        minimal={true}
                        icon={showSidebar ? "chevron-left" : "menu"}
                        text={showSidebar ? "Menu" : "Menu"}
                        onClick={() => setShowSidebar(!showSidebar)}
                      />
                      <NavbarDivider></NavbarDivider>
                      <div
                        style={{
                          color: "white",
                          display: "flex",
                          fontSize: "0.8em"
                        }}
                      >
                        <Link to={"/"} style={{ color: "white" }}>
                          All Workspaces
                        </Link>
                        <div style={{ padding: "0em 0.4em" }}>/</div>
                        <Link
                          className={skeltonClass}
                          to={`/projects/${project ? project.id : ""}`}
                          style={{ color: "white" }}
                        >
                          {project ? project.name : ""}
                        </Link>
                        <div style={{ padding: "0em 0.4em" }}>/</div>
                        <Link
                          className={skeltonClass}
                          to={`/projects/${
                            project ? project.id : ""
                          }/time-tracking`}
                          style={{ color: "white" }}
                        >
                          Time Tracking
                        </Link>
                      </div>
                    </>
                  }
                  post={
                    <>
                      {/* <Button
                        className={skeltonClass}
                        minimal={true}
                        icon={"download"}
                      >
                        Export
                      </Button> */}
                    </>
                  }
                ></ProjectNavBar>
                <Body>
                  <div style={{ padding: "2em", position: 'relative' }}>
                    <div style={{position: 'absolute', right: '2em', top: '1.5em', width: '300px'}}>
                  <Select
                    placeholder={"Select a person"}
                    className={"SESelect"}
                    classNamePrefix={"SESelect"}
                    value={project.members.map((member) => ({label: member.displayName,value: member.id})).find((m) => m.value === userId)}
                    onChange={(v: any) => {
                      history.push(`/projects/${projectId}/time-tracking/u/${v.value}`)
                    }}
                    options={
                      project.members.map((member) => ({label: member.displayName,value: member.id}))
                    }
                  />
                  </div>
                    {/*                   
                  <div style={{paddingBottom: '2em', display: 'flex', justifyContent:'space-between'}}>
                      <div></div>
                      <div>
                      <Button intent={'primary'} icon={'download'}>Export</Button>
                      </div>
                      
                  </div> */}
                  <CalendarStyle>
                    <DnDCalendar
                      className={skeltonClass}
                      localizer={localizer}
                      resizable
                      defaultView="week"
                      selectable={true}
                      step={15}
                      timeslots={4}
                      views={["week"]}
                      events={[
                        ...((bound && [
                          { ...bound, title: "New", allDay: false }
                        ]) ||
                          []),
                        ...project.timeLogsView.map((item) => ({resource: item, title: item.projectProject.name, start: new Date(item.start), end: new Date(item.end), allDay: false}))
                      ]}
                      startAccessor="start"
                      endAccessor="end"
                      components={{
                        event: (e) => {
                        
                          return <div>
                            <div className={'eventTitle'}>{e.title}</div>
                            <div className={'eventSubTitle'}>
                              {e && e.event && e.event.resource ? e.event.resource.projectProjectFee.name : ""}
                            </div>
                          </div>
                        },
                        eventWrapper: (props) => {
                          // console.log({props})
                          return <EventWrapper {...props}></EventWrapper>
                        }
                      }}
                      scrollToTime={new Date()}
                      eventPropGetter={(event, start, end, isSelected) => {
                        const color = event && event.resource && event.resource.projectProject.color ? event.resource.projectProject.color : '#51595F'
                        return {
                          className: 'eventCard',
                          style: {
                            backgroundColor: color,
                            
                            
                          }
                        }
                      }}
                      onNavigate={e => {
                        // console.log('SETSSTART',moment(e))
                        setStartDate(moment(e).startOf('week'))
                       
                      }}
                      onView={e => {
                        console.log({ onView: e });
                      }}
                      onSelectSlot={e => {
                        console.log({ onSelectSlot: e });
                        if (canClick) {
                          setEditOpen(true);
                          setBound({ start: e.start, end: e.end });
                          setCanClick(false);
                        }
                      }}
                      onRangeChagne={e => {
                        
                        
                        console.log({ onRangeChagne: e });
                      }}
                      onSelectEvent={e => {
                        console.log({ onSelectEvent: e });
                        setCanClick(false);
                        setEditedItem(e.resource)
                        setEditOpen(true)
                      }}
                      onSelecting={e => {
                        console.log({ onSelecting: e });
                      }}
                      onEventDrop={e => {
                        const item = e.event.resource
                        const exp = {
                          start: moment(e.start).toISOString(),
                          end: moment(e.end).toISOString()
                        }
                        updateTimeLog({
                          variables: {
                            input: {
                              projectId: projectId,
                              projectTimeLogId: item.id,
                              ...exp
                            }
                          },
                          optimisticResponse: {
                            __typename: "Mutation",
                            updateProjectTimeLog: {
                              id: item.id,
                              __typename: "ProjectTimeLog",
                              ...exp
                            }
                          },
                        })
                        console.log({ onEventDrop: e });
                      }}
                      onEventResize={e => {
                        const item = e.event.resource
                        const exp = {
                          start: moment(e.start).toISOString(),
                          end: moment(e.end).toISOString()
                        }
                        updateTimeLog({
                          variables: {
                            input: {
                              projectId: projectId,
                              projectTimeLogId: item.id,
                              ...exp
                            }
                          },
                          optimisticResponse: {
                            __typename: "Mutation",
                            updateProjectTimeLog: {
                              id: item.id,
                              __typename: "ProjectTimeLog",
                              ...exp
                            }
                          },
                        })
                        console.log({ onEventResize: e });
                      }}
                      style={{ height: "calc(100vh - 50px - 6em)" }}
                    />
                    </CalendarStyle>
                  </div>
                </Body>
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
});

export default ProjectTime;
