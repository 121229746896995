import { Button, Drawer, EditableText, Icon, Menu, Popover, Spinner } from '@blueprintjs/core';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import React, { useState } from 'react'
import { createView, deleteView, updateProjectView } from '../../state/ducks/project/operations';

import ChangelogLink from '../ChangelogLink';
import ImportModal from '../ImportModal';
import Logo from '../UI/Logo';
import ProjectFields from '../ProjectFields';
import { SIcon } from '../../utils/Icons';
import Team from '../Team';
import { connect } from "react-redux";
import { loggedUser } from '../../state/ducks/user/selectors';

export default connect((state, ownProps) => ({
   
    loggedUser: loggedUser(state)
  }))((props) => {
    const {project, loggedUser, activeView, activeMenu} = props
    const [creatingView, setCreatingView] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [teamOpen, setTeamOpen] = useState(false);
    const [importOpen, setImportOpen] = useState(false);
    let { projectId } = useParams();
    const history = useHistory()
    const location = useLocation();
    
    return (<>
    
      <ImportModal open={importOpen} onClose={() => setImportOpen(false)}></ImportModal>
      
        <div style={{ flex: "0 0 250px", backgroundColor: "#F2F7FA", overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: '0 0 50px',paddingTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(12, 123, 230)' }}>
              <Link to={"/"}>
                <Logo format="h" color={'white'} width={120}></Logo>
              </Link>
            </div>
            <div style={{flex: '1'}}>
              {/* <div style={{fontSize: 16, padding: '1em'}}>
              {projectView ? <EditableText
              defaultValue={projectView.name}
              onChange={val => {
          
              }}
            ></EditableText>: null}</div> */}
            {/* <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{ paddingLeft: '0.5em 1em'}}><Link to={'/'}><Button minimal={true} icon={'chevron-left'}></Button></Link></div>  <div className={'diagonalMenu'} style={{background:'rgb(12, 123, 230)', paddingTop: '0.35em', paddingRight: '1em', paddingLeft: '2.5em', color: 'white', flex: '1 0'}}>{projectView ? projectView.name : null}</div>
            </div> */}
            
              {/* <div className={"menuVoice"}>VIEWS</div>
              {project
                ? project.projectViews.map(view => (
                    <Link
                      key={view.id}
                      to={`/projects/${project.id}/v/${view.id}`}
                    >
                      <div
                      style={view.id === activeView?.id ? {display: 'flex', justifyContent: 'space-between'}: {}}
                        className={[
                          "menuSubVoice",
                          view.id === activeView?.id ? "active" : ""
                        ].join(" ")}
                      >
                        <div style={{flex: '1', display: 'flex', alignItems: 'center'}}>
                          <div>
                            <Icon icon={"list-columns"}></Icon>
                          </div>
                          <div style={{ paddingLeft: "1em" }}>
                            {view.id === activeView?.id ? (
                              <EditableText
                                defaultValue={view.name}
                                onChange={val => {
                                  updateProjectView(
                                    project.id,
                                    activeView?.id,
                                    {
                                      name: val
                                    }
                                  )(props.dispatch);
                                }}
                              ></EditableText>
                            ) : (
                              view.name
                            )}
                          </div>
                        </div>
                        <div>
                        {view.id === activeView?.id && project.projectViews.length > 1 ? <Popover position={'bottom'} content={<Menu>
    <Menu.Item intent={'danger'} icon="trash" onClick={() => {
      deleteView(project.id, activeView?.id, () => {
        history.push(`/projects/${project.id}`)
      }, () => {

      })(props.dispatch)
    }} text="Delete" />
    </Menu>}><Button style={{minHeight: 10}} icon={'more'} minimal={true} small={true}></Button></Popover>: null} 
                        </div>
                      </div>
                    </Link>
                  ))
                : null}
              <div
                className={"menuSubVoice"}
                onClick={() => {
                  if (creatingView) {
                    return;
                  }
                  setCreatingView(true);
                  createView(
                    project.id,
                    (response) => {
                     
                      setCreatingView(false);
                      history.push(`/projects/${project.id}/v/${response.data.createProjectView.id}`)
                    },
                    () => {
                      setCreatingView(false);
                    }
                  )(props.dispatch);
                }}
              >
                <div>
                  {creatingView ? (
                    <Spinner size={16}></Spinner>
                  ) : (
                    <Icon icon={"small-plus"}></Icon>
                  )}
                </div>
                <div style={{ paddingLeft: "1em", color: "#A1A8B9" }}>
                  Add View
                </div>
              </div> */}
               <div className={"menuVoice"}>TASK MANAGEMENT</div>
              <Link to={`/projects/${project?.id}/views`}>
              <div
                className={["menuSubVoice", activeMenu === 'views' ? "active" : ""].join(
                  " "
                )}
                
              >
                <div>
                  <SIcon icon={"kanban"}></SIcon>
                </div>
                <div style={{ paddingLeft: "1em" }}>Kanban Boards</div>
              </div>
              </Link>
              {/* <Link to={`/projects/${project?.id}/custom-fields`}>
              <div
                className={["menuSubVoice", activeMenu === 'customFields' ? "active" : ""].join(
                  " "
                )}
                
              >
                <div>
                  <Icon icon={"tag"}></Icon>
                </div>
                <div style={{ paddingLeft: "1em" }}>Custom Fields</div>
              </div>
              </Link> */}
              {/* <div className={"menuVoice"}>RESOURCE MANAGEMENT</div>
              <Link to={`/projects/${project?.id}/projects`}>
              <div
                className={["menuSubVoice", activeMenu === 'projects' ? "active" : ""].join(
                  " "
                )}
                
              >
                <div>
                  <Icon icon={"projects"}></Icon>
                </div>
                <div style={{ paddingLeft: "1em" }}>Projects</div>
              </div>
              </Link>
              <Link to={`/projects/${project?.id}/time-tracking`}>
              <div
                className={["menuSubVoice", activeMenu === 'timeTracking' ? "active" : ""].join(
                  " "
                )}
                
              >
                <div>
                  <Icon icon={"time"}></Icon>
                </div>
                <div style={{ paddingLeft: "1em" }}>Time Tracking</div>
              </div>
              </Link>
              <div><Popover position={'bottom'} content={<div style={{padding: '1em'}}>Coming soon!</div>}>
              <div
                className={["menuSubVoice", activeMenu === 'expenses' ? "active" : ""].join(
                  " "
                )}
                style={{opacity: 0.7}}
              >
                <div>
                  <Icon icon={"dollar"}></Icon>
                </div>
                <div style={{ paddingLeft: "1em" }}>Track Expenses</div> 
              </div>
              </Popover>
              </div>
              <Link to={`/projects/${project?.id}/workload`}>
              <div
                className={["menuSubVoice", activeMenu === 'workload' ? "active" : ""].join(
                  " "
                )}
                
                
              >
                <div>
                  <Icon icon={"layers"}></Icon>
                </div>
                <div style={{ paddingLeft: "1em" }}>Workload</div> 
              </div>
              </Link> */}
              
             
              <div className={"menuVoice"}>WORKSPACE</div>
              <Link to={`/projects/${project?.id}/team`}>
                <div
                  className={["menuSubVoice", activeMenu === 'team' ? "active" : ""].join(" ")}
                  
                >
                  <div>
                    <Icon icon={"people"}></Icon>
                  </div>
                  <div style={{ paddingLeft: "1em" }}>Team</div>
                </div>
              </Link>
             
              <div
                className={["menuSubVoice",  importOpen ? "active" : ""].join(
                  " "
                )}
                onClick={() => {
                  setImportOpen(true);
                }}
              >
                <div>
                  <Icon icon={"import"}></Icon>
                </div>
                <div style={{ paddingLeft: "1em" }}>Import</div>
              </div>
              {/* <div
                className={["menuSubVoice", settingsOpen ? "active" : ""].join(
                  " "
                )}
              >
                <div>
                  <Icon style={{color: '#0D81F1'}} icon={"star"}></Icon>
                </div>
                <div style={{ paddingLeft: "1em" }}>Upgrade to <span style={{padding: '0.2em 0.4em', backgroundColor:'#0D81F1', borderRadius: '3px', color: 'white', fontSize: '0.8em'}}>PRO</span></div>
              </div> */}
            </div>
            <div style={{flex: '0 0 calc(50px + 2em)'}}>
                <div style={{padding: '1em'}}>
                  
                {props.loggedUser? (
                  <div style={{border: '1px solid #DFE9F1', borderRadius: 3, width: '100%', height: '50px', display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => {
                    history.push('/profile')
                  }}>
                  <div style={{flex: '0 0 50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1.5em', backgroundColor: '#DFE9F1'}}>
                  {props.loggedUser.shortDisplayName}
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '1em'}}>
                    <div>
                      <div>{props.loggedUser.displayName}</div>
                      <div style={{fontSize: '0.8em'}}>@{props.loggedUser.username}</div>
                    </div>
                  </div>
                </div>
                ) :null}
               
               <div style={{display: 'flex', fontSize: '0.8em', color: 'rgb(161, 168, 185)', justifyContent: 'space-between', paddingTop: '1em'}}>
                    <div>v0.1.4</div>
                    <ChangelogLink />
                  </div>
                </div>
            </div>
          </div></>
    )
})