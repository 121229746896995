import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../auth/types';
import { USER_ME_FETCH_REQUEST, USER_SETUP_REQUEST, USER_UPDATE_REQUEST } from './types';
import { call, put, takeLatest } from 'redux-saga/effects';
import { client, request } from '../../utils/graphql';
import { errorFetchMe, logoutClear, receiveFetchMe } from './actions';
import { trackEvent, trackIdentity } from '../../utils/trackEvent';

import api from '../../utils/api'
import errorHandler from '../../utils/errorHandler';
import gql from 'graphql-tag'

function *fetchMe(action: any){
    const fetchAllApi = () => {
        return client.query({
            fetchPolicy: 'network-only',
            query: gql`{
                me{
                    id
                    firstName
                    lastName
                    displayName
                    shortDisplayName
                    email
                    username
                }
            }`
        })
        
    }
    try{
        const fetchResponse = yield call(fetchAllApi)
        // console.log('FF', fetchResponse)
        if(action.payload && action.payload.resolve){
            action.payload.resolve(fetchResponse)
        }
        if(fetchResponse.data && fetchResponse.data.me){
            trackIdentity(fetchResponse.data.me.id, {
                email: fetchResponse.data.me.email,
                displayName: fetchResponse.data.me.displayName,
                firstName: fetchResponse.data.me.firstName,
                lastName: fetchResponse.data.me.lastName,
                username: fetchResponse.data.me.username,
            })            
        }
        yield put(receiveFetchMe(fetchResponse));
    }catch (e){
        errorHandler({
            error: e,
        })
        if(action.payload && action.payload.reject){
            action.payload.reject(e)
        }
        yield put(errorFetchMe(e));
    }
}
function *logoutClearSaga(action: any){
    yield put(logoutClear());
}
function *setupUser(action: any) {
    trackEvent('USER_SETUP_REQUEST')
    const updateApi = (data) => {
        return client.mutate({
            mutation: gql`mutation setupUser($input: UpdateUserInput){
                setupUser(input: $input){
                    id
                    firstName
                    lastName
                    email
                    username
                }
            }`,
            variables: {input: data}
        })
        
    }
    try{
        const fetchResponse = yield call(updateApi, action.payload.data)
        action.payload.resolve(fetchResponse)
        // yield put(receiveFetchMe(fetchResponse));
    }catch (e){
        errorHandler({
            error: e,
        })
        action.payload.reject(e)
        // yield put(errorFetchMe(e));
    }
}
function *updateUser(action: any) {
    trackEvent('USER_UPDATE_REQUEST')
    const updateApi = (data) => {
        return client.mutate({
            mutation: gql`mutation updateUser($input: UpdateUserInput){
                updateUser(input: $input){
                    id
                    firstName
                    lastName
                    email
                    username
                }
            }`,
            variables: {input: data}
        })
    }
    try{
        const fetchResponse = yield call(updateApi, action.payload.data)
        action.payload.resolve(fetchResponse)
        // yield put(receiveFetchMe(fetchResponse));
    }catch (e){
        errorHandler({
            error: e,
        })
        action.payload.reject(e)
        // yield put(errorFetchMe(e));
    }
}

function *loginSagas(){
    yield takeLatest(LOGIN_SUCCESS, fetchMe);
    yield takeLatest(USER_ME_FETCH_REQUEST, fetchMe);
    yield takeLatest(LOGOUT_SUCCESS, logoutClearSaga);
    yield takeLatest(USER_SETUP_REQUEST, setupUser);
    yield takeLatest(USER_UPDATE_REQUEST, updateUser);
}

export default loginSagas;