import {
  Button,
  Callout,
  Card,
  Elevation,
  H2,
  Icon,
  InputGroup,
  Intent,
  Switch
} from "@blueprintjs/core";
import { Link, Redirect } from "react-router-dom";
import React, { Component, useCallback, useState } from "react";

import FlexView from 'react-flexview'
import Input from "../../components/UI/Input";
import Logo from '../../components/UI/Logo';
import { connect } from "react-redux";
import { setTheme } from "../../state/ducks/interface/operations";
import { signupUser } from "../../state/ducks/auth/operations";

const Register = (props) => {
    const { dispatch, signupError, isAuthenticated, isSigningUp, theme } = props;
    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    })
    const signup = useCallback(() => {
        signupUser(credentials.email, credentials.password)(dispatch)
    }, [credentials])
    const switchTheme = useCallback(e => {
        const darkMode = (e.target as HTMLInputElement).checked;
        const theme = darkMode ? "dark" : "light";
        setTheme(theme)(dispatch);
      }, []);
    return (<>{ isAuthenticated ? (
        <Redirect to="/" />
    ) :(<div style={{ paddingTop: 100 }}>
        <Card
          elevation={4}
          style={{
            margin: "auto",
            display: "block",
            marginTop: 0,
            width: "100%",
            maxWidth: "400px",
            minHeight: "50%"
          }}
        >
            <div style={{padding: '2em 0em'}}><Logo format="v" width={100}></Logo></div>
           <FlexView style={{'justifyContent': 'space-between'}}>
              <FlexView>
                <H2>Sign up</H2>
              </FlexView>
              <FlexView vAlignContent='center'>
                {/* <Switch checked={theme === "dark"} onChange={switchTheme}>
                  <Icon icon="moon" />
                </Switch> */}
              </FlexView>
            </FlexView>
         
        <InputGroup large={true} id="email" placeholder={'Email'} value={credentials.email} onChange={(e) => {
            setCredentials({...credentials, email: e.target.value})
        }}></InputGroup>
        <div style={{padding: '0.5em'}}></div>
        <InputGroup large={true} id="password" placeholder={'Password'} type="password" value={credentials.password} onChange={(e) => {
            setCredentials({...credentials, password: e.target.value})
        }} onKeyDown={(e) => {
            if (e.keyCode === 13) {
                signup()
            }
        } }></InputGroup>
        <div style={{padding: '1em'}}>
        {signupError && (
                 <Callout intent={Intent.DANGER}>{signupError}</Callout>
 
             )}
        </div>
        <div style={{textAlign: 'center', padding: '1em', fontSize: 10}}>
        By creating an account, you're agreeing to accept the Customer Terms of Service.
        </div>
        <Button intent={Intent.PRIMARY} loading={isSigningUp} fill={true} onClick={signup} large={true}>Sign up</Button>
    
        <div style={{textAlign:'center', padding: '1em'}}><div>Have an account?</div><Link to="/login"><Button intent={Intent.PRIMARY} minimal={true}>Sign in</Button></Link>
            </div >
        </Card>
        
             
    </div>)}</>)
}

function mapStateToProps(state) {
  return {
    isSigningUp: state.auth.isSigningUp,
    signupError: state.auth.signupError,
    isAuthenticated: state.auth.isAuthenticated,
    theme: state.interface.theme
  };
}

export default connect(mapStateToProps)(Register);