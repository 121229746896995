import {
  Alignment,
  Button,
  ButtonGroup,
  Card,
  Classes,
  H2,
  Icon,
  InputGroup,
  Intent,
  Popover,
  Switch
} from "@blueprintjs/core";
import { AppearanceType, SizeType } from '@atlaskit/avatar';
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";
import React, { useCallback, useEffect, useState } from "react";
import { createProject, fetchAllProject } from "../state/ducks/project/operations";

import AvatarGroup from '@atlaskit/avatar-group';
import Body from "../components/Body";
import FlexView from "react-flexview";
import Fuse from 'fuse.js'
import Logo from "../components/UI/Logo";
import NavBar from "../components/NavBar";
import { connect } from "react-redux";
import { logoutUser } from "../state/ducks/auth/operations";
import { setTheme } from "../state/ducks/interface/operations";
import { useDebounce } from 'use-debounce';

const Home = connect(state => {
  return {
    theme: state.interface.theme,
    projects: state.project.data,
    isCreatingProject: state.project.isCreating,
    isFetching: state.project.isFetching
  };
})(props => {
  const { theme, dispatch, projects, isCreatingProject, isFetching } = props;
  const [name, setName] = useState("")
  const [tutorialOpen, setTutorialOpen] = useState(false)
  const [search, setSearch] = useState("")
  const [searchResults, setSearchResults] = useState<any>([])
  const [searchDebounce] = useDebounce(search, 100);
  const [openCreate, setOpenCreate] = useState(false)
  useEffect(() => {
    fetchAllProject()(dispatch)
  }, [])
  useEffect(()=>{
    if(searchDebounce != ""){
        var options = {
            keys: [
              "name",
             
            ]
          };
          var fuse = new Fuse(projects, options); // "list" is the item array
          var result = fuse.search(searchDebounce);
          
        setSearchResults(result)
    }else{
        setSearchResults(projects)
    }
  }, [searchDebounce, projects])
  const switchTheme = useCallback(
    e => {
      const themen = theme === "light" ? "dark" : "light";
      setTheme(themen)(dispatch);
    },
    [theme]
  );
  const createProjectCb = useCallback(() => {
    createProject(name)(dispatch)
    setName("")
    setOpenCreate(false)
  }, [name])
  return (
    <div>
      <NavBar></NavBar>
      <Body boxed>
      
      {/* <ModalTransition>
        {tutorialOpen && (
          <ModalDialog width={620} onClose={() => setTutorialOpen(false)}>
            <div style={{margin: 'auto', width: 560}} dangerouslySetInnerHTML={{__html: `
      <iframe width="560" height="315" src="https://www.youtube.com/embed/KogwxjScESQ" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      `}}></div>
          </ModalDialog>
        )}
      </ModalTransition>
      <Button icon={'play'} intent={'primary'} style={{margin: 'auto',marginBottom: '1em'}} onClick={()=>{
        setTutorialOpen(true)
      }}>Guarda il video tutorial</Button> */}
        <Card elevation={2}>
          <FlexView style={{ justifyContent: "space-between" }}>
            <FlexView>
              <InputGroup leftIcon="search" placeholder="Search" type="search" value={search} onChange={(e) => {
                  setSearch(e.target.value)
              }}></InputGroup>
            </FlexView>
            <FlexView>
            <Button icon="refresh" minimal={true} loading={isFetching} onClick={() => {
                    fetchAllProject()(dispatch)
                  }} ></Button>
                  <div style={{width: '1em'}}></div>
                {/* <Popover onInteraction={(v) => setOpenCreate(v)} isOpen={openCreate} position={'bottom'} content={(<div style={{padding: '1em', display: 'flex', flexDirection: 'column'}}>
                    <InputGroup autoFocus={true} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} onKeyDown={(e)=>{
                        if (e.keyCode === 13) {
                            createProjectCb();
                          }
                    }}></InputGroup>
                    <div style={{flex:'0 0 1em'}}></div>
                    <Button fill={true} intent={'primary'} loading={isCreatingProject} onClick={createProjectCb}>
                        Create
                      </Button></div>
                )}>
                   */}
              <Button icon="plus" intent={'success'} loading={isCreatingProject} onClick={()=>{
                props.history.push('/new-project')
              }} >
                New Workspace
              </Button>
              {/* </Popover> */}
            </FlexView>
          </FlexView>
        </Card>
        {searchResults ? searchResults.map((project) => (<Card key={project.id} elevation={1} style={{marginTop: 20}} className={isFetching ? Classes.SKELETON : ''} interactive={true} onClick={() => {
            props.history.push(`/projects/${project.id}`)
        }} >
          <FlexView style={{ justifyContent: "space-between" }}>
            <FlexView>
              <H2>{project.name}</H2>
       
            </FlexView>
            <FlexView style={{height: 50}}>
            <AvatarGroup
                appearance="stack"
               
                data={project.members ? project.members.map((member) => ({
                  appearance: 'circle' as AppearanceType,
                    size: 'medium'  as SizeType,
                    enableTooltip: true,
                    src: member.photoURL ? member.photoURL : 'https://api.adorable.io/avatars/64/'+member.id+'.png',
                    name: member.displayName,
                })): []}
                size="large"
                maxCount={6}
                
                />
            </FlexView>
          </FlexView>
        </Card>)): null}
      </Body>
    </div>
  );
});

export default Home;
