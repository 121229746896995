import React, {useEffect, useRef} from 'react'
import { useFlexLayout, useMountedLayoutEffect, useRowSelect, useTable } from 'react-table'

import { Checkbox } from '@blueprintjs/core'
import styled from 'styled-components'

const Styles = styled.div`

  table {
    
    tbody td {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      display: flex;
      align-items: center;
      
    }
    
`
const IndeterminateCheckbox = React.forwardRef(
    ({ ...rest }: any, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef: any = ref || defaultRef
  
 
      return (
        <>
          {/* <input type="checkbox" ref={resolvedRef} {...rest} /> */}
          <Checkbox ref={resolvedRef} {...rest}></Checkbox>
        </>
      )
    }
  )
export default (props) => {
    const {columns, data, selectedRows, onSelectedRowsChange, className } = props

     // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = useTable({
    columns,
    data,
    initialState: {
        selectedRowIds: selectedRows || {}
      }
  },
  useFlexLayout,
  useRowSelect,
  hooks => {
    hooks.visibleColumns.push(columns => [
      // Let's make a column for selection
      {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        width: '10px',
        maxWidth:'10px',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div style={{width: '16px', height: '16px'}}>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <div style={{width: '16px', height: '16px'}}>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      ...columns,
    ])
  })
  useMountedLayoutEffect(() => {
    
    onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
  }, [onSelectedRowsChange, selectedRowIds]);

  // Render the UI for your table
  return (<Styles className={className}>
    <table className={'bp3-html-table bp3-html-table-striped'} style={{width: '100%'}} {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table></Styles>
  )
}