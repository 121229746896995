
export const events = {
    'SIGNUP_REQUEST': {},
    'LOGIN_REQUEST': {},
    'RESETPW_REQUEST': {},
    'LOGOUT_REQUEST': {},

}

export const trackEvent = (evName) => {
    const w:any = window
    w.amplitude.getInstance().logEvent(evName);

    //
    w.sendinblue.track(
        evName /*mandatory*/,
        // {
        //   'properties' /*optional*/
        // },
        // {
        //   'event data' /*optional*/
        // }
      );

      
}
export const trackIdentity = (userId, {email, displayName, firstName, lastName, username}) => {
    const w:any = window
    w.amplitude.getInstance().setUserId(userId);
    w.Tawk_API.onLoad = function(){
        w.Tawk_API.setAttributes({
            'name'  : displayName,
            'email' : email,
            'hash'  : userId
        }, function(error){});
    }
    w.sendinblue.identify(email, {'id': userId, 'displayName': displayName, 'FIRSTNAME': firstName, 'LASTNAME': lastName });
    w.FS.identify(userId, {
        displayName: displayName,
        email: email
    });
}
export const trackLogout = () => {
    (window as any).amplitude.getInstance().setUserId(null);
}
