import { call, put, select, takeLatest } from 'redux-saga/effects';

import { THEME_SET_REQUEST } from './types';

function *loadTheme (action: any){
    const currInt: any =  yield select((s) => s.interface)
    if(currInt.theme === 'dark'){
        document.body.style.backgroundColor = '#30404d'
    }else{
        document.body.style.backgroundColor = '#f8f9fa'
    }
}

function *interfaceSagas(){
    yield takeLatest('persist/REHYDRATE', loadTheme);
    yield takeLatest(THEME_SET_REQUEST, loadTheme);
}

export default interfaceSagas;