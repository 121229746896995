import {
    Alignment,
    Button,
    ButtonGroup,
    Card,
    Classes,
    FormGroup,
    H2,
    Icon,
    InputGroup,
    Intent,
    Popover,
    Switch
} from "@blueprintjs/core";
import { AppearanceType, SizeType } from '@atlaskit/avatar';
import ModalDialog, { ModalTransition } from "@atlaskit/modal-dialog";
import React, { useCallback, useEffect, useState } from "react";
import { createProject, fetchAllProject } from "../state/ducks/project/operations";

import AvatarGroup from '@atlaskit/avatar-group';
import Body from "../components/Body";
import FlexView from "react-flexview";
import Fuse from 'fuse.js'
import Logo from "../components/UI/Logo";
import NavBar from "../components/NavBar";
import VerifyImg from '../assets/verify.svg'
import { connect } from "react-redux";
import { logoutUser } from "../state/ducks/auth/operations";
import { setTheme } from "../state/ducks/interface/operations";
import { useDebounce } from 'use-debounce';

const VerifyEmail = connect(state => {
    return {
      
    };
  })(props => {
    

    return (
      <div>
        <NavBar></NavBar>
        <Body boxed>
        <div style={{textAlign: 'center'}}>
      <img style={{width: 200 }} src={VerifyImg}></img>
            <H2>Verify your email!</H2>
            <p>We've sent you an email, click on the link inside to verify your account.</p>
            <div style={{height: 30}}></div>
            </div>
          
        </Body>
      </div>
    );
  });
  
  export default VerifyEmail;
  