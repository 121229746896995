import {all} from 'redux-saga/effects'
import { duckSagas as authSagas } from './auth'
import { duckSagas as exampleDuckSagas } from "./example-duck";
import { duckSagas as interfaceSagas } from "./interface";
import { duckSagas as projectSagas } from "./project";
import { duckSagas as userSagas } from "./user";
export default function* rootSaga() {
    yield all([
        exampleDuckSagas(),
        authSagas(),
        projectSagas(),
        interfaceSagas(),
        userSagas()
    ])
    // code after all-effect
  }
  