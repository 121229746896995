import {
    Button,
    Classes,
    Drawer,
    FormGroup,
    H1,
    Icon,
    InputGroup,
    NavbarDivider,
    Popover,
    Tag
} from "@blueprintjs/core";
import RGL, { WidthProvider } from "react-grid-layout";
import React, { useEffect, useState } from "react";
import {
    startProjectView,
    stopProjectView,
    updateProjectView
} from "../../state/ducks/project/operations";
import { useHistory, useParams } from "react-router-dom";

import Body from "../../components/Body";
import { Droppable } from "react-beautiful-dnd";
import ItemEditor from "../../components/ItemEditor";
import ItemList from '../../components/ItemList'
import { Link } from "react-router-dom";
import { PROJECT_TEAM } from "../../state/graphql";
import ProjectMenu from "../../components/ProjectMenu";
import ProjectNavBar from "../../components/ProjectNavBar";
import { Query } from '@apollo/react-components';
import Select from "react-select";
import Team from "../../components/Team";
import classNames from "classnames";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";
import { usePlaceholderData } from "../../utils";

const GridLayout = WidthProvider(RGL);
  
  const ProjectTeam= connect((state, ownProps) => {
    return {
      loggedUser: loggedUser(state)
    };
  })(props => {
    const [showSidebar, setShowSidebar] = useState(true);
    let { projectId } = useParams();
 
 
      
    return (
        <Query fetchPolicy={'cache-and-network'} query={PROJECT_TEAM} variables={{id: projectId}}>
      {({ loading, error, data: realData }) => {
        const {data, skeltonClass} = usePlaceholderData({
          data: {
            me: {
              project: {
                name: 'Lorem ipsum dolor',
                id: '123',
                projectViews: [
  
                ],
                itemAttributes: [
  
                ],
                memberRoles: [

                ],
                projectInvites: [
                  
                ]
              }
            }
          },
          realData,
          loading
        })
          const project = data.me?.project
        return (

      
      <div>
          
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            overflow: "hidden"
          }}
        >
          {showSidebar ? (
            <ProjectMenu project={project} activeMenu={'team'}></ProjectMenu>
          ) : null}
          <div style={{ flex: "1 1", backgroundColor: "#FBFDFF" }}>
            <ProjectNavBar
              notFixed={true}
              pre={
                <>
                  
                  <Button
                    minimal={true}
                    icon={showSidebar ? "chevron-left" : "menu"}
                    text={showSidebar ? "Menu" : "Menu"}
                    onClick={() => setShowSidebar(!showSidebar)}
                  />
                  <NavbarDivider></NavbarDivider>
                  <div style={{color: 'white', display: 'flex', fontSize: '0.8em'}}>
                    <Link to={'/'} style={{color:'white'}}>
                      All Workspaces
                    </Link> 
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link className={skeltonClass} to={`/projects/${project ? project.id:''}`} style={{color:'white'}}>
                      {project ? project.name:''}
                    </Link>
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link className={skeltonClass} to={`/projects/${project ? project.id:''}/team`} style={{color:'white'}}>
                      Team
                    </Link>
                  </div>
                  
                  
                  
                </>
              }
              post={
                <>
            
                </>
              }
            ></ProjectNavBar>
            <div
                style={{
                  width: "100%",
                  height: "calc(100vh - 50px)",
                  position: "relative",
                  overflow: "scroll"
                }}
                className={"scroller"}
              >
            <Body boxed>
                  <H1><span className={skeltonClass}><Icon style={{marginRight: '0.4em'}} iconSize={40} icon={'people'}></Icon> Team</span></H1>
                 
                  
                  <Team projectId={project?.id} memberRoles={project.memberRoles} projectInvites={project.projectInvites}></Team>
                
                
                
              
            </Body>
            </div>
          </div>
        </div>
      </div>
        )
    }}
  </Query>
    );
  });
  
  export default ProjectTeam;
  