import {
  Button,
  Callout,
  Card,
  Elevation,
  H2,
  Icon,
  InputGroup,
  Intent,
  Switch
} from "@blueprintjs/core";
import { Link, Redirect } from "react-router-dom";
import React, { Component, useCallback, useState } from "react";

import FlexView from 'react-flexview'
import Logo from "../../components/UI/Logo";
import { connect } from "react-redux";
import { resetUser } from "../../state/ducks/auth/operations";
import { setTheme } from '../../state/ducks/interface/operations';

const ForgotPassword = (props) => {
    const { dispatch, resetError, isAuthenticated, isResetting, resetSuccess, theme } = props;
    const [credentials, setCredentials] = useState({
        email: ''
    })
    const forgot = useCallback(() => {
         resetUser(credentials.email)(dispatch)
    }, [credentials])
    const switchTheme = useCallback(e => {
        const darkMode = (e.target as HTMLInputElement).checked;
        const theme = darkMode ? "dark" : "light";
        setTheme(theme)(dispatch);
      }, []);
    return (<>{ isAuthenticated ? (
        <Redirect to="/" />
    ) :(<div style={{ paddingTop: 100 }}>
        <Card
          elevation={4}
          style={{
            margin: "auto",
            display: "block",
            marginTop: 0,
            width: "100%",
            maxWidth: "400px",
            minHeight: "50%"
          }}
        >
            <div style={{padding: '2em 0em'}}><Logo format="v" width={100}></Logo></div>
          <FlexView style={{'justifyContent': 'space-between'}}>
            <FlexView>
              <H2>Forgot password?</H2>
            </FlexView>
            <FlexView vAlignContent='center'>
              {/* <Switch checked={theme === "dark"} onChange={switchTheme}>
                <Icon icon="moon" />
              </Switch> */}
            </FlexView>
          </FlexView>
         
        
        <InputGroup large={true} id="email" placeholder={'Email'} value={credentials.email} onChange={(e) => {
            setCredentials({...credentials, email: e.target.value})
        }} onKeyDown={(e) => {
            if (e.keyCode === 13) {
                forgot()
            }
        } }></InputGroup>
        <div style={{padding: '1em'}}>
        {resetError && (
                <Callout intent={Intent.DANGER}>
                 {resetError}
               </Callout>
             )}
             {resetSuccess && ( <Callout intent={Intent.SUCCESS}>
                 Reset email sent
               </Callout>)}
        </div>
        <Button intent={Intent.PRIMARY} loading={isResetting} fill={true} onClick={forgot} large={true}>Send Email</Button>
    
        <div style={{textAlign:'center', padding: '1em'}}><Link to="/login"><Button intent={Intent.PRIMARY} minimal={true}>Back to Login</Button></Link>
            </div >
        </Card>
        
             
    </div>)}</>)
}

function mapStateToProps(state) {
  return {
    isResetting: state.auth.isResetting,
    resetError: state.auth.resetError,
    resetSuccess: state.auth.resetSuccess,
    theme: state.interface.theme
  };
}

export default connect(mapStateToProps)(ForgotPassword);