import { Button, Card, EditableText, FormGroup, H1, H2, InputGroup, NavbarDivider, NumericInput, Popover, ProgressBar } from "@blueprintjs/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { PROJECT_PROJECT, PROJECT_PROJECT_FEE } from "../../state/graphql";
import RGL, { WidthProvider } from "react-grid-layout";
import React, { useCallback, useState } from "react";
import { createView, deleteView, updateProjectView } from "../../state/ducks/project/operations";
import { joinList, useForm } from "../../utils";
import { useMutationCreateProjectProject, useMutationCreateProjectProjectFee, useMutationCreateView, useMutationDeleteProjectProject, useMutationDeleteProjectProjectFee, useMutationUpdateProjectProject, useMutationUpdateProjectProjectFee } from "../../state/mutations";

import Body from "../../components/Body";
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import ProjectMenu from "../../components/ProjectMenu";
import ProjectNavBar from "../../components/ProjectNavBar";
import { Query } from "@apollo/react-components";
import Select from "react-select";
import Table from "../../components/Table";
import { connect } from "react-redux";
import { loggedUser } from "../../state/ducks/user/selectors";
import moment from "moment";
import { useParams } from "react-router-dom";

const ProjectProjectFeeForm = ({project, projectProject, projectProjectFee}) => {
    const {getValue, setValue, isDirty, groupProps, handleCatch, saving, setSaving, changes, handleThen, revert, getInitialValue} = useForm({values: projectProjectFee, successMessage: 'Project Fee saved!'})
    const [updateProjectProjectFee] = useMutationUpdateProjectProjectFee()
    const types = [
      {label: 'Time - Hours', value: 'time'},
      {label: 'Material - Units', value: 'material'}
    ]
    return (<div >
        <div style={{display:'flex', justifyContent:'flex-end'}}>
            {isDirty && <Button style={{marginRight: '1em'}} icon={'undo'} onClick={() => {
                revert()
            }}>Revert</Button>}
            <Button intent={'primary'} disabled={!isDirty} loading={saving} icon={'floppy-disk'} onClick={() => {
                setSaving(true)
                updateProjectProjectFee({
                    variables: {
                        input: {
                            projectId: project.id,
                            projectProjectId: projectProject.id,
                            projectProjectFeeId: projectProjectFee.id,
                            ...changes
                        }
                    }
                }).then((a) => {
                    handleThen(a)
                    console.log({a})
                }).catch((b) => {
                    handleCatch(b)
                })
            }}>Save</Button>
            </div>
            <div style={{marginTop: '2em', display: 'grid', gridGap: "0.5em", gridTemplateColumns: "repeat(5,1fr)"}}>
            <FormGroup label="Activity Name" labelInfo={'*'} {...groupProps('name')}>
            <InputGroup
            
            large={true}
            id="feeName"
            placeholder={"Activity Name"}
            
            value={getValue('name', "")}
            onChange={e => {
                setValue('name', e.target.value)
                
            }}
            ></InputGroup>
        </FormGroup>
        <FormGroup label="Activity Type" labelInfo={'*'} {...groupProps('type')}>
        <Select
            placeholder={"Select a type"}
            className={"SESelect"}
            classNamePrefix={"SESelect"}
            value={types.find((v) => v.value === getValue('type'))}
            onChange={(v: any) => {
              setValue("type", v.value);
              
            }}
            options={types}
          />

        </FormGroup>
        

        <FormGroup label="Estimated Qty" {...groupProps('qtyEstimate')}>
        <NumberFormat thousandSeparator={true} placeholder={"Estimated Qty"} allowNegative={false} 
            value={getValue('qtyEstimate', null)}
            className={'bp3-input bp3-large bp3-fill'}
            onBlur={()=>{
                setValue('budget', getValue('unitCost',0) * getValue('qtyEstimate', 0))
            }}
            onValueChange={(valueAsNumber) => {
              if(valueAsNumber.floatValue !== getInitialValue('qtyEstimate')){
                setValue('qtyEstimate', valueAsNumber.floatValue ? valueAsNumber.floatValue : 0)
                
                
              }
                
            }}
             />
            
        </FormGroup>
        <FormGroup label="Unit Fee" labelInfo={'*'} {...groupProps('unitFee')}>
            <NumberFormat thousandSeparator={true} prefix={'$'} placeholder={"Unit Fee"} allowNegative={false} 
            value={getValue('unitFee', null)}
            className={'bp3-input bp3-large bp3-fill'}
       
            onValueChange={(valueAsNumber) => {
              if(valueAsNumber.floatValue !== getInitialValue('unitFee')){
              setValue('unitFee', valueAsNumber.floatValue ? valueAsNumber.floatValue : 0)
              }
            }}
             />
           
        </FormGroup>
        <FormGroup label="Unit Cost" labelInfo={'*'} {...groupProps('unitCost')}>
            <NumberFormat thousandSeparator={true} prefix={'$'} placeholder={"Unit Cost"} allowNegative={false} 
            value={getValue('unitCost', 0)}
            className={'bp3-input bp3-large bp3-fill'}
            onBlur={()=>{
                setValue('budget', getValue('unitCost',0) * getValue('qtyEstimate',0))
            }}
            onValueChange={(valueAsNumber) => {
                
                if(valueAsNumber.floatValue !== getInitialValue('unitCost'))
                    setValue('unitCost', valueAsNumber.floatValue ? valueAsNumber.floatValue : 0)
            }}
             />
           
        </FormGroup>
      
        {/* <FormGroup label="Budget" {...groupProps('budget')}>
        <NumberFormat thousandSeparator={true} prefix={'$'} placeholder={"Budget"} allowNegative={false} 
            value={getValue('budget',null)}
            disabled={true}
            className={'bp3-input bp3-large bp3-fill'}
            onBlur={()=>{
                setValue('hoursEstimate', getValue('budget',0) / getValue('hourlyCost',0))
            }}
            onValueChange={(valueAsNumber) => {
              if(valueAsNumber.floatValue !== getInitialValue('budget')){
                setValue('budget', valueAsNumber.floatValue ? valueAsNumber.floatValue : null)
                // if(valueAsNumber.floatValue && valueAsNumber.floatValue !== getValue('budget')){
                //     setValue('hoursEstimate', valueAsNumber.floatValue / getValue('hourlyCost'))
                // }
              }
                
            }}
             />
            
        </FormGroup> */}
            </div>
   
    </div>)
}
const ProjectProjectFee= connect((state, ownProps) => {
    return {
      loggedUser: loggedUser(state)
    };
  })(props => {
    const [showSidebar, setShowSidebar] = useState(true);
  const [selectedItems, setSelectedItems] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [addProjectProjectFee, { data }]= useMutationCreateProjectProjectFee()
  const [deleteProjectProjectFee] = useMutationDeleteProjectProjectFee()

  const [name, setName] = useState("")
  const hasSelectedItems = Object.keys(selectedItems).length > 0
  const history = props.history
  let { projectId, projectProjectId, projectProjectFeeId } = useParams();
  const createProjectCb = useCallback(async () => {
    
    if (isCreating) {
        return;
      }
      setIsCreating(true);
      try{
          
        const response = await addProjectProjectFee({
            variables: {
                input: {
                    projectId: projectId,
                    projectProjectId: projectProjectId,
                    name: name
                }
            }
        })
        setIsCreating(false);
        setName("")
        setOpenCreate(false)
        
      }catch(e){
        console.error(e)
        setIsCreating(false);
          setName("")
          setOpenCreate(false)
      }
      
    
  }, [name])
    
 
      
    return (
        <Query fetchPolicy={'network-only'} query={PROJECT_PROJECT_FEE} variables={{id: projectId, where1: {id: {equals: projectProjectId}}, where2: {id:{equals: projectProjectFeeId}}}}>
      {({ loading, error, data }) => {
          const project = data?.me?.project
          const projectProject = data?.me?.project.actualProjectProject[0]
          const projectProjectFee = data?.me?.project.actualProjectProject[0].actualProjectProjectFee[0]
          
          return (

      
      <div>
          
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            overflow: "hidden"
          }}
        >
          {showSidebar ? (
            <ProjectMenu project={project} activeMenu={'projects'}></ProjectMenu>
          ) : null}
          <div style={{ flex: "1 1", backgroundColor: "#FBFDFF" }}>
            <ProjectNavBar
              notFixed={true}
              pre={
                <>
                  
                  <Button
                    minimal={true}
                    icon={showSidebar ? "chevron-left" : "menu"}
                    text={showSidebar ? "Menu" : "Menu"}
                    onClick={() => setShowSidebar(!showSidebar)}
                  />
                  <NavbarDivider></NavbarDivider>
                  <div style={{color: 'white', display: 'flex', fontSize: '0.8em'}}>
                    <Link to={'/'} style={{color:'white'}}>
                      All Workspaces
                    </Link> 
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link to={`/projects/${project ? project.id:''}`} style={{color:'white'}}>
                      {project ? project.name:''}
                    </Link>
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link to={`/projects/${project ? project.id:''}/projects`} style={{color:'white'}}>
                      Projects
                    </Link>
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link to={`/projects/${project ? project.id:''}/projects/${projectProject ? projectProject.id : ''}`} style={{color:'white'}}>
                      {projectProject && projectProject.name}
                    </Link>
                    <div style={{padding: '0em 0.4em'}}>/</div>
                    <Link to={`/projects/${project ? project.id:''}/projects/${projectProject ? projectProject.id : ''}/fees/${projectProjectFee ? projectProjectFee.id : ''}`} style={{color:'white'}}>
                      {projectProjectFee && projectProjectFee.name}
                    </Link>
                  </div>
                  
                  
                  
                </>
              }
              post={
                <>
                   
                </>
              }
            ></ProjectNavBar>
            <Body boxed>
                
                     
                    <H1>{projectProjectFee && projectProjectFee.name}</H1>

                    <Card style={{marginBottom:'2em'}}>
                        <ProjectProjectFeeForm project={project} projectProject={projectProject} projectProjectFee={projectProjectFee}/>
                    </Card>
                    
                  
                 
                </Body>
            
          </div>
        </div>
      </div>
        )
    }}
  </Query>
    );
  });
  
  export default ProjectProjectFee;
  