import * as types from "./types";

const setupUser = (data, resolve, reject) => ({
    type: types.USER_SETUP_REQUEST,
    payload: {
        data,
        resolve,
        reject
    }
})
const updateUser = (data, resolve, reject) => ({
    type: types.USER_UPDATE_REQUEST,
    payload: {
        data,
        resolve,
        reject
    }
})
const fetchMe = (resolve, reject) => ({
    type: types.USER_ME_FETCH_REQUEST,
    payload: {
    
        resolve,
        reject
    }
})
const receiveFetchMe = (response) => ({
    type: types.USER_ME_FETCH_SUCCESS,
    payload: {
        me: response.data.me
    }
})
const errorFetchMe = (response) => ({
    type: types.USER_ME_FETCH_FAILURE,
    payload: {
        response
    }
})
const logoutClear = () => ({
    type: types.USER_LOGOUT_CLEAR_REQUEST
})
export {
    receiveFetchMe,
    errorFetchMe,
    logoutClear,
    setupUser,
    fetchMe,
    updateUser
    // quack
};